import React, { useEffect, useMemo, useState } from 'react'

import { Button, ButtonSize, ButtonVariant } from '@amzn/stencil-react-components/button'
import { IconAlertTriangleFill, IconBin, IconPlus } from '@amzn/stencil-react-components/icons'
import { Col, Flex, Row, Spacer, View } from '@amzn/stencil-react-components/layout'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'
import { ModalContent, WithModal } from '@amzn/stencil-react-components/modal'
import { useBreakpoints } from '@amzn/stencil-react-components/responsive'
import { ScreenReaderOnly } from '@amzn/stencil-react-components/screen-reader-only'
import { RowData, Table, TableColumn } from '@amzn/stencil-react-components/table'
import { H5, Text } from '@amzn/stencil-react-components/text'

import { IconWithTooltip } from 'src/components/IconWithTooltip'
import { MediaManager } from 'src/components/MediaContentsEditor/MediaManager'
import { ResponsiveRow } from 'src/components/ResponsiveRow'
import { isDefaultLocale } from 'src/contexts/ModuleLocaleContext'
import { useItemEntity, useValidationErrorEntity } from 'src/hooks/useEntity'
import { TableCell, TableCellType } from 'src/models/dto/items/BaseTableDTO'
import {
    MultipleChoiceDisplayType,
    MultipleChoiceItemDTO,
    MultipleChoiceResponseDTO,
    MultipleChoiceType,
} from 'src/models/dto/items/MultipleChoiceItemDTO'
import { Locale, LocalizeDefault } from 'src/models/dto/Locale'
import { GENERIC_ERROR_KEY, ValidationErrorEntity } from 'src/models/dto/TemplateValidationError'
import { getListOfFileTypes, MediaFileGroups } from 'src/models/media/MediaTemplateDTO'
import { focusOnId } from 'src/pages/module-builder/focus'
import { ItemEditorProps } from 'src/pages/module-builder/item-editors/ItemEditorContainer'
import {
    GenericItemEditorSelectInput,
    ItemEditorCheckboxInput,
    ItemEditorNumberInput,
    ItemEditorSelectInput,
    ItemEditorTextInput,
} from 'src/pages/module-builder/item-editors/ItemEditorInputs'
import {
    ButtonCell,
    CellType,
    getRenderCardForRow,
    NumberCell,
    StimulusTableProps,
    TextCell,
} from 'src/pages/module-builder/item-editors/ItemEditorTable'
import { getHarveyBallLegend } from 'src/pages/module-builder/item-editors/table-editor/HarveyBallLegend'
import { getSymbolLegends } from 'src/pages/module-builder/item-editors/table-editor/SymbolLegend'
import { CellEditor } from 'src/pages/module-builder/item-editors/table-editor/TableCellEditor'
import {
    EditCellComponentProps,
    Header,
    TableEditor,
    ViewCellComponentProps,
} from 'src/pages/module-builder/item-editors/table-editor/TableEditor'
import { TableItemCellView } from 'src/pages/module-builder/item-editors/TableItemEditor'
import usePrevious from 'src/pages/module-review/hooks'
import { MultipleChoiceHandler } from 'src/services/EntityServices/ItemUpdateHandlers/MultipleChoiceHandler'

const PLACEHOLDERS = ['E.g. Cats are best', 'E.g. Ducks are best', 'E.g. Llamas are best']

function isMultipleChoiceResponseDTO(
    cellData: TableCell | MultipleChoiceResponseDTO
): cellData is MultipleChoiceResponseDTO {
    return 'score' in cellData
}

const multipleChoiceHandler = MultipleChoiceHandler.INSTANCE

export function ChangeLayoutModal({
    onClose,
    onDone,
    currentDisplayType,
    nextDisplayType,
}: {
    onClose: () => void
    onDone: () => void
    currentDisplayType: MultipleChoiceDisplayType
    nextDisplayType: MultipleChoiceDisplayType
}) {
    return (
        <ModalContent
            titleText='Change Layout'
            buttons={[
                <Button
                    key='change-layout-modal-submit'
                    dataTestId='change-layout-modal-submit'
                    onClick={() => {
                        onDone()
                        onClose()
                    }}
                    isDestructive
                >
                    Yes, change layout
                </Button>,
                <Button
                    key='close-modal'
                    dataTestId='close-modal'
                    onClick={onClose}
                    variant={ButtonVariant.Primary}
                >
                    No, stay on current layout
                </Button>,
            ]}
        >
            <Col gridGap='S400'>
                {currentDisplayType === MultipleChoiceDisplayType.TABLE ? (
                    <Text>
                        You are currently on the table layout. By switching to another layout, you
                        will lose all the table data you currently have and will have to fill it
                        again later. Are you sure you want to switch layouts?
                    </Text>
                ) : nextDisplayType !== MultipleChoiceDisplayType.Image ? (
                    <Text>
                        You have images attached to responses currently. If you switch layouts, you
                        will lose the media and will have to add them to the responses again. Are
                        you sure you want to switch layouts?
                    </Text>
                ) : (
                    <Text>
                        You currently have more than 4 responses, and the Image layout has a maximum
                        of 4 responses allowed. If you switch you will lose the data for all extra
                        responses, do you want to continue?
                    </Text>
                )}
            </Col>
        </ModalContent>
    )
}

const ScoreCellEditor = ({
    cell,
    validationErrorEntity,
    showValidationError,
}: {
    cell: EditCellComponentProps<MultipleChoiceResponseDTO>
    validationErrorEntity: ValidationErrorEntity
    showValidationError: boolean
}) => {
    return (
        <Row gridGap={'S300'}>
            <Col flex={'1'}>
                <ItemEditorTextInput
                    placeholder={'Enter score label'}
                    inputId={`cell-${cell.colNum}-${cell.rowNum}-${cell.itemId}-editor-label`}
                    itemId={cell.itemId}
                    value={cell.nextCellData.responseLabel ?? ''}
                    setValue={(label) => {
                        cell.setNextCellData((prev) => ({
                            ...prev,
                            responseLabel: label,
                        }))
                    }}
                    validationErrorMessage={(
                        validationErrorEntity.validationErrors[`responses[${cell.rowNum}].label`] ??
                        []
                    ).join(', ')}
                    showError={showValidationError}
                    labelText={'Label'}
                />
            </Col>
            <Col flex={'1'}>
                <ItemEditorNumberInput
                    placeholder={'0.0'}
                    inputId={`cell-${cell.colNum}-${cell.rowNum}-${cell.itemId}-editor-score`}
                    itemId={cell.itemId}
                    value={cell.nextCellData.score ?? null}
                    showError={showValidationError}
                    validationErrorMessage={(
                        validationErrorEntity.validationErrors[`responses[${cell.rowNum}].score`] ??
                        []
                    ).join(', ')}
                    setValue={(score) => {
                        cell.setNextCellData((prev) => ({
                            ...prev,
                            score,
                        }))
                    }}
                    labelText={'Score'}
                    min={0}
                />
            </Col>
        </Row>
    )
}

export const TableResponsesLayout = ({
    multipleChoiceItemDTO,
    locale,
    showValidationError,
    editDisabled,
}: {
    multipleChoiceItemDTO: MultipleChoiceItemDTO
    locale: Locale
    showValidationError: boolean
    editDisabled?: boolean
}) => {
    const { tableResponses, id: itemId } = multipleChoiceItemDTO
    const { entity: validationErrorEntity } = useValidationErrorEntity(itemId)

    const headers: Header[] = [
        ...tableResponses.headers.map((t) => ({
            headerName: t.headerI18N,
            editable: true,
        })),
        {
            headerName: 'Score Information',
            editable: false,
        },
    ]

    return (
        <TableEditor<TableCell | MultipleChoiceResponseDTO>
            itemId={itemId}
            headers={headers}
            rows={tableResponses.rows.map((r, index) => [
                ...r.cells,
                multipleChoiceItemDTO.responses[index],
            ])}
            setNumberOfColumns={(nextNumber: number) => {
                multipleChoiceHandler.setNumberOfColumns(itemId, nextNumber)
            }}
            setNumberOfRows={(nextNumberOfRows: number) => {
                multipleChoiceHandler.setNumberOfRows(itemId, nextNumberOfRows)
            }}
            legends={[
                getHarveyBallLegend({
                    itemId: itemId,
                    baseTableDTO: multipleChoiceItemDTO.tableResponses,
                    locale: locale,
                    tableOperations: MultipleChoiceHandler.INSTANCE,
                }),
            ]}
            symbolLegend={getSymbolLegends({
                itemId: itemId,
                baseTableDTO: multipleChoiceItemDTO.tableResponses,
                locale: locale,
                tableOperations: MultipleChoiceHandler.INSTANCE,
            })}
            locale={locale}
            setColumnName={(columnIndex, columnText) => {
                multipleChoiceHandler.setColumnName(itemId, columnIndex, columnText, locale)
            }}
            editCellComponent={(props) => {
                const cellData = props.nextCellData
                if (isMultipleChoiceResponseDTO(cellData)) {
                    return (
                        <ScoreCellEditor
                            cell={
                                props as never as EditCellComponentProps<MultipleChoiceResponseDTO>
                            }
                            validationErrorEntity={validationErrorEntity}
                            showValidationError={showValidationError}
                        />
                    )
                } else {
                    const tableCell = props as never as EditCellComponentProps<TableCell>
                    return (
                        <CellEditor
                            {...tableCell}
                            itemId={itemId}
                            locale={locale}
                            harveyEnabled={
                                multipleChoiceItemDTO.tableResponses.harveyBallLegendEnabled
                            }
                            symbolEnabled={multipleChoiceItemDTO.tableResponses.symbolLegendEnabled}
                            tableOperations={MultipleChoiceHandler.INSTANCE}
                        />
                    )
                }
            }}
            cellViewComponent={(props) => {
                const cellData = props.cellData
                if (isMultipleChoiceResponseDTO(cellData)) {
                    const { responseLabel, score } = cellData
                    const scoreIsNotFilled = typeof score !== 'number'
                    const error = !responseLabel || scoreIsNotFilled
                    return (
                        <Row alignItems='center' gridGap='S200'>
                            {error && (
                                <View>
                                    <IconAlertTriangleFill aria-hidden />
                                </View>
                            )}
                            <Col alignItems={'start'}>
                                {responseLabel ? (
                                    <Text>Label: {responseLabel}</Text>
                                ) : (
                                    <Text color='red70'>No label</Text>
                                )}
                                {scoreIsNotFilled ? (
                                    <Text color='red70'>No score</Text>
                                ) : (
                                    <Text>Score: {score}</Text>
                                )}
                            </Col>
                        </Row>
                    )
                } else {
                    const tableCell = props as never as ViewCellComponentProps<TableCell>
                    return <TableItemCellView {...tableCell} locale={locale} />
                }
            }}
            setCellData={({ colNum, rowNum, cellData }) => {
                if (isMultipleChoiceResponseDTO(cellData)) {
                    multipleChoiceHandler.updateResponseLabel(
                        itemId,
                        rowNum,
                        cellData.responseLabel ?? ''
                    )
                    multipleChoiceHandler.updateScore(itemId, rowNum, cellData.score)
                } else {
                    multipleChoiceHandler.setCellData(
                        itemId,
                        colNum,
                        rowNum,
                        cellData as never as TableCell
                    )
                }
            }}
            defaultCellData={(rowNum, colNum, cellType: TableCellType) => {
                if (colNum === tableResponses.headers.length) {
                    return {
                        responseI18N: LocalizeDefault(''),
                        score: null,
                        responseLabel: '',
                    }
                }

                return MultipleChoiceHandler.INSTANCE.getCellTypeDefault(itemId, cellType, locale)
            }}
            cellEditModalTitleText={(rowNum, colNum) => {
                if (colNum === tableResponses.headers.length) {
                    return `Edit score: Row ${rowNum + 1}`
                }
                return `Edit table cell: Column ${colNum + 1}, Row ${rowNum + 1}`
            }}
            minRows={2}
            disabled={editDisabled}
        />
    )
}

const MAX_CHARACTERS_FOR_COLUMN_DISPLAY_TYPE = 7
export const MCRegularLayout = ({
    itemDTO,
    locale,
    showValidationError,
    editDisabled,
}: {
    itemDTO: MultipleChoiceItemDTO
    locale: Locale
    showValidationError: boolean
    editDisabled?: boolean
}) => {
    const { entity: validationErrorEntity } = useValidationErrorEntity(itemDTO.id)

    const tableColumns = useMemo(
        () =>
            [
                {
                    header: 'Response options',
                    width: '50%',
                    cellComponent: TextCell,
                    errorMessage: 'Please enter a response option',
                    verticalAlign: 'top',
                    accessor: ({ data, index }: RowData<MultipleChoiceResponseDTO>) => ({
                        id: itemDTO.id,
                        type: 'option',
                        value: data?.responseI18N?.[locale] ?? '',
                        placeholder: PLACEHOLDERS[index % PLACEHOLDERS.length],
                        labelText: `Response option ${index + 1}`,
                        showError: showValidationError,
                        errorMessage: (
                            validationErrorEntity.validationErrors[
                                `responses[${index}].responseI18N[${locale}]`
                            ] ?? []
                        ).join(', '),
                        setValue: (nextValue: string) => {
                            multipleChoiceHandler.updateResponseOption(
                                itemDTO.id,
                                index,
                                locale,
                                nextValue
                            )
                        },
                        disabled: editDisabled,
                        maxCharacterLimit:
                            itemDTO.displayType === MultipleChoiceDisplayType.Columns
                                ? MAX_CHARACTERS_FOR_COLUMN_DISPLAY_TYPE
                                : undefined,
                    }),
                },
                {
                    header: 'Label',
                    width: '30%',
                    cellComponent: TextCell,
                    verticalAlign: 'top',
                    accessor: ({ data, index }: RowData<MultipleChoiceResponseDTO>) => ({
                        id: itemDTO.id,
                        type: 'responseLabel',
                        value: data?.responseLabel ?? '',
                        placeholder: 'Enter score label',
                        disabled: !isDefaultLocale(locale) || editDisabled,
                        labelText: `Label ${index + 1}`,
                        showError: showValidationError,
                        errorMessage: (
                            validationErrorEntity.validationErrors[`responses[${index}].label`] ??
                            []
                        ).join(', '),
                        setValue: (nextValue: string) => {
                            multipleChoiceHandler.updateResponseLabel(itemDTO.id, index, nextValue)
                        },
                    }),
                },
                {
                    header: 'Score',
                    width: '15%',
                    cellComponent: NumberCell,
                    verticalAlign: 'top',
                    accessor: ({ data, index }: RowData<MultipleChoiceResponseDTO>) => ({
                        id: itemDTO.id,
                        type: 'option',
                        placeholder: '0.0',
                        value: data?.score,
                        disabled: !isDefaultLocale(locale) || editDisabled,
                        labelText: `Score ${index + 1}`,
                        showError: showValidationError,
                        errorMessage: (
                            validationErrorEntity.validationErrors[`responses[${index}].score`] ??
                            []
                        ).join(', '),
                        setValue: (nextValue: number) => {
                            multipleChoiceHandler.updateScore(itemDTO.id, index, nextValue)
                        },
                    }),
                },
                {
                    header: <ScreenReaderOnly>Delete</ScreenReaderOnly>,
                    width: '5%',
                    cellComponent: ButtonCell,
                    accessor: ({ index }: RowData<MultipleChoiceResponseDTO>) => ({
                        id: itemDTO.id,
                        type: 'delete',
                        value: undefined,
                        cellType: CellType.DeleteButton,
                        disabled:
                            !isDefaultLocale(locale) ||
                            itemDTO.responses.length <= 2 ||
                            editDisabled,
                        labelText: `Delete response ${index + 1}`,
                        setValue: () => multipleChoiceHandler.deleteResponse(itemDTO.id, index),
                    }),
                },
            ] as unknown as TableColumn<MultipleChoiceResponseDTO, StimulusTableProps<unknown>>[],
        [
            editDisabled,
            itemDTO.displayType,
            itemDTO.id,
            itemDTO.responses.length,
            locale,
            showValidationError,
            validationErrorEntity.validationErrors,
        ]
    )

    const renderCardForRow = useMemo(() => getRenderCardForRow(tableColumns), [tableColumns])

    return (
        <Col width={'100%'} id={`table-${itemDTO.id}`}>
            <View id={`table-title-${itemDTO.id}`} aria-live='assertive'>
                <ScreenReaderOnly>
                    Response options ({itemDTO.responses.length} entries)
                </ScreenReaderOnly>
            </View>
            <Table
                aria-labelledby={`table-title-${itemDTO.id}`}
                columns={tableColumns}
                data={itemDTO.responses}
                renderCardForRow={renderCardForRow}
                dataTestId='multiple-choice-item-response-options-table'
            />
        </Col>
    )
}

export const MCImageLayout = ({
    itemDTO,
    locale,
    showValidationError,
    editDisabled,
}: {
    itemDTO: MultipleChoiceItemDTO
    locale: Locale
    showValidationError: boolean
    editDisabled?: boolean
}) => {
    const { entity: validationErrorEntity } = useValidationErrorEntity(itemDTO.id)

    const { matches } = useBreakpoints()
    const isMobile = matches.s
    const fieldFlex = isMobile ? '1 1' : '1 1 29%'

    return (
        <View width={'100%'}>
            <H5 color='neutral100' fontWeight='bold'>
                Image response options
            </H5>
            <View border={isMobile ? '0px' : '1px solid #B9C0C8'} role='list'>
                {itemDTO.responses.map((response: MultipleChoiceResponseDTO, index: number) => {
                    const id = `${itemDTO.id}-${index}-response`

                    return (
                        <View
                            key={index}
                            role='listitem'
                            id={id}
                            dataTestId={id}
                            tabIndex={-1}
                            aria-label={`Image option ${index + 1}`}
                        >
                            <Row
                                alignItems='center'
                                backgroundColor={isMobile ? 'white' : 'neutral05'}
                                justifyContent='space-between'
                                padding={isMobile ? 0 : 'S200'}
                                width='100%'
                            >
                                <Text color='neutral90'>{`Image option ${index + 1}`}</Text>
                                <Spacer flex='1 1 0' />
                                {!isMobile && (
                                    <Button
                                        dataTestId={`response-${index}-delete-button`}
                                        icon={
                                            <IconBin title={`Delete image option ${index + 1}`} />
                                        }
                                        aria-disabled={
                                            itemDTO.responses.length <= 2 || editDisabled
                                        }
                                        aria-label={`Delete image option ${index + 1}`}
                                        variant={ButtonVariant.Tertiary}
                                        size={ButtonSize.Small}
                                        isDestructive
                                        onClick={() => {
                                            multipleChoiceHandler.deleteResponse(itemDTO.id, index)
                                        }}
                                    />
                                )}
                            </Row>
                            <ResponsiveRow
                                width='100%'
                                gridGap={isMobile ? '0' : 'S300'}
                                alignItems={'flex-start'}
                                flexWrap={'wrap'}
                                padding={isMobile ? 0 : 'S200'}
                            >
                                <Col flex={fieldFlex} width={isMobile ? '100%' : 'auto'}>
                                    {isMobile && <Spacer height={'S200'} />}
                                    <ItemEditorTextInput
                                        dataTestId={`response-${index}-option`}
                                        inputId={`response-option-${index}`}
                                        value={itemDTO.responses[index].responseI18N[locale] ?? ''}
                                        placeholder={'E.g. Jacket'}
                                        itemId={itemDTO.id}
                                        setValue={(nextValue: string) => {
                                            multipleChoiceHandler.updateResponseOption(
                                                itemDTO.id,
                                                index,
                                                locale,
                                                nextValue
                                            )
                                        }}
                                        labelText={'Response option'}
                                        maxCharacterLimit={25}
                                        showError={showValidationError}
                                        validationErrorMessage={(
                                            validationErrorEntity.validationErrors[
                                                `responses[${index}].responseI18N[${locale}]`
                                            ] ?? []
                                        ).join(', ')}
                                        disabled={editDisabled}
                                    />
                                </Col>
                                <Col flex={fieldFlex} width={isMobile ? '100%' : 'auto'}>
                                    {isMobile && <Spacer height={'S200'} />}
                                    <ItemEditorTextInput
                                        dataTestId={`response-${index}-label`}
                                        inputId={`response-label-${index}`}
                                        value={itemDTO.responses[index].responseLabel ?? ''}
                                        placeholder={'Enter a score label'}
                                        itemId={itemDTO.id}
                                        setValue={(nextValue: string) => {
                                            multipleChoiceHandler.updateResponseLabel(
                                                itemDTO.id,
                                                index,
                                                nextValue
                                            )
                                        }}
                                        labelText={'Score label'}
                                        showError={showValidationError}
                                        validationErrorMessage={(
                                            validationErrorEntity.validationErrors[
                                                `responses[${index}].label`
                                            ] ?? []
                                        ).join(', ')}
                                        disabled={editDisabled}
                                    />
                                </Col>
                                <Col flex={fieldFlex} width={isMobile ? '100%' : 'auto'}>
                                    {isMobile && <Spacer height={'S200'} />}
                                    <ItemEditorNumberInput
                                        dataTestId={`response-${index}-score`}
                                        inputId={`response-score-${index}`}
                                        value={itemDTO.responses[index].score ?? null}
                                        disabled={editDisabled}
                                        placeholder={'0.0'}
                                        itemId={itemDTO.id}
                                        setValue={(nextValue) => {
                                            if (nextValue === null) {
                                                multipleChoiceHandler.updateScore(
                                                    itemDTO.id,
                                                    index,
                                                    0
                                                )
                                            } else {
                                                multipleChoiceHandler.updateScore(
                                                    itemDTO.id,
                                                    index,
                                                    nextValue
                                                )
                                            }
                                        }}
                                        labelText={'Score'}
                                        showError={showValidationError}
                                        validationErrorMessage={(
                                            validationErrorEntity.validationErrors[
                                                `responses[${index}].score`
                                            ] ?? []
                                        ).join(', ')}
                                    />
                                </Col>
                                <Col
                                    dataTestId={`response-${index}-add-media`}
                                    padding={0}
                                    width={isMobile ? '100%' : 'auto'}
                                >
                                    <MediaManager
                                        itemId={itemDTO.id}
                                        locale={locale}
                                        localeWiseMediaOpt={
                                            itemDTO.responses[index].localeWiseMedia
                                        }
                                        setLocaleWiseMediaOpt={(change) => {
                                            multipleChoiceHandler.updateResponseLocaleWiseMediaOpt(
                                                itemDTO.id,
                                                change,
                                                index
                                            )
                                        }}
                                        disableMediaExpander={true}
                                        maxNumberOfMedia={1}
                                        buttonText={'Add an image'}
                                        buttonVariant={ButtonVariant.Secondary}
                                        lockedFileTypes={getListOfFileTypes(MediaFileGroups.IMAGE)}
                                        tableAriaLabel={`Image option ${index + 1} media`}
                                        disabled={editDisabled}
                                    />
                                </Col>
                                {isMobile && (
                                    <Col width={'100%'}>
                                        <Button
                                            dataTestId={`response-${index}-delete-button`}
                                            aria-disabled={
                                                itemDTO.responses.length <= 2 || editDisabled
                                            }
                                            aria-label={`Delete response ${index + 1}`}
                                            variant={ButtonVariant.Secondary}
                                            isDestructive
                                            onClick={() => {
                                                multipleChoiceHandler.deleteResponse(
                                                    itemDTO.id,
                                                    index
                                                )
                                            }}
                                        >
                                            Delete response {index + 1}
                                        </Button>
                                        <Spacer height='S300' />
                                    </Col>
                                )}
                            </ResponsiveRow>
                        </View>
                    )
                })}
            </View>
        </View>
    )
}

export const MCLayout = ({
    itemDTO,
    locale,
    showValidationError,
    editDisabled,
}: {
    itemDTO: MultipleChoiceItemDTO
    locale: Locale
    showValidationError: boolean
    editDisabled?: boolean
}) => {
    switch (itemDTO.displayType) {
        case MultipleChoiceDisplayType.TABLE:
            return (
                <TableResponsesLayout
                    locale={locale}
                    multipleChoiceItemDTO={itemDTO}
                    showValidationError={showValidationError}
                    editDisabled={editDisabled}
                />
            )
        case MultipleChoiceDisplayType.Image:
            return (
                <MCImageLayout
                    itemDTO={itemDTO}
                    locale={locale}
                    showValidationError={showValidationError}
                    editDisabled={editDisabled}
                />
            )
        default:
            return (
                <MCRegularLayout
                    itemDTO={itemDTO}
                    locale={locale}
                    showValidationError={showValidationError}
                    editDisabled={editDisabled}
                />
            )
    }
}

export const MultipleChoiceItemEditor = ({
    itemId,
    locale,
    workflowEntityId,
    showValidationError = false,
    editDisabled,
}: ItemEditorProps) => {
    const { entity: itemDTO } = useItemEntity<MultipleChoiceItemDTO>({
        entityId: itemId,
        workflowEntityId,
    })

    const { entity: validationErrorEntity } = useValidationErrorEntity(itemId)

    const { matches } = useBreakpoints()

    const previous = usePrevious<MultipleChoiceItemDTO>(itemDTO)

    useEffect(() => {
        if (itemDTO.responses.length != previous.responses.length) {
            if (itemDTO.displayType === MultipleChoiceDisplayType.Image) {
                const indexOfLastResponse = itemDTO.responses.length - 1
                const idOfLastResponse = `${itemDTO.id}-${indexOfLastResponse}-response`
                focusOnId(idOfLastResponse)
            }
        }
    }, [itemDTO.responses.length, itemDTO.id, previous.responses.length, itemDTO.displayType])

    const flexItemWidth = matches.s ? '100%' : 'default'

    const UNIQUE_ITEM_ID_FLEX_BASIS = matches.s ? '100%' : '200px'
    const OPTIONAL_CHECKBOX_FLEX_BASIS = matches.s ? '100%' : '80px'
    const SELECTION_TYPE_FLEX_BASIS = matches.s ? '100%' : '100px'

    // This is for unvalidated modules that had 0 responses previously; should default to have 2 response at least
    if (itemDTO.responses.length === 0) {
        multipleChoiceHandler.addResponse(itemDTO.id)
        multipleChoiceHandler.addResponse(itemDTO.id)
    }

    const [displayNameToValues, valueToDisplayNames] = useMemo(() => {
        const m1 = new Map<string, number | undefined>()
        const m2 = new Map<number | undefined, string>()
        m1.set('(None)', undefined)
        m2.set(undefined, '(None)')
        itemDTO.responses.forEach((_v, i) => {
            const label = (i + 1).toString()
            m1.set(label, i + 1)
            m2.set(i + 1, label)
        })
        return [m1, m2]
    }, [itemDTO.responses])

    const topLevelErrors = useMemo(() => {
        return [
            ...(validationErrorEntity.validationErrors[GENERIC_ERROR_KEY] || []),
            ...(validationErrorEntity.validationErrors.responseOptions || []),
            ...(validationErrorEntity.validationErrors.responseLabels || []),
            ...(validationErrorEntity.validationErrors.scores || []),
        ]
    }, [validationErrorEntity.validationErrors])

    const hasToplevelErrors = topLevelErrors.length > 0

    const displayTypeDisplayNames = useMemo(() => {
        return new Map<MultipleChoiceDisplayType, string>([
            [MultipleChoiceDisplayType.radio, 'Standard'],
            [MultipleChoiceDisplayType.dropdown, 'Dropdown'],
            [MultipleChoiceDisplayType.tile, 'Tile'],
            [MultipleChoiceDisplayType.TABLE, 'Table'],
            [MultipleChoiceDisplayType.Columns, 'Columns'],
            [MultipleChoiceDisplayType.Image, 'Image'],
        ])
    }, [])

    const [nextDisplayType, setNextDisplayType] = useState<MultipleChoiceDisplayType>(
        itemDTO.displayType
    )

    const renderModal = ({ close }: { close: () => void }) => (
        <ChangeLayoutModal
            onClose={() => {
                close()
            }}
            onDone={() => {
                close()
                multipleChoiceHandler.updateDisplayType(itemDTO.id, nextDisplayType)
            }}
            currentDisplayType={itemDTO.displayType}
            nextDisplayType={nextDisplayType}
        />
    )

    return (
        <>
            {showValidationError && hasToplevelErrors && topLevelErrors?.length > 0 && (
                <MessageBanner type={MessageBannerType.Error}>
                    <ul>
                        {topLevelErrors.map((message, index) => (
                            <li key={index}>{message}</li>
                        ))}
                    </ul>
                </MessageBanner>
            )}
            <Row gridGap='S300' alignItems={'flex-start'} flexWrap={'wrap'}>
                <Col flex={`3 0 ${UNIQUE_ITEM_ID_FLEX_BASIS}`}>
                    <ItemEditorTextInput
                        dataTestId='unique-item-id'
                        inputId='unique-item-id'
                        value={itemDTO.label}
                        placeholder={'Some human readable label'}
                        disabled={!isDefaultLocale(locale) || editDisabled}
                        itemId={itemDTO.id}
                        setValue={(nextValue: string) => {
                            multipleChoiceHandler.updateLabel(itemDTO.id, nextValue)
                        }}
                        validationErrorMessage={(
                            validationErrorEntity.validationErrors.label ?? []
                        ).join(', ')}
                        showError={showValidationError}
                        labelText='Unique Item ID'
                    />
                </Col>
                <Col
                    gridGap={'S200'}
                    padding={{ top: `${matches.s ? '8px' : '35px'}` }}
                    flex={`0 0 ${OPTIONAL_CHECKBOX_FLEX_BASIS}`}
                >
                    <ItemEditorCheckboxInput
                        dataTestId='optional-checkbox'
                        inputId='optional'
                        itemId={itemDTO.id}
                        disabled={!isDefaultLocale(locale) || editDisabled}
                        labelText={'Optional'}
                        value={itemDTO.optional}
                        setValue={(nextValue: boolean) => {
                            multipleChoiceHandler.updateOptional(itemDTO.id, nextValue)
                        }}
                    />
                </Col>
                <Col
                    gridGap={'S200'}
                    padding={{ top: `${matches.s ? '8px' : '29px'}` }}
                    flex={'0 0 170px'}
                >
                    <Row alignItems={'center'}>
                        <ItemEditorCheckboxInput
                            dataTestId='preserve-order-checkbox'
                            inputId={'preserve-order'}
                            itemId={itemDTO.id}
                            labelText={'Preserve order'}
                            disabled={!isDefaultLocale(locale) || editDisabled}
                            value={itemDTO.preserveOrder}
                            setValue={(nextValue: boolean) => {
                                multipleChoiceHandler.updatePreserveOrder(itemDTO.id, nextValue)
                            }}
                        />
                        <IconWithTooltip tooltipText='When randomizing page, ensure this items position is never changed' />
                    </Row>
                </Col>
                <WithModal renderModal={renderModal}>
                    {({ open }) => (
                        <Col flex={`1 0 ${SELECTION_TYPE_FLEX_BASIS}`}>
                            <ItemEditorSelectInput<MultipleChoiceDisplayType>
                                valueToDisplayNames={displayTypeDisplayNames}
                                dataTestId='multiple-choice-display-type'
                                inputId='multiple-choice-display-type'
                                itemId={itemDTO.id}
                                labelText='Item type'
                                disabled={!isDefaultLocale(locale) || editDisabled}
                                value={itemDTO.displayType}
                                setValue={(nextValue: MultipleChoiceDisplayType) => {
                                    if (
                                        MultipleChoiceHandler.checkResponsesHaveImage(
                                            itemDTO.responses
                                        ) ||
                                        (nextValue === MultipleChoiceDisplayType.Image &&
                                            itemDTO.responses.length > 4) ||
                                        (itemDTO.displayType === MultipleChoiceDisplayType.TABLE &&
                                            MultipleChoiceHandler.checkTableLayoutUpdated(
                                                itemDTO.tableResponses
                                            ))
                                    ) {
                                        open()
                                        setNextDisplayType(nextValue)
                                    } else {
                                        multipleChoiceHandler.updateDisplayType(
                                            itemDTO.id,
                                            nextValue
                                        )
                                    }
                                }}
                            />
                        </Col>
                    )}
                </WithModal>
                <Col
                    gridGap={'S200'}
                    padding={{ top: `${matches.s ? '8px' : '35px'}` }}
                    flex={`1 0 ${SELECTION_TYPE_FLEX_BASIS}`}
                >
                    <ItemEditorCheckboxInput
                        inputId={'selection-type'}
                        dataTestId={'multiple-choice-selection-type'}
                        itemId={itemDTO.id}
                        labelText={'Multi-select'}
                        value={itemDTO.multipleChoiceType === MultipleChoiceType.MultiSelect}
                        disabled={!isDefaultLocale(locale) || editDisabled}
                        setValue={(nextValue: boolean) => {
                            if (nextValue) {
                                multipleChoiceHandler.updateMultipleChoiceType(
                                    itemDTO.id,
                                    MultipleChoiceType.MultiSelect
                                )
                            } else {
                                multipleChoiceHandler.updateMultipleChoiceType(
                                    itemDTO.id,
                                    MultipleChoiceType.SingleSelect
                                )
                            }
                        }}
                    />
                </Col>
            </Row>
            <Spacer height='S400' />
            <Row>
                <Col flexGrow={1} width={flexItemWidth}>
                    <ItemEditorTextInput
                        inputId='questionI18N'
                        dataTestId='multiple-choice-question'
                        value={itemDTO.questionI18N[locale] ?? ''}
                        disabled={editDisabled}
                        placeholder={'Enter the stimulus question'}
                        itemId={itemDTO.id}
                        setValue={(nextValue: string) => {
                            multipleChoiceHandler.updateQuestion(itemDTO.id, locale, nextValue)
                        }}
                        validationErrorMessage={(
                            validationErrorEntity.validationErrors.questionI18N ?? []
                        ).join(', ')}
                        showError={showValidationError}
                        labelText={'Stimulus'}
                    />
                </Col>
            </Row>
            <Spacer height='S400' />
            <MCLayout
                locale={locale}
                itemDTO={itemDTO}
                showValidationError={showValidationError}
                editDisabled={editDisabled}
            />
            {itemDTO.displayType !== MultipleChoiceDisplayType.TABLE && (
                <ResponsiveRow padding={'S200'} justifyContent={'center'}>
                    {!matches.s && <Spacer flex={1} />}
                    <Button
                        dataTestId='multiple-choice-item-add-response-option-button'
                        icon={<IconPlus aria-hidden />}
                        variant={ButtonVariant.Tertiary}
                        aria-disabled={
                            !isDefaultLocale(locale) ||
                            (itemDTO.displayType === MultipleChoiceDisplayType.Image &&
                                itemDTO.responses.length >= 4) ||
                            editDisabled
                        }
                        onClick={() => {
                            multipleChoiceHandler.addResponse(itemDTO.id)
                        }}
                    >
                        Add response option
                    </Button>
                </ResponsiveRow>
            )}
            <ResponsiveRow padding={'S200'} justifyContent={'center'} alignItems={'center'}>
                {!matches.s && <Spacer flex={1} />}
                <ItemEditorCheckboxInput
                    dataTestId='multiple-choice-randomize-responses'
                    inputId='responseOrderRandomizationEnabled'
                    itemId={itemDTO.id}
                    labelText='Randomize Responses'
                    value={itemDTO.responseOrderRandomizationEnabled}
                    disabled={!isDefaultLocale(locale) || editDisabled}
                    setValue={(nextValue: boolean) => {
                        multipleChoiceHandler.updateResponseOrderRandomizationEnabled(
                            itemDTO.id,
                            nextValue
                        )
                    }}
                />
            </ResponsiveRow>
            {itemDTO.multipleChoiceType === MultipleChoiceType.MultiSelect && (
                <>
                    <Spacer height='S100' />
                    <Row>
                        <Col flexGrow={1} width={flexItemWidth}>
                            <GenericItemEditorSelectInput
                                dataTestId='multiple-choice-num-required-responses'
                                inputId='numRequiredResponses'
                                value={itemDTO.numRequiredResponses}
                                disabled={!isDefaultLocale(locale) || editDisabled}
                                options={Array.from(displayNameToValues.keys())}
                                itemId={itemDTO.id}
                                setValue={(nextValue: number | undefined) => {
                                    if (typeof nextValue === 'undefined') {
                                        multipleChoiceHandler.updateNumRequiredResponses(
                                            itemDTO.id,
                                            undefined
                                        )
                                    } else {
                                        multipleChoiceHandler.updateNumRequiredResponses(
                                            itemDTO.id,
                                            nextValue
                                        )
                                    }
                                }}
                                validationErrorMessage={(
                                    validationErrorEntity.validationErrors.numRequiredResponses ??
                                    []
                                ).join(', ')}
                                showError={showValidationError}
                                labelText='Number of required responses'
                                valueToDisplayNames={valueToDisplayNames}
                                displayNamesToValues={displayNameToValues}
                            />
                        </Col>
                    </Row>
                </>
            )}
            {MultipleChoiceHandler.showFreeTextResponse(itemDTO.displayType) && (
                <Flex
                    flexDirection={matches.s ? 'column' : 'row'}
                    gridGap={matches.s ? 'S300' : 'S200'}
                >
                    <Col
                        padding={{ top: `${matches.s ? '8px' : '35px'}`, left: 'S100' }}
                        gridGap={'S200'}
                    >
                        <ItemEditorCheckboxInput
                            dataTestId='free-text-response-checkbox'
                            inputId='free-text-response-checkbox'
                            itemId={itemDTO.id}
                            disabled={!isDefaultLocale(locale) || editDisabled}
                            labelText={'Add free text response'}
                            value={itemDTO.freeTextResponse?.responseEnabled ?? false}
                            setValue={(nextValue: boolean) => {
                                multipleChoiceHandler.updateFreeTextCheckbox(itemDTO.id, nextValue)
                            }}
                        />
                    </Col>
                    {itemDTO.freeTextResponse.responseEnabled && (
                        <Col
                            flex={`3 0 ${UNIQUE_ITEM_ID_FLEX_BASIS}`}
                            maxWidth={matches.s ? '100%' : '40%'}
                        >
                            <ItemEditorTextInput
                                dataTestId={'free-text-response-label'}
                                inputId={'free-text-response-label'}
                                placeholder={'Specify your own answer'}
                                disabled={editDisabled}
                                itemId={itemDTO.id}
                                value={itemDTO.freeTextResponse?.freeTextResponseI18N[locale] ?? ''}
                                setValue={(response) => {
                                    multipleChoiceHandler.updateFreeTextResponse(
                                        itemDTO.id,
                                        locale,
                                        response
                                    )
                                }}
                                labelText={'Free text response'}
                                validationErrorMessage={(
                                    validationErrorEntity.validationErrors[
                                        'freeTextResponse.freeTextResponseI18N'
                                    ] ?? []
                                ).join(', ')}
                                showError={showValidationError}
                            />
                        </Col>
                    )}
                </Flex>
            )}
        </>
    )
}
