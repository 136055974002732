import React, { useContext, useState } from 'react'

import { Button, ButtonSize, ButtonVariant } from '@amzn/stencil-react-components/button'
import { StencilContext } from '@amzn/stencil-react-components/context'
import { Col } from '@amzn/stencil-react-components/layout'
import { Modal } from '@amzn/stencil-react-components/modal'
import { useBreakpoints } from '@amzn/stencil-react-components/responsive'

import { ModalCloseButtonComponent } from 'src/components/MediaContentsEditor/ModalCloseButtonComponent'
import { isDefaultLocale } from 'src/contexts/ModuleLocaleContext'
import { Locale } from 'src/models/dto/Locale'
import { AssetLibraryPageBody } from 'src/pages/asset-library/AssetLibraryPage'
import { EditAssetPage } from 'src/pages/asset-library/AssetUploadedPage'
import { DisplayType, MediaTemplate } from 'src/pages/asset-library/types'
import { FocusId } from 'src/pages/module-builder/focus'

export function MediaManagerModal({
    selectMediaVersionId,
    onClose,
    locale,
    buttonText,
    buttonVariant,
    buttonFocusId,
    lockedFileTypes,
    disabled,
}: {
    selectMediaVersionId: (mediaVersionId: string) => void
    onClose?: () => void
    locale: Locale
    buttonText?: string
    buttonVariant?: ButtonVariant
    buttonFocusId?: FocusId
    lockedFileTypes?: string[]
    disabled?: boolean
}) {
    const { theme } = useContext(StencilContext)
    const [currentVersionId, setCurrentVersionId] = useState<string | undefined>()
    const [isOpen, setIsOpen] = useState(false)
    const {
        matches: { s: isSmall },
    } = useBreakpoints()

    const open = () => {
        setCurrentVersionId(undefined)
        setIsOpen(true)
    }

    const close = () => {
        setIsOpen(false)
        onClose?.()
    }

    const width = theme.constants.maxWidth
    const maxWidth = isSmall ? '100vw' : '95vw'
    const minHeight = isSmall ? '100vh' : '95vh'

    return (
        <>
            <Button
                variant={buttonVariant ? buttonVariant : ButtonVariant.Primary}
                onClick={open}
                aria-disabled={!isDefaultLocale(locale) || disabled}
                dataTestId='media-manager-add-media'
                id={buttonFocusId}
            >
                {buttonText ? buttonText : 'Add Media'}
            </Button>
            <Modal isOpen={isOpen} close={close} isScrollable={false}>
                <>
                    <div
                        style={{
                            width,
                            maxWidth,
                            minHeight,
                            height: minHeight,
                            backgroundColor: '#F8F8F8',
                            overflow: 'auto',
                        }}
                    >
                        <div
                            style={{
                                padding: '5px',
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <ModalCloseButtonComponent close={close} />
                        </div>
                        <div style={{ marginTop: '-60px' }}>
                            {currentVersionId ? (
                                <EditAssetPage
                                    isEmbedded={true}
                                    useMedia={() => {
                                        selectMediaVersionId(currentVersionId)
                                        close()
                                    }}
                                    cancel={() => {
                                        setCurrentVersionId(undefined)
                                    }}
                                    mediaId={currentVersionId}
                                />
                            ) : (
                                <AssetLibraryPageBody
                                    displayType={DisplayType.ASSIGN_TO_MODULE_PAGE}
                                    embedded={true}
                                    pageSize={10}
                                    selectUse={(versionId) => {
                                        selectMediaVersionId(versionId)
                                        close()
                                    }}
                                    selectView={setCurrentVersionId}
                                    lockedFileTypes={lockedFileTypes}
                                    actionsColumn={{
                                        header: '',
                                        accessor: ({ data }: { data: MediaTemplate }) => data,
                                        cellComponent: ({
                                            data: media,
                                        }: {
                                            data: MediaTemplate
                                            index: number
                                        }) => {
                                            return (
                                                <Col
                                                    alignItems={'center'}
                                                    justifyContent={'space-around'}
                                                    height={'100%'}
                                                >
                                                    <Button
                                                        size={ButtonSize.Small}
                                                        variant={ButtonVariant.Tertiary}
                                                        dataTestId='use-search-result'
                                                        onClick={(e) => {
                                                            e.preventDefault()
                                                            selectMediaVersionId(media.versionId)
                                                            close()
                                                        }}
                                                    >
                                                        Use
                                                    </Button>
                                                    <Button
                                                        size={ButtonSize.Small}
                                                        variant={ButtonVariant.Tertiary}
                                                        dataTestId='view-search-result'
                                                        onClick={(e) => {
                                                            e.preventDefault()
                                                            setCurrentVersionId(media.versionId)
                                                        }}
                                                    >
                                                        View
                                                    </Button>
                                                </Col>
                                            )
                                        },
                                    }}
                                />
                            )}
                        </div>
                    </div>
                </>
            </Modal>
        </>
    )
}
