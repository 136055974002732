import React, { useMemo } from 'react'

import { Col, Row, Spacer } from '@amzn/stencil-react-components/layout'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'
import { useBreakpoints } from '@amzn/stencil-react-components/responsive'

import { IconWithTooltip } from 'src/components/IconWithTooltip'
import { Features } from 'src/config.app'
import { isDefaultLocale } from 'src/contexts/ModuleLocaleContext'
import { isFeatureEnabled } from 'src/helper/featureFlagHelper'
import { useEntity, useItemEntity } from 'src/hooks/useEntity'
import {
    AudioEvaluationItemDTO,
    ModelVersion,
    ScoringMLModelType,
    TranscriptionMLModelType,
} from 'src/models/dto/items/AudioEvaluationItemDTO'
import { GENERIC_ERROR_KEY, ValidationErrorEntity } from 'src/models/dto/TemplateValidationError'
import { ItemEditorProps } from 'src/pages/module-builder/item-editors/ItemEditorContainer'
import {
    GenericItemEditorSelectInput,
    ItemEditorCheckboxInput,
    ItemEditorTextArea,
    ItemEditorTextInput,
} from 'src/pages/module-builder/item-editors/ItemEditorInputs'
import { ItemEntityService } from 'src/services/EntityServices/ItemEntityService'
import { AudioEvaluationHandler } from 'src/services/EntityServices/ItemUpdateHandlers/AudioEvaluationHandler'
import {
    VALIDATION_ERROR_ENTITY_STORE_SELECTOR,
    ValidationErrorEntityService,
} from 'src/services/EntityServices/ValidationErrorEntityService'

const transcriptionModelTypeVersions = new Map<TranscriptionMLModelType, ModelVersion>([
    [
        TranscriptionMLModelType.ACE_SALES_TRANSCRIPTION_MODEL,
        {
            displayName: 'Ace Sales Transcription',
            versions: ['1.0'],
        },
    ],
    [
        TranscriptionMLModelType.AA_Sales_Transcribe_en_US,
        {
            displayName: 'AA Sales Transcribe EN US',
            versions: ['1.0'],
        },
    ],
])

const getScoringModelTypeVersions = () => {
    const baseModelVersions = new Map<ScoringMLModelType, ModelVersion>([
        [
            ScoringMLModelType.AA_ACE_Sales_NLP_Scoring,
            {
                displayName: 'Ace Sales NLP Scoring',
                versions: ['1.0', '2.0'],
            },
        ],
        [
            ScoringMLModelType.ACE_SALES_SCORING_MODEL,
            {
                displayName: 'AA Sales Scoring',
                versions: ['1.0'],
            },
        ],
        [
            ScoringMLModelType.AA_Sales_RoW,
            {
                displayName: 'AA Sales RoW',
                versions: ['2.0', '3.0'],
            },
        ],
        [
            ScoringMLModelType.AA_Sales_NATAM,
            {
                displayName: 'AA Sales NATAM',
                versions: ['2.0', '3.0'],
            },
        ],
        [
            ScoringMLModelType.Customer_Facing,
            {
                displayName: 'Customer Facing',
                versions: ['1.0', '1.1'],
            },
        ],
    ])

    if (isFeatureEnabled(Features.LLM_MIXTRAL_CRA_SCORING)) {
        baseModelVersions.set(ScoringMLModelType.LLM_Mixtral_CRA_Scoring, {
            displayName: 'Mixtral',
            versions: ['1.0'],
        })
    }

    return baseModelVersions
}

// Get unique display names
export const getDisplayNames = <T,>(modelVersions: Map<T, ModelVersion>): string[] => {
    return Array.from(modelVersions.values()).map((model) => model.displayName)
}

// Get all versions for a specific model type
export const getVersions = <T,>(modelVersions: Map<T, ModelVersion>, modelType: T): string[] => {
    return modelVersions.get(modelType)?.versions.sort((a, b) => a.localeCompare(b)) || []
}

export const getOptions = <T,>(displayNames: Map<T, string>): string[] => {
    return Array.from(displayNames.values())
}

const transcriptionsModelTypeOptions: string[] = getDisplayNames(transcriptionModelTypeVersions)

export const disallowedMlModelVersionsForSoundCheck = new Set(['1.0'])
export const maxNumAttemptsOptions: string[] = ['1', '2', '3', '4', '5']
export const maxRecordingTimeOptions: string[] = [
    '15',
    '30',
    '45',
    '60',
    '75',
    '90',
    '105',
    '120',
    '135',
    '150',
    '165',
    '180',
    '195',
    '210',
    '225',
    '240',
    '255',
    '270',
    '285',
    '300',
]

const transcriptionDisplayNamesToValues: Map<string, TranscriptionMLModelType> = new Map(
    Array.from(transcriptionModelTypeVersions.entries()).map(([modelType, modelVersion]) => [
        modelVersion.displayName,
        modelType,
    ])
)

const scoringDisplayNamesToValues: Map<string, ScoringMLModelType> = new Map(
    Array.from(getScoringModelTypeVersions().entries()).map(([modelType, modelVersion]) => [
        modelVersion.displayName,
        modelType,
    ])
)

const transcriptionValuesDisplayNames: Map<TranscriptionMLModelType, string> = new Map(
    Array.from(transcriptionModelTypeVersions.entries()).map(([modelType, modelVersion]) => [
        modelType,
        modelVersion.displayName,
    ])
)

const scoringValuesToDisplayNames: Map<ScoringMLModelType, string> = new Map(
    Array.from(getScoringModelTypeVersions().entries()).map(([modelType, modelVersion]) => [
        modelType,
        modelVersion.displayName,
    ])
)

export const maxNumAttemptsToValues: Map<string, number> = new Map<string, number>(
    Array.from(maxNumAttemptsOptions).map((rec) => {
        return [rec, +rec] as [string, number]
    })
)

export const maxNumAttemptsToName: Map<number, string> = new Map<number, string>(
    Array.from(maxNumAttemptsOptions).map((rec) => {
        return [+rec, rec] as [number, string]
    })
)

export const maxRecordingToValues: Map<string, number> = new Map<string, number>(
    Array.from(maxRecordingTimeOptions).map((rec) => {
        return [rec, +rec] as [string, number]
    })
)

export const maxRecordingToName: Map<number, string> = new Map<number, string>(
    Array.from(maxRecordingTimeOptions).map((rec) => {
        return [+rec, rec] as [number, string]
    })
)

export const mlModelVersionsToValues: Map<string, string> = new Map(
    [
        ...Array.from(transcriptionModelTypeVersions.values()),
        ...Array.from(getScoringModelTypeVersions().values()),
    ].reduce((acc: [string, string][], modelVersion) => {
        modelVersion.versions.forEach((version) => {
            acc.push([version, version])
        })
        return acc
    }, [])
)

export const mlModelVersionsToValuesForScoring: Map<string, string> = new Map(
    Array.from(getScoringModelTypeVersions().values()).reduce(
        (acc: [string, string][], modelVersion) => {
            modelVersion.versions.forEach((version) => {
                acc.push([version, version])
            })
            return acc
        },
        []
    )
)

export const mlModelVersionsToValuesForTranscription: Map<string, string> = new Map(
    Array.from(transcriptionModelTypeVersions.values()).reduce(
        (acc: [string, string][], modelVersion) => {
            modelVersion.versions.forEach((version) => {
                acc.push([version, version])
            })
            return acc
        },
        []
    )
)

export const getLatestVersion = <T,>(modelVersions: Map<T, ModelVersion>, modelType: T): string => {
    const versions = modelVersions.get(modelType)?.versions
    if (!versions?.length) throw new Error('No model versions found')

    // Sort in descending order to get the latest version
    return versions.sort((a, b) => b.localeCompare(a))[0]
}

export const AudioEvaluationItemEditor = ({
    itemId,
    locale,
    showValidationError,
    workflowEntityId,
    editDisabled,
}: ItemEditorProps) => {
    const { entity: itemDTO } = useItemEntity<AudioEvaluationItemDTO>({
        entityId: itemId,
        workflowEntityId,
    })

    const { matches } = useBreakpoints()

    if (!ValidationErrorEntityService.has(itemId)) {
        ValidationErrorEntityService.create(itemId)
    }

    const { entity: validationErrorEntity } = useEntity<ValidationErrorEntity>({
        entityId: itemId,
        selector: VALIDATION_ERROR_ENTITY_STORE_SELECTOR,
    })

    const flexItemWidth = matches.s ? '100%' : 'default'
    const UNIQUE_ITEM_ID_FLEX_BASIS = matches.s ? '100%' : '200px'
    const CHECKBOX_FLEX_BASIS = `0 0 ${matches.s ? '100%' : '80px'}`
    const SELECT_INPUT_FLEX_BASIS = `2 0 ${matches.s ? '100%' : '200px'}`

    const scoringModelTypeVersions = useMemo(() => getScoringModelTypeVersions(), [])
    const scoringModelTypeOptions: string[] = useMemo(
        () => getDisplayNames(scoringModelTypeVersions),
        [scoringModelTypeVersions]
    )

    return (
        <>
            {validationErrorEntity.validationErrors[GENERIC_ERROR_KEY] && showValidationError && (
                <MessageBanner type={MessageBannerType.Error}>
                    <ul>
                        {validationErrorEntity.validationErrors[GENERIC_ERROR_KEY].map(
                            (message, index) => (
                                <li key={index}>{message}</li>
                            )
                        )}
                    </ul>
                </MessageBanner>
            )}
            <Row gridGap='S300' alignItems={'flex-start'} flexWrap={'wrap'}>
                <Col flex={`3 0 ${UNIQUE_ITEM_ID_FLEX_BASIS}`}>
                    <ItemEditorTextInput
                        inputId={'unique-item-id'}
                        dataTestId={'unique-item-id'}
                        value={itemDTO.label}
                        disabled={!isDefaultLocale(locale) || editDisabled}
                        placeholder={'Some human readable label'}
                        itemId={itemDTO.id}
                        setValue={(nextValue: string) => {
                            ItemEntityService.updateLabel(itemDTO.id, nextValue)
                        }}
                        validationErrorMessage={(
                            validationErrorEntity.validationErrors.label ?? []
                        ).join(', ')}
                        showError={showValidationError}
                        labelText={'Unique Item ID'}
                    />
                </Col>
                <Col
                    gridGap={'S200'}
                    padding={{ top: `${matches.s ? '8px' : '30px'}` }}
                    flex={CHECKBOX_FLEX_BASIS}
                >
                    <Row alignItems={'center'}>
                        <ItemEditorCheckboxInput
                            inputId={'experimental'}
                            dataTestId={'experimental-checkbox'}
                            itemId={itemDTO.id}
                            labelText={'Experimental'}
                            value={itemDTO.experimental}
                            disabled={!isDefaultLocale(locale) || editDisabled}
                            setValue={(nextValue: boolean) => {
                                AudioEvaluationHandler.updateExperimental(itemId, nextValue)
                            }}
                        />
                        <IconWithTooltip tooltipText='Is this item used for research and not being scored?' />
                    </Row>
                </Col>
                <Col
                    gridGap={'S200'}
                    padding={{ top: `${matches.s ? '8px' : '35px'}` }}
                    flex={CHECKBOX_FLEX_BASIS}
                >
                    <ItemEditorCheckboxInput
                        inputId={'optional'}
                        dataTestId={'optional-checkbox'}
                        itemId={itemDTO.id}
                        labelText={'Optional'}
                        disabled={!isDefaultLocale(locale) || editDisabled}
                        value={itemDTO.optional}
                        setValue={(nextValue: boolean) => {
                            AudioEvaluationHandler.updateOptional(itemId, nextValue)
                        }}
                    />
                </Col>
                <Col
                    gridGap={'S200'}
                    padding={{ top: `${matches.s ? '8px' : '29px'}` }}
                    flex={'0 0 170px'}
                >
                    <Row alignItems={'center'}>
                        <ItemEditorCheckboxInput
                            inputId={'preserve-order'}
                            itemId={itemDTO.id}
                            disabled={!isDefaultLocale(locale) || editDisabled}
                            labelText={'Preserve order'}
                            dataTestId={'preserve-order-checkbox'}
                            value={itemDTO.preserveOrder}
                            setValue={(nextValue: boolean) => {
                                AudioEvaluationHandler.updatePreserveOrder(itemId, nextValue)
                            }}
                        />
                        <IconWithTooltip tooltipText='When randomizing page, ensure this items position is never changed' />
                    </Row>
                </Col>
                <Col flex={SELECT_INPUT_FLEX_BASIS}>
                    <GenericItemEditorSelectInput
                        valueToDisplayNames={maxNumAttemptsToName}
                        inputId={'audio-eval-max-attempts'}
                        dataTestId={'max-number-of-attempts'}
                        itemId={itemDTO.id}
                        labelText={'Maximum Attempts'}
                        options={maxNumAttemptsOptions}
                        disabled={!isDefaultLocale(locale) || editDisabled}
                        displayNamesToValues={maxNumAttemptsToValues}
                        value={itemDTO.maxNumberOfAttempts}
                        setValue={(nextValue) => {
                            AudioEvaluationHandler.updateMaxNumberOfAttempts(itemId, nextValue)
                        }}
                        validationErrorMessage={(
                            validationErrorEntity.validationErrors.maxNumberOfAttempts ?? []
                        ).join(', ')}
                        showError={showValidationError}
                    />
                </Col>
                <Col flex={SELECT_INPUT_FLEX_BASIS}>
                    <GenericItemEditorSelectInput
                        valueToDisplayNames={maxRecordingToName}
                        inputId={'audio-eval-max-recording-time'}
                        dataTestId={'max-recording-time-in-seconds'}
                        itemId={itemDTO.id}
                        labelText={'Maximum Recording Time (sec)'}
                        disabled={!isDefaultLocale(locale) || editDisabled}
                        options={maxRecordingTimeOptions}
                        displayNamesToValues={maxRecordingToValues}
                        value={itemDTO.maxRecordingTimeInSeconds}
                        setValue={(nextValue) => {
                            AudioEvaluationHandler.updateMaxRecordingTimeInSeconds(
                                itemId,
                                nextValue
                            )
                        }}
                        validationErrorMessage={(
                            validationErrorEntity.validationErrors.maxRecordingTimeInSeconds ?? []
                        ).join(', ')}
                        showError={showValidationError}
                    />
                </Col>
            </Row>
            <Spacer height={'S200'} />
            <Row gridGap='S300' alignItems={'flex-start'} flexWrap={'wrap'}>
                <Col flex={SELECT_INPUT_FLEX_BASIS}>
                    <GenericItemEditorSelectInput
                        valueToDisplayNames={transcriptionValuesDisplayNames}
                        inputId={'audio-eval-transcription-ml-model'}
                        dataTestId={'transcription-ml-model'}
                        itemId={itemDTO.id}
                        labelText={'Transcription ML Model'}
                        disabled={!isDefaultLocale(locale) || editDisabled}
                        options={transcriptionsModelTypeOptions}
                        displayNamesToValues={transcriptionDisplayNamesToValues}
                        value={itemDTO.transcriptionMLModel}
                        setValue={(nextValue) => {
                            AudioEvaluationHandler.updateTranscriptionMlModel(itemId, nextValue)
                            AudioEvaluationHandler.updateScoringMlModelVersion(
                                itemId,
                                getLatestVersion(transcriptionModelTypeVersions, nextValue)
                            )
                        }}
                        validationErrorMessage={(
                            validationErrorEntity.validationErrors.transcriptionMLModel ?? []
                        ).join(', ')}
                        showError={showValidationError}
                    />
                </Col>
                <Col flex={SELECT_INPUT_FLEX_BASIS}>
                    <GenericItemEditorSelectInput
                        valueToDisplayNames={mlModelVersionsToValues}
                        inputId={'audio-eval-transcription-ml-model-version'}
                        dataTestId={'transcription-ml-model-version'}
                        itemId={itemDTO.id}
                        labelText={'Transcription ML Model Version'}
                        options={getVersions(
                            transcriptionModelTypeVersions,
                            itemDTO.transcriptionMLModel
                        )}
                        displayNamesToValues={mlModelVersionsToValuesForTranscription}
                        disabled={!isDefaultLocale(locale) || editDisabled}
                        value={itemDTO.transcriptionMLModelVersion}
                        setValue={(nextValue) => {
                            AudioEvaluationHandler.updateTranscriptionMlModelVersion(
                                itemId,
                                nextValue
                            )
                        }}
                        validationErrorMessage={(
                            validationErrorEntity.validationErrors.transcriptionMLModelVersion ?? []
                        ).join(', ')}
                        showError={showValidationError}
                    />
                </Col>
                <Col flex={SELECT_INPUT_FLEX_BASIS}>
                    <GenericItemEditorSelectInput
                        valueToDisplayNames={scoringValuesToDisplayNames}
                        inputId={'audio-eval-scoring-ml-model'}
                        dataTestId={'scoring-ml-model'}
                        disabled={!isDefaultLocale(locale) || editDisabled}
                        itemId={itemDTO.id}
                        labelText={'Scoring ML Model'}
                        options={scoringModelTypeOptions}
                        displayNamesToValues={scoringDisplayNamesToValues}
                        value={itemDTO.scoringMLModel}
                        setValue={(nextValue) => {
                            AudioEvaluationHandler.updateScoringMlModel(itemId, nextValue)
                            AudioEvaluationHandler.updateScoringMlModelVersion(
                                itemId,
                                getLatestVersion(scoringModelTypeVersions, nextValue)
                            )
                        }}
                        validationErrorMessage={(
                            validationErrorEntity.validationErrors.scoringMLModel ?? []
                        ).join(', ')}
                        showError={showValidationError}
                    />
                </Col>
                <Col flex={SELECT_INPUT_FLEX_BASIS}>
                    <GenericItemEditorSelectInput
                        valueToDisplayNames={mlModelVersionsToValuesForScoring}
                        inputId={'audio-eval-scoring-ml-model-version'}
                        dataTestId={'scoring-ml-model-version'}
                        disabled={!isDefaultLocale(locale) || editDisabled}
                        itemId={itemDTO.id}
                        labelText={'Scoring ML Model Version'}
                        options={getVersions(scoringModelTypeVersions, itemDTO.scoringMLModel)}
                        displayNamesToValues={mlModelVersionsToValuesForScoring}
                        value={itemDTO.scoringMLModelVersion}
                        setValue={(nextValue) => {
                            AudioEvaluationHandler.updateScoringMlModelVersion(itemId, nextValue)
                        }}
                        validationErrorMessage={(
                            validationErrorEntity.validationErrors.scoringMLModelVersion ?? []
                        ).join(', ')}
                        showError={showValidationError}
                    />
                </Col>
            </Row>
            <Spacer height={'S200'} />
            <Row alignItems={'center'}>
                <Col flexGrow={1} width={flexItemWidth}>
                    <ItemEditorTextArea
                        inputId={'situation'}
                        locale={locale}
                        dataTestId={'audio-evaluation-situation-editor'}
                        value={itemDTO.situationI18N[locale] ?? ''}
                        disabled={editDisabled}
                        itemId={itemDTO.id}
                        setValue={(nextValue: string) => {
                            AudioEvaluationHandler.updateSituation(itemId, locale, nextValue)
                        }}
                        validationErrorMessage={(
                            validationErrorEntity.validationErrors.situationI18N ?? []
                        ).join(', ')}
                        showError={showValidationError}
                        labelText={'Situation'}
                    />
                </Col>
            </Row>
            <Spacer height={'S200'} />
            <Row alignItems={'center'}>
                <Col flexGrow={1} width={flexItemWidth}>
                    <ItemEditorTextArea
                        inputId={'task'}
                        locale={locale}
                        dataTestId={'audio-evaluation-task-editor'}
                        value={itemDTO.taskI18N[locale] ?? ''}
                        disabled={editDisabled}
                        itemId={itemDTO.id}
                        setValue={(nextValue: string) => {
                            AudioEvaluationHandler.updateTask(itemId, locale, nextValue)
                        }}
                        validationErrorMessage={(
                            validationErrorEntity.validationErrors.taskI18N ?? []
                        ).join(', ')}
                        showError={showValidationError}
                        labelText={'Task'}
                    />
                </Col>
            </Row>
        </>
    )
}
