import React, { useCallback, useMemo, useRef, useState } from 'react'
import styled from '@emotion/styled'

import { Button, ButtonVariant } from '@amzn/stencil-react-components/button'
import { styledWithTheme } from '@amzn/stencil-react-components/context'
import {
    IconAlertTriangleFill,
    IconBin,
    IconPencil,
    IconPlus,
} from '@amzn/stencil-react-components/icons'
import { Col, Row, Spacer, View } from '@amzn/stencil-react-components/layout'
import { Modal, ModalContent } from '@amzn/stencil-react-components/modal'
import { ScreenReaderOnly } from '@amzn/stencil-react-components/screen-reader-only'
import { Text } from '@amzn/stencil-react-components/text'

import { isDefaultLocale } from 'src/contexts/ModuleLocaleContext'
import {
    BaseTableDTO,
    TableCell,
    TableCellType,
    TableIconType,
    TableSymbolType,
} from 'src/models/dto/items/BaseTableDTO'
import { Locale } from 'src/models/dto/Locale'
import { focusOnId } from 'src/pages/module-builder/focus'
import {
    getCellDataAfterAddition,
    getCellDataAfterDelete,
    replaceCellSymbol,
} from 'src/pages/module-builder/item-editors/table-editor/SymbolLegend'
import {
    EditCellComponentProps,
    Header,
    ViewCellComponentProps,
} from 'src/pages/module-builder/item-editors/table-editor/TableEditor'
import { TableOperations } from 'src/services/EntityServices/ItemUpdateHandlers/TableOperations'
import { ItemEditorSelectInput, ItemEditorTextArea, ItemEditorTextInput } from '../ItemEditorInputs'

export const CellButton = styledWithTheme('button')(({ theme }) => ({
    background: 'transparent',
    padding: `${theme.selectors.space('S200') ?? '8px'} ${theme.selectors.space('S300') ?? '16px'}`,
    display: 'block',
    width: '100%',
    height: '100%',
    border: 'none',
    cursor: 'pointer',
}))

export const ThNoPadding = styled('th')({
    paddingTop: 0,
    paddingBottom: 0,
    verticalAlign: 'middle',
})

export const TdNoPadding = styled('td')({
    paddingTop: 0,
    paddingBottom: 0,
    verticalAlign: 'middle',
})

function CellWithEditIcon({ title, children }: { title: string; children: React.ReactChild }) {
    return (
        <Row
            alignItems='center'
            justifyContent='flex-start'
            padding={{ left: 'S100', right: 'S100' }}
            gridGap='S200'
        >
            <View>{children}</View> <IconPencil title={`, ${title}`} color='primary70' />
        </Row>
    )
}

interface TableCellProps<CellData> {
    dataTestId?: string
    defaultNextData: (rowNum: number, colNum: number, cellType: TableCellType) => CellData
    onClear?: (rowCol: { rowNum: number; colNum: number }) => void
    cellData?: CellData
    cellViewComponent: (props: ViewCellComponentProps<CellData>) => JSX.Element
    cellEditComponent: (props: EditCellComponentProps<CellData>) => JSX.Element
    cellEditModalTitleText?: (rowNum: number, colNum: number) => string
    saveChanges: (nextCellData: CellData) => void
    itemId: string
    rowNumber: number
    columnNumber: number
    symbolLegends: Map<TableSymbolType, string>
    disabled?: boolean
}

export function NoDataCell({ text = 'No data', disabled }: { text?: string; disabled?: boolean }) {
    return (
        <Row
            alignItems='center'
            gridGap='S200'
            padding={{ top: 'S300', bottom: 'S300' }}
            height='100%'
        >
            <IconAlertTriangleFill aria-hidden />
            <Text color='red70'>{text}</Text>
            {!disabled && <ScreenReaderOnly>: Required</ScreenReaderOnly>}
        </Row>
    )
}

function CellPopover({
    titleText,
    target,
    close,
    isOpen,
    buttons,
    children: body,
    dataTestId,
}: {
    titleText: string
    isOpen: boolean
    target: React.MutableRefObject<null>
    close: () => void
    buttons: React.ReactNode[]
    dataTestId?: string
    children: () => React.ReactNode
}) {
    return (
        <Modal close={close} isOpen={isOpen} shouldCloseOnClickOutside dataTestId={dataTestId}>
            <ModalContent
                maxWidth='calc(100vw - 32px)'
                ref={target as never}
                titleText={titleText}
                buttons={buttons}
            >
                {isOpen ? body() : <div />}
            </ModalContent>
        </Modal>
    )
}

export function TableCell2<CellData>({
    itemId,
    cellData,
    onClear,
    defaultNextData,
    cellEditComponent,
    cellViewComponent,
    cellEditModalTitleText,
    columnNumber,
    rowNumber,
    saveChanges,
    dataTestId,
    symbolLegends,
    disabled,
}: TableCellProps<CellData>) {
    const target = useRef(null)
    const [isOpen, setIsOpen] = useState(false)
    const [nextCellData, setNextCellData] = useState<CellData>(
        cellData ?? defaultNextData(rowNumber, columnNumber, TableCellType.TEXT)
    )

    const open = useCallback(() => {
        setNextCellData(cellData ?? defaultNextData(rowNumber, columnNumber, TableCellType.TEXT))
        setIsOpen(true)
    }, [cellData, columnNumber, defaultNextData, rowNumber])

    const close = useCallback(() => {
        setIsOpen(false)
    }, [])

    const onClearCleared = useCallback(() => {
        onClear?.({ colNum: columnNumber, rowNum: rowNumber })
        setNextCellData(
            defaultNextData(
                rowNumber,
                columnNumber,
                (nextCellData && (nextCellData['type'] as TableCellType)) ?? TableCellType.TEXT
            )
        )
    }, [onClear, columnNumber, rowNumber, nextCellData, defaultNextData])

    const buttons = useMemo(
        () => [
            <Button
                key='clear-button'
                variant={ButtonVariant.Tertiary}
                onClick={(e) => {
                    e.stopPropagation()
                    onClearCleared()
                }}
            >
                Clear
            </Button>,
            <Spacer key='spacer' flex={1} />,
            <Button key='cancel-button' variant={ButtonVariant.Secondary} onClick={close}>
                Cancel
            </Button>,
            <Button
                key='save-cell-button'
                dataTestId='save-cell-button'
                variant={ButtonVariant.Primary}
                onClick={() => {
                    saveChanges(nextCellData)
                    close()
                }}
            >
                Save &amp; Close
            </Button>,
        ],
        [onClearCleared, saveChanges, nextCellData, close]
    )

    if (disabled) {
        return (
            <TdNoPadding key={`${rowNumber}-${columnNumber}`} style={{ padding: 0 }}>
                {cellData ? (
                    cellViewComponent({
                        itemId,
                        cellData,
                        colNum: columnNumber,
                        rowNum: rowNumber,
                        symbolLegends: symbolLegends,
                    })
                ) : (
                    <NoDataCell disabled={disabled} />
                )}
            </TdNoPadding>
        )
    }

    return (
        <TdNoPadding key={`${rowNumber}-${columnNumber}`} style={{ padding: 0 }}>
            <CellButton
                ref={target}
                data-test-id={dataTestId ?? `cell-${columnNumber}-${rowNumber}-button`}
                onClick={() => {
                    if (!isOpen) {
                        open()
                    }
                }}
            >
                <CellWithEditIcon title='Click to edit this cell'>
                    {cellData ? (
                        cellViewComponent({
                            itemId,
                            cellData,
                            colNum: columnNumber,
                            rowNum: rowNumber,
                            symbolLegends: symbolLegends,
                        })
                    ) : (
                        <NoDataCell />
                    )}
                </CellWithEditIcon>
            </CellButton>

            <CellPopover
                dataTestId={'edit-cell-popover'}
                titleText={
                    cellEditModalTitleText
                        ? cellEditModalTitleText(rowNumber, columnNumber)
                        : `Edit table cell: Column ${columnNumber + 1}, Row ${rowNumber + 1}`
                }
                {...{ buttons, target, close, isOpen }}
            >
                {() =>
                    cellEditComponent({
                        nextCellData,
                        setNextCellData,
                        colNum: columnNumber,
                        rowNum: rowNumber,
                        itemId,
                    })
                }
            </CellPopover>
        </TdNoPadding>
    )
}

const getHeaderText = (header: Header, locale: Locale) => {
    if (typeof header.headerName === 'string') {
        return header.headerName
    } else {
        return header.headerName[locale] ?? ''
    }
}

/**
 * renders a Table Header cell complete with default text and handler for editing
 * @param param0
 * @returns
 */
export function TableHeaderCell2({
    itemId,
    header,
    locale,
    updateHeader,
    columnIndex,
    dataTestId,
    disabled,
}: {
    itemId: string
    columnIndex: number
    header: Header
    locale: Locale
    updateHeader?: (nextHeader: string) => void
    dataTestId?: string
    disabled?: boolean
}) {
    const headerText = getHeaderText(header, locale)
    const target = useRef(null)
    const [isOpen, setIsOpen] = useState(false)
    const [nextHeadingText, setNextHeadingText] = useState<string>(headerText)

    const open = useCallback(() => setIsOpen(true), [])
    const close = useCallback(() => setIsOpen(false), [])

    const headingTooShort = nextHeadingText.trim().length > 0 ? 'Heading is required' : undefined

    const [focused, setFocused] = useState(false)

    const buttons = useMemo(
        () => [
            <Button
                key='clear-button'
                variant={ButtonVariant.Tertiary}
                onClick={(e) => {
                    e.stopPropagation()
                    setNextHeadingText('')
                }}
            >
                Clear
            </Button>,
            <Spacer key='spacer' flex={1} />,
            <Button key='cancel-button' variant={ButtonVariant.Secondary} onClick={close}>
                Cancel
            </Button>,
            <Button
                key='save-table-header-button'
                dataTestId='save-table-header-button'
                data-column-index={columnIndex}
                variant={ButtonVariant.Primary}
                onClick={() => {
                    if (updateHeader) {
                        updateHeader(nextHeadingText)
                    }
                    close()
                }}
            >
                Save &amp; Close
            </Button>,
        ],
        [columnIndex, setNextHeadingText, nextHeadingText, close, updateHeader]
    )

    if (!header.editable || disabled) {
        return (
            <ThNoPadding>
                <Row alignItems='center' gridGap='S200'>
                    {headerText.trim().length === 0 ? (
                        <NoDataCell disabled={disabled} />
                    ) : (
                        <Text>{headerText}</Text>
                    )}
                    {focused && <ScreenReaderOnly>{headerText}</ScreenReaderOnly>}
                </Row>
            </ThNoPadding>
        )
    }

    return (
        <ThNoPadding>
            <CellButton
                ref={target}
                onClick={open}
                data-test-id={dataTestId ?? `table-heading-${columnIndex}`}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
            >
                <CellWithEditIcon title={focused ? 'click to edit this heading' : ''}>
                    <Row alignItems='center' gridGap='S200'>
                        {headerText.trim().length === 0 ? (
                            <>
                                <IconAlertTriangleFill aria-hidden />
                                <Text color='red70'>Table Heading {columnIndex + 1}</Text>
                            </>
                        ) : (
                            <Text>{headerText}</Text>
                        )}
                        <ScreenReaderOnly>
                            {focused ? `, ${!headerText ? 'Required, ' : ''}` : ''}
                        </ScreenReaderOnly>
                    </Row>
                </CellWithEditIcon>
            </CellButton>
            <CellPopover
                titleText={`Edit table heading: Column ${columnIndex + 1}`}
                {...{ buttons, target, close, isOpen }}
            >
                {() => (
                    <Col>
                        <ItemEditorTextInput
                            dataTestId={`header-cell-heading-text-${columnIndex}`}
                            inputId={`header-cell-heading-text-${columnIndex}`}
                            itemId={itemId}
                            labelText={`Column ${columnIndex + 1} `}
                            validationErrorMessage={headingTooShort}
                            placeholder='Required field'
                            disabled={false}
                            value={nextHeadingText}
                            setValue={setNextHeadingText}
                        />
                    </Col>
                )}
            </CellPopover>
        </ThNoPadding>
    )
}

export function CellEditor({
    itemId,
    nextCellData,
    setNextCellData,
    locale,
    harveyEnabled,
    symbolEnabled,
    tableOperations,
}: EditCellComponentProps<TableCell> & {
    itemId: string
    locale: Locale
    harveyEnabled: boolean
    symbolEnabled: boolean
    tableOperations: TableOperations<BaseTableDTO>
}) {
    const cellTypes = useMemo(() => {
        const val = new Map<TableCellType, string>([
            [TableCellType.TEXT, 'Text'],
            [TableCellType.AVAILABILITY, 'Shaded'],
        ])

        if (harveyEnabled) {
            val.set(TableCellType.ICON, 'Harvey Ball')
        }

        //Symbol cell type available if symbols enabled and at least one legend defined
        if (
            symbolEnabled &&
            (tableOperations.hasValidSymbolLegends(itemId, locale) || !isDefaultLocale(locale))
        ) {
            val.set(TableCellType.SYMBOL, 'Symbol')
        }

        return val
    }, [harveyEnabled, itemId, locale, symbolEnabled, tableOperations])

    return (
        <>
            <ItemEditorSelectInput
                dataTestId={'cell-type-select'}
                itemId={itemId}
                labelText={'Cell Type'}
                disabled={!isDefaultLocale(locale)}
                value={nextCellData.type}
                inputId={'cell-type'}
                setValue={(value: TableCellType) =>
                    setNextCellData((previous: TableCell) => {
                        let nextData = ''

                        if (value === TableCellType.AVAILABILITY) {
                            nextData = 'false'
                        } else if (value === TableCellType.ICON) {
                            nextData = TableIconType.HARVEY_BALL_EMPTY
                        } else if (value === TableCellType.SYMBOL) {
                            nextData = tableOperations.getFirstValidSymbolLegend(itemId, locale)
                        }

                        return {
                            ...previous,
                            type: value,
                            dataI18N: {
                                ...previous.dataI18N,
                                [Locale.en_US]: nextData,
                            },
                        }
                    })
                }
                valueToDisplayNames={cellTypes}
            />
            {nextCellData.type === TableCellType.TEXT && (
                <ItemEditorTextArea
                    dataTestId={'cell-text-input'}
                    labelText={'Cell Text'}
                    inputId='cell-text'
                    disabled={false}
                    locale={locale}
                    itemId={itemId}
                    value={nextCellData.dataI18N[locale] ?? ''}
                    setValue={(value) =>
                        setNextCellData((prevState) => ({
                            ...prevState,
                            dataI18N: {
                                ...prevState.dataI18N,
                                [locale]: value,
                            },
                        }))
                    }
                />
            )}
            {nextCellData.type === TableCellType.ICON && (
                <ItemEditorSelectInput
                    dataTestId={'cell-icon-select'}
                    itemId={itemId}
                    labelText={'Icon'}
                    disabled={false}
                    value={nextCellData.dataI18N[locale] as TableIconType}
                    inputId={'cell-icon'}
                    setValue={(value: TableIconType) =>
                        setNextCellData((previous: TableCell) => {
                            return {
                                ...previous,
                                dataI18N: {
                                    ...previous.dataI18N,
                                    [locale]: value,
                                },
                            }
                        })
                    }
                    valueToDisplayNames={
                        new Map<TableIconType, string>([
                            [TableIconType.HARVEY_BALL_EMPTY, 'Harvey Ball Empty'],
                            [TableIconType.HARVEY_BALL_HALF_FULL, 'Harvey Ball Half Full'],
                            [TableIconType.HARVEY_BALL_FULL, 'Harvey Ball Full'],
                        ])
                    }
                />
            )}
            {/*The select does not work with booleans, hence strings*/}
            {nextCellData.type === TableCellType.AVAILABILITY && (
                <ItemEditorSelectInput
                    dataTestId={'cell-availability-select'}
                    itemId={itemId}
                    labelText={'Shaded'}
                    disabled={!isDefaultLocale(locale)}
                    value={nextCellData.dataI18N[locale] ?? 'false'}
                    inputId={'cell-shaded'}
                    setValue={(value: string) => {
                        setNextCellData((previous: TableCell) => {
                            return {
                                ...previous,
                                dataI18N: {
                                    ...previous.dataI18N,
                                    [locale]: value,
                                },
                            }
                        })
                    }}
                    valueToDisplayNames={
                        new Map<string, string>([
                            ['false', 'Grey'],
                            ['true', 'White'],
                        ])
                    }
                />
            )}
            {nextCellData.type === TableCellType.SYMBOL && (
                <View dataTestId={'symbol-cell-section'} padding={{ top: 'S300' }}>
                    {nextCellData.dataI18N[locale]
                        ?.split(',')
                        .map((symbol: string, index: number) => (
                            <Row key={index} gridGap='S200'>
                                <Col width='75%'>
                                    <ItemEditorSelectInput
                                        dataTestId={`cell-symbol-select-${index}`}
                                        itemId={itemId}
                                        labelText={`Symbol ${index + 1}`}
                                        disabled={false}
                                        value={TableSymbolType[symbol]}
                                        inputId={`Symbol-${index + 1}`}
                                        setValue={(value: TableSymbolType) => {
                                            setNextCellData((previous: TableCell) => {
                                                const newValue = replaceCellSymbol(
                                                    previous.dataI18N[locale] || '',
                                                    index,
                                                    value
                                                )
                                                return {
                                                    ...previous,
                                                    dataI18N: {
                                                        ...previous.dataI18N,
                                                        [locale]: newValue,
                                                    },
                                                }
                                            })
                                        }}
                                        valueToDisplayNames={tableOperations.getValueMapForSymbols(
                                            itemId,
                                            locale
                                        )}
                                        isSymbolLegend={true}
                                    />
                                </Col>
                                <Col margin={{ top: 'S400' }}>
                                    <Button
                                        dataTestId={`delete-cell-symbol-${index}`}
                                        id={`delete-cell-symbol-${index}`}
                                        aria-label={`delete symbol ${index + 1}`}
                                        icon={<IconBin title='Delete item' />}
                                        isDestructive
                                        variant={ButtonVariant.Tertiary}
                                        disabled={
                                            !nextCellData.dataI18N[locale] ||
                                            (nextCellData.dataI18N[locale]?.split(',').length ??
                                                0) < 2
                                        }
                                        onClick={() => {
                                            setNextCellData((previous: TableCell) => {
                                                const newValue = getCellDataAfterDelete(
                                                    previous.dataI18N[locale] || '',
                                                    index
                                                )
                                                return {
                                                    ...previous,
                                                    dataI18N: {
                                                        ...previous.dataI18N,
                                                        [locale]: newValue,
                                                    },
                                                }
                                            })
                                            focusOnId(`Symbol-${index}-${itemId}-toggle-button`)
                                        }}
                                    />
                                </Col>
                            </Row>
                        ))}
                    <Button
                        icon={<IconPlus aria-hidden />}
                        id={'add-symbol-button'}
                        dataTestId={'add-symbol-button'}
                        variant={ButtonVariant.Tertiary}
                        disabled={!tableOperations.hasValidSymbolLegends(itemId, locale)}
                        onClick={() => {
                            setNextCellData((previous: TableCell) => {
                                const newValue = getCellDataAfterAddition(
                                    previous.dataI18N[locale] || '',
                                    tableOperations.getFirstValidSymbolLegend(itemId, locale)
                                )
                                return {
                                    ...previous,
                                    dataI18N: {
                                        ...previous.dataI18N,
                                        [locale]: newValue,
                                    },
                                }
                            })
                            const nextNumberOfSymbols =
                                (nextCellData.dataI18N[locale] ?? '').split(',').length + 1
                            focusOnId(`Symbol-${nextNumberOfSymbols}-${itemId}-toggle-button`)
                        }}
                    >
                        Add Symbol
                    </Button>
                </View>
            )}
        </>
    )
}
