import React, { useCallback, useEffect, useState } from 'react'
import { startCase } from 'lodash'

import { Button } from '@amzn/stencil-react-components/button'
import { ExpanderContent } from '@amzn/stencil-react-components/expander'
import { TriggerButton } from '@amzn/stencil-react-components/helpers'
import {
    IconAlertCircleFill,
    IconCheckCircleFill,
    IconChevronDown,
    IconChevronUp,
} from '@amzn/stencil-react-components/icons'
import { Col, Flex, Hr, Row, Spacer, View } from '@amzn/stencil-react-components/layout'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'
import { useBreakpoints } from '@amzn/stencil-react-components/responsive'
import { ScreenReaderOnly } from '@amzn/stencil-react-components/screen-reader-only'
import { Text } from '@amzn/stencil-react-components/text'

import { ItemOptions } from 'src/components/ItemOptions'
import { useEntity, useItemEntity } from 'src/hooks/useEntity'
import { getItemTitle, ItemDTO, ItemType } from 'src/models/dto/items/ItemDTO'
import { LikertGroupDTO } from 'src/models/dto/items/LikertItemDTO'
import { Locale } from 'src/models/dto/Locale'
import { ModuleLayout } from 'src/models/dto/ModuleDTO'
import { ValidationErrorEntity } from 'src/models/dto/TemplateValidationError'
import {
    focusOnId,
    itemFocusId,
    moveDownFocusId,
    moveUpFocusId,
} from 'src/pages/module-builder/focus'
import { AudioCheckItemEditor } from 'src/pages/module-builder/item-editors/AudioCheckItemEditor'
import { AudioEvaluationItemEditor } from 'src/pages/module-builder/item-editors/AudioEvaluationItemEditor'
import { ChatItemEditor } from 'src/pages/module-builder/item-editors/ChatItemEditor'
import { ContentItemEditor } from 'src/pages/module-builder/item-editors/ContentItemEditor'
import { EmailContentItemEditor } from 'src/pages/module-builder/item-editors/EmailContentItemEditor'
import { FreeTextItemEditor } from 'src/pages/module-builder/item-editors/FreeTextItemEditor'
import { InformationImagesItemEditor } from 'src/pages/module-builder/item-editors/InformationImagesItemEditor'
import { LikertGroupItemEditor } from 'src/pages/module-builder/item-editors/LikertGroupItemEditor'
import { MediaStimulusItemEditor } from 'src/pages/module-builder/item-editors/MediaStimulusItemEditor'
import { MultipleChoiceItemEditor } from 'src/pages/module-builder/item-editors/MultipleChoiceItemEditor'
import { RatingItemEditor } from 'src/pages/module-builder/item-editors/RatingItemEditor'
import { SingleSelectCheckboxItemEditor } from 'src/pages/module-builder/item-editors/SingleSelectCheckboxItemEditor'
import { StaticContentLayoutEditor } from 'src/pages/module-builder/item-editors/StaticContentLayouts/StaticContentLayoutEditor'
import { TableItemEditor } from 'src/pages/module-builder/item-editors/TableItemEditor'
import { VirtualMeetingItemEditor } from 'src/pages/module-builder/item-editors/VirtualMeetingItemEditor'
import { WritingExerciseItemEditor } from 'src/pages/module-builder/item-editors/WritingExerciseItemEditor'
import { WrittenResponseItemEditor } from 'src/pages/module-builder/item-editors/WrittenResponseItemEditor'
import { ErrorCheckService } from 'src/services/backend/ErrorCheckService'
import { ItemGroupHandler } from 'src/services/EntityServices/ActivityUpdateHandlers/ItemGroupHandler'
import { ItemEntityService } from 'src/services/EntityServices/ItemEntityService'
import {
    VALIDATION_ERROR_ENTITY_STORE_SELECTOR,
    ValidationErrorEntityService,
} from 'src/services/EntityServices/ValidationErrorEntityService'
import { RankingResponseTableItemEditor } from './RankingResponseTableItemEditor'

const ItemEditor = (props: ItemEditorProps) => {
    const itemType = ItemEntityService.get(props.itemId).itemType
    switch (itemType) {
        case ItemType.FreeText:
            return <FreeTextItemEditor {...props} />
        case ItemType.RankingResponseTable:
            return <RankingResponseTableItemEditor {...props} />
        case ItemType.LikertGroup:
            return <LikertGroupItemEditor {...props} />
        case ItemType.AudioEvaluation:
            return <AudioEvaluationItemEditor {...props} />
        case ItemType.AudioCheck:
            return <AudioCheckItemEditor {...props} />
        case ItemType.Rating:
            return <RatingItemEditor {...props} />
        case ItemType.MultipleChoice:
            return <MultipleChoiceItemEditor {...props} />
        case ItemType.StaticContentLayout:
            return <StaticContentLayoutEditor {...props} />
        case ItemType.Content:
            return <ContentItemEditor {...props} />
        case ItemType.SingleSelectCheckbox:
            return <SingleSelectCheckboxItemEditor {...props} />
        case ItemType.WrittenResponse:
            return <WrittenResponseItemEditor {...props} />
        case ItemType.MediaStimulus:
            return <MediaStimulusItemEditor {...props} />
        case ItemType.Table:
            return <TableItemEditor {...props} />
        case ItemType.EmailContent:
            return <EmailContentItemEditor {...props} />
        case ItemType.Chat:
            return <ChatItemEditor {...props} />
        case ItemType.VirtualMeeting:
            return <VirtualMeetingItemEditor {...props} />
        case ItemType.WritingExercise:
            return <WritingExerciseItemEditor {...props} />
        case ItemType.InformationImages:
            return <InformationImagesItemEditor {...props} />
    }

    return null
}

export interface ItemEditorContainerProps {
    itemEntityId: string
    workflowEntityId: string
    pageIndex: number
    itemIndex: number
    isEndOfPage: boolean
    locale: Locale
    containerDataTestId: string
    showValidationError?: boolean
    moduleLayout?: ModuleLayout
    editDisabled?: boolean
}

const ItemEditorContainer = React.memo((props: ItemEditorContainerProps) => {
    const { entity } = useItemEntity<ItemDTO>({
        entityId: props.itemEntityId,
        workflowEntityId: props.workflowEntityId,
    })
    const {
        matches: { s: small },
    } = useBreakpoints()

    const [isChanged, setIsChanged] = useState(false)
    useEffect(() => setIsChanged(true), [entity])

    const [previewUnexpectedError, setPreviewUnexpectedError] = useState<string | undefined>(
        undefined
    )

    if (!ValidationErrorEntityService.has(props.itemEntityId)) {
        ValidationErrorEntityService.create(props.itemEntityId)
    }

    const { entity: validationErrorEntity } = useEntity<ValidationErrorEntity>({
        entityId: props.itemEntityId,
        selector: VALIDATION_ERROR_ENTITY_STORE_SELECTOR,
    })

    let numOfError = 0
    let invalid = false
    if (entity.itemType === ItemType.LikertGroup) {
        numOfError = (entity as LikertGroupDTO).stimulus
            .map((s) => ErrorCheckService.getErrorCount(s.stimulusV2DTO.id))
            .reduce((partialSum, a) => partialSum + a, 0)

        numOfError += ErrorCheckService.getErrorCount(validationErrorEntity.id)
        invalid = numOfError > 0
    } else {
        numOfError = ErrorCheckService.getErrorCount(validationErrorEntity.id)
        invalid = numOfError > 0
    }

    const [isExpanded, setIsExpanded] = useState(true)
    const [showError, setShowError] = useState(props.showValidationError)

    useEffect(() => {
        setShowError(props.showValidationError ?? false)
    }, [props.showValidationError])

    const itemDesc = `${startCase(entity.itemType)} item, page ${props.pageIndex + 1}, item ${
        props.itemIndex + 1
    }`
    const triggerButtonName = `${isExpanded ? 'Collapse' : 'Expand'} ${itemDesc}`

    const currentItemFocusId = itemFocusId(props.pageIndex + 1, props.itemIndex + 1)
    const prevItemFocusId = itemFocusId(props.pageIndex + 1, props.itemIndex)
    const nextItemFocusId = itemFocusId(props.pageIndex + 1, props.itemIndex + 2)

    const onErrorCheck = useCallback(() => {
        setIsChanged(false)
        setShowError(true)
    }, [])

    const moveUpButton = (
        <Button
            dataTestId={`move-item-up-page-${props.pageIndex}-item-${props.itemIndex}`}
            icon={
                <IconChevronUp
                    title={`Move item up to position ${props.itemIndex} in page ${
                        props.pageIndex + 1
                    }`}
                />
            }
            id={moveUpFocusId(currentItemFocusId)}
            aria-disabled={props.itemIndex === 0 || props.editDisabled}
            onClick={() => {
                ItemGroupHandler.moveUp(props.workflowEntityId, props.itemEntityId)
                focusOnId(moveUpFocusId(prevItemFocusId), prevItemFocusId)
            }}
        />
    )

    const moveDownButton = (
        <Button
            dataTestId={`move-item-down-page-${props.pageIndex}-item-${props.itemIndex}`}
            icon={
                <IconChevronDown
                    title={`Move item down to position ${props.itemIndex + 2} in page ${
                        props.pageIndex + 1
                    }`}
                />
            }
            id={moveDownFocusId(currentItemFocusId)}
            aria-disabled={props.isEndOfPage || props.editDisabled}
            onClick={() => {
                ItemGroupHandler.moveDown(props.workflowEntityId, props.itemEntityId)
                focusOnId(moveDownFocusId(nextItemFocusId), nextItemFocusId)
            }}
        />
    )

    return (
        <Col backgroundColor='white' padding='S300' dataTestId={props.containerDataTestId}>
            {previewUnexpectedError && (
                <MessageBanner
                    type={MessageBannerType.Error}
                    dismissButtonAltText={'Hide errors'}
                    dataTestId={'unexpected-error-banner'}
                    isDismissible={true}
                    onDismissed={() => setPreviewUnexpectedError(undefined)}
                >
                    {previewUnexpectedError}
                </MessageBanner>
            )}
            <Spacer height='S200' />
            <Flex
                flexDirection={small ? 'column' : 'row'}
                gridGap={small ? 'S300' : 'S200'}
                justifyContent='space-between'
                width='100%'
            >
                <Row alignItems='center' gridGap='S200'>
                    <TriggerButton
                        isExpanded={isExpanded}
                        onClick={() => setIsExpanded(!isExpanded)}
                        iconAltText={triggerButtonName}
                        aria-label={triggerButtonName}
                        id={itemFocusId(props.pageIndex + 1, props.itemIndex + 1)}
                    />
                    <Text fontSize='T400' color={invalid && showError ? 'red70' : 'neutral90'}>
                        <h4>
                            <strong>{getItemTitle(entity.itemType)} Item</strong>
                        </h4>
                    </Text>
                </Row>
                {showError && (
                    <Row gridGap='S200' margin={small ? 'S200' : '0'}>
                        {invalid && showError && (
                            <Row
                                color={'red70'}
                                gridGap={'S100'}
                                alignItems={'center'}
                                dataTestId={'item-error-banner'}
                            >
                                <IconAlertCircleFill aria-hidden={true} />
                                {ErrorCheckService.getErrorCount(validationErrorEntity.id)} issues
                                <ScreenReaderOnly> for {itemDesc}</ScreenReaderOnly>
                            </Row>
                        )}
                        {!invalid && showError && !isChanged && (
                            <Row color={'green70'} gridGap={'S100'} alignItems={'center'}>
                                <IconCheckCircleFill aria-hidden={true} />
                                No issues
                                <ScreenReaderOnly> for {itemDesc}</ScreenReaderOnly>
                            </Row>
                        )}
                    </Row>
                )}
                <Row justifyContent='flex-end' gridGap='S200' width={small ? '100%' : undefined}>
                    <div>{moveUpButton}</div>
                    <div>{moveDownButton}</div>
                    <View flex='1 1'>
                        <ItemOptions
                            workflowEntityId={props.workflowEntityId}
                            itemId={props.itemEntityId}
                            itemIndex={props.itemIndex}
                            pageIndex={props.pageIndex}
                            onErrorCheck={onErrorCheck}
                            onPreviewUnexpectedError={() => {
                                setPreviewUnexpectedError(
                                    'An error has occurred while previewing the item. Please try again later.'
                                )
                            }}
                            editDisabled={props.editDisabled}
                        />
                    </View>
                </Row>
            </Flex>
            <View>
                <ExpanderContent isExpanded={isExpanded}>
                    <Spacer height={'S400'} />
                    <Hr size='wide' color='neutral20' />
                    <Spacer height={'S400'} />
                    <ItemEditor
                        itemId={entity.id}
                        locale={props.locale}
                        itemIndex={props.itemIndex}
                        pageIndex={props.pageIndex}
                        showValidationError={showError}
                        workflowEntityId={props.workflowEntityId}
                        moduleLayout={props.moduleLayout}
                        editDisabled={props.editDisabled}
                    />
                </ExpanderContent>
            </View>
        </Col>
    )
})

ItemEditorContainer.displayName = 'ItemEditorContainer'

export default ItemEditorContainer

export interface ItemEditorProps {
    itemId: string
    locale: Locale
    pageIndex: number
    itemIndex: number
    showValidationError?: boolean
    workflowEntityId: string
    moduleLayout?: ModuleLayout
    editDisabled?: boolean
}
