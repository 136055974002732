import React, { useCallback, useMemo, useState } from 'react'

import { Button, ButtonSize, ButtonVariant } from '@amzn/stencil-react-components/button'
import { Input, InputWrapper } from '@amzn/stencil-react-components/form'
import { IconCopy } from '@amzn/stencil-react-components/icons'
import { Col } from '@amzn/stencil-react-components/layout'
import { ModalContent } from '@amzn/stencil-react-components/modal'
import { useBreakpoints } from '@amzn/stencil-react-components/responsive'

import { RawMediaView } from 'src/components/media/RawMediaView'
import { useMediaSrc } from 'src/components/MediaContentsEditor'
import { MediaCardProps } from 'src/components/MediaContentsEditor/MediaCard'
import { ResponsiveRow } from 'src/components/ResponsiveRow'
import { isDefaultLocale } from 'src/contexts/ModuleLocaleContext'
import { MediaContent } from 'src/models/dto/items/MediaDTO'

interface MediaModalProps extends MediaCardProps {
    close: () => void
}

export function MediaModal({ close, mediaContent, setMediaContent, locale }: MediaModalProps) {
    const tooltipText =
        "Alt text is used by screen readers and browsers to communicate image content which can't be seen."
    const minInputWidth = 400
    const {
        matches: { s: isSmall },
    } = useBreakpoints()

    const [newAltText, setNewAltText] = useState(mediaContent.altTextI18N[locale] ?? '')
    const [newRelativePath, setNewRelativePath] = useState(
        mediaContent.relativePathI18N[locale] ?? ''
    )
    const save = useCallback(() => {
        setMediaContent((mc: MediaContent) => ({
            ...mc,
            altTextI18N: {
                ...mediaContent.altTextI18N,
                [locale]: newAltText,
            },
            relativePathI18N: {
                ...mediaContent.relativePathI18N,
                [locale]: newRelativePath,
            },
        }))
        close()
    }, [
        close,
        locale,
        mediaContent.altTextI18N,
        mediaContent.relativePathI18N,
        newAltText,
        newRelativePath,
        setMediaContent,
    ])

    const { name, mediaSrc } = useMediaSrc(newRelativePath)

    const buttons = useMemo(
        () => [
            <Button
                key={0}
                variant={ButtonVariant.Secondary}
                onClick={close}
                dataTestId={'cancel-media-modal'}
            >
                Cancel
            </Button>,
            <Button
                key={1}
                dataTestId={'save-media-modal'}
                variant={ButtonVariant.Primary}
                title='Save and close'
                aria-label={'Save and close'}
                onClick={save}
            >
                Save
            </Button>,
        ],
        [close, save]
    )

    const altTextError =
        newAltText?.trim().length === 0 ? 'All images must have alt text' : undefined
    return (
        <ModalContent maxWidth='90vw' titleText={name} buttons={buttons}>
            <ResponsiveRow width='100%' gridGap='S300'>
                <Col maxWidth={120}>
                    <RawMediaView
                        url={mediaSrc}
                        altText='Media preview'
                        mimeType={mediaContent.mimeType}
                        controls
                    />
                </Col>
                <Col gridGap='S200' width='100%' minWidth={!isSmall ? minInputWidth : undefined}>
                    <InputWrapper
                        id='media-files-modal-path'
                        dataTestId='media-files-modal-path'
                        labelText='Relative path'
                    >
                        {(props) => (
                            <Input
                                {...props}
                                dataTestId='media-files-modal-path-input'
                                insetElementTrailing={{
                                    inset: 0,
                                    element: (
                                        <Button
                                            variant={ButtonVariant.Tertiary}
                                            size={ButtonSize.Small}
                                            icon={<IconCopy title='Copy image path' />}
                                            onClick={() => navigator.clipboard?.writeText(name)}
                                        />
                                    ),
                                }}
                                value={newRelativePath}
                                onChange={(e) => setNewRelativePath(e.target.value)}
                                disabled={isDefaultLocale(locale)}
                            />
                        )}
                    </InputWrapper>
                    <InputWrapper
                        id='media-files-modal-alt-text'
                        dataTestId='media-files-modal-alt-text'
                        labelText='Alt text'
                        tooltipText={tooltipText}
                        error={!!altTextError}
                        footer={altTextError}
                    >
                        {(props) => (
                            <Input
                                {...props}
                                dataTestId='media-files-modal-alt-text-input'
                                value={newAltText}
                                onChange={(e) => setNewAltText(e.target.value)}
                                placeholder='e.g. Cats holding paws and dancing in a circle'
                            />
                        )}
                    </InputWrapper>
                </Col>
            </ResponsiveRow>
        </ModalContent>
    )
}
