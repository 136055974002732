import React from 'react'

import { Button } from '@amzn/stencil-react-components/button'
import { Modal, ModalContent } from '@amzn/stencil-react-components/modal'

import { MUPPItemBankManager } from 'src/pages/module-builder/mupp-exam-editor/MUPPItemBankManager'

export interface SelectItemBankModalProps {
    isOpen: boolean
    close: (value: boolean) => void
    setItemBank: (version: string, id: string, locales: string[]) => void
}

export const SelectItemBankModal = (props: SelectItemBankModalProps) => {
    return (
        <Modal
            isOpen={props.isOpen}
            close={() => props.close(false)}
            shouldCloseOnClickOutside={false}
        >
            <ModalContent
                maxWidth='100vw'
                titleText={'Available Item Banks'}
                buttons={[
                    <Button
                        key='close-button'
                        dataTestId={'close-button'}
                        onClick={() => props.close(false)}
                    >
                        close
                    </Button>,
                ]}
            >
                <MUPPItemBankManager setItemBank={props.setItemBank} />
            </ModalContent>
        </Modal>
    )
}
