import React from 'react'

import { Button, ButtonVariant } from '@amzn/stencil-react-components/button'
import { IconPlus } from '@amzn/stencil-react-components/icons'
import { Col, Row } from '@amzn/stencil-react-components/layout'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'
import { useBreakpoints } from '@amzn/stencil-react-components/responsive'
import { ScreenReaderOnly } from '@amzn/stencil-react-components/screen-reader-only'

import { IconWithTooltip } from 'src/components/IconWithTooltip'
import { isDefaultLocale } from 'src/contexts/ModuleLocaleContext'
import { useEntity, useItemEntity } from 'src/hooks/useEntity'
import {
    MAX_RATING_SCALE,
    MIN_RATING_SCALE,
    RatingItemDTO,
    RatingScaleDTO,
} from 'src/models/dto/items/RatingItemDTO'
import { GENERIC_ERROR_KEY, ValidationErrorEntity } from 'src/models/dto/TemplateValidationError'
import { ItemEditorProps } from 'src/pages/module-builder/item-editors/ItemEditorContainer'
import {
    ItemEditorCheckboxInput,
    ItemEditorTextInput,
} from 'src/pages/module-builder/item-editors/ItemEditorInputs'
import {
    CellType,
    ItemEditorTable,
    ItemEditorTableColumns,
} from 'src/pages/module-builder/item-editors/ItemEditorTable'
import { RatingHandler } from 'src/services/EntityServices/ItemUpdateHandlers/RatingHandler'
import {
    VALIDATION_ERROR_ENTITY_STORE_SELECTOR,
    ValidationErrorEntityService,
} from 'src/services/EntityServices/ValidationErrorEntityService'

export const RatingItemEditor = ({
    itemId,
    locale,
    workflowEntityId,
    showValidationError,
    editDisabled,
}: ItemEditorProps) => {
    const { entity: itemDTO } = useItemEntity<RatingItemDTO>({
        entityId: itemId,
        workflowEntityId,
    })

    if (!ValidationErrorEntityService.has(itemId)) {
        ValidationErrorEntityService.create(itemId)
    }

    const { entity: validationErrorEntity } = useEntity<ValidationErrorEntity>({
        entityId: itemId,
        selector: VALIDATION_ERROR_ENTITY_STORE_SELECTOR,
    })

    const { matches } = useBreakpoints()

    const scaleTableColumns = [
        {
            header: 'Define rating scale',
            headerLabel: 'Define rating scale',
            width: '80%',
            cellType: CellType.TextInput,
            placeHolder: 'Rating scale',
            disabled: editDisabled,
            accessor: (data) => data.nameI18N[locale],
            setValue: (nextValue: string, index: number) =>
                RatingHandler.updateScaleName(itemDTO.id, index, locale, nextValue),
        },
        {
            header: 'Score',
            headerLabel: 'Score',
            width: '15%',
            disabled: !isDefaultLocale(locale) || editDisabled,
            cellType: CellType.NumberInput,
            placeHolder: '0.0',
            accessor: (data) => data.score,
            setValue: (nextValue: number, index: number) =>
                RatingHandler.updateScaleScore(itemDTO.id, index, nextValue),
        },
        {
            header: <ScreenReaderOnly>Delete action</ScreenReaderOnly>,
            headerLabel: 'Delete',
            width: '5%',
            disabled:
                !isDefaultLocale(locale) ||
                itemDTO.scales?.length <= MIN_RATING_SCALE ||
                editDisabled,
            cellType: CellType.DeleteButton,
            setValue: (nextValue: never, index: number) =>
                RatingHandler.deleteScale(itemDTO.id, index),
        },
    ] as ItemEditorTableColumns<RatingScaleDTO>

    return (
        <>
            {validationErrorEntity.validationErrors[GENERIC_ERROR_KEY] && showValidationError && (
                <MessageBanner type={MessageBannerType.Error}>
                    <ul>
                        {validationErrorEntity.validationErrors[GENERIC_ERROR_KEY].map(
                            (message, index) => (
                                <li key={index}>{message}</li>
                            )
                        )}
                    </ul>
                </MessageBanner>
            )}
            <Col gridGap={'S300'}>
                <Row width={'100%'} gridGap={'S300'} flexWrap={'wrap'}>
                    <Col width={matches.s ? '100%' : '410px'}>
                        <ItemEditorTextInput
                            itemId={itemDTO.id}
                            dataTestId='unique-item-id'
                            inputId='unique-item-id'
                            labelText='Unique Item ID'
                            disabled={!isDefaultLocale(locale) || editDisabled}
                            placeholder='Some human readable label'
                            value={itemDTO.label}
                            setValue={(nextValue: string) =>
                                RatingHandler.updateLabel(itemDTO.id, nextValue)
                            }
                            validationErrorMessage={(
                                validationErrorEntity.validationErrors.label ?? []
                            ).join(', ')}
                            showError={showValidationError}
                        />
                    </Col>
                    <Col padding={matches.s ? {} : { top: '35px' }}>
                        <ItemEditorCheckboxInput
                            itemId={itemDTO.id}
                            dataTestId={'optional-checkbox'}
                            inputId={'optional'}
                            disabled={!isDefaultLocale(locale) || editDisabled}
                            labelText={'Optional'}
                            value={itemDTO.optional}
                            setValue={(nextValue: boolean) =>
                                RatingHandler.updateOptional(itemDTO.id, nextValue)
                            }
                        />
                    </Col>
                    <Col padding={{ top: `${matches.s ? '8px' : '29px'}` }}>
                        <Row alignItems={'center'}>
                            <ItemEditorCheckboxInput
                                itemId={itemDTO.id}
                                dataTestId={'preserve-order-checkbox'}
                                disabled={!isDefaultLocale(locale) || editDisabled}
                                inputId={'preserve-order'}
                                labelText={'Preserve order'}
                                value={itemDTO.preserveOrder}
                                setValue={(nextValue: boolean) =>
                                    RatingHandler.updatePreserveOrder(itemDTO.id, nextValue)
                                }
                            />
                            <IconWithTooltip tooltipText='When randomizing page, ensure this items position is never changed' />
                        </Row>
                    </Col>
                </Row>
                <Col width={'100%'}>
                    <ItemEditorTextInput
                        itemId={itemDTO.id}
                        dataTestId={'rating-question-input'}
                        inputId='rating-questions'
                        labelText='Stimulus'
                        placeholder='Enter a stimulus question'
                        disabled={editDisabled}
                        value={itemDTO.questionI18N[locale] || ''}
                        setValue={(nextValue: string) =>
                            RatingHandler.updateQuestion(itemDTO.id, locale, nextValue)
                        }
                        validationErrorMessage={(
                            validationErrorEntity.validationErrors.questionI18N ?? []
                        ).join(', ')}
                        showError={showValidationError}
                    />
                </Col>
                <Col width={'100%'}>
                    <ItemEditorTable
                        itemId={itemDTO.id}
                        columns={scaleTableColumns}
                        data={itemDTO.scales}
                        dataTestId='rating-scale-table'
                        validationErrorMessage={(
                            validationErrorEntity.validationErrors.scales ?? []
                        ).join(', ')}
                        showError={showValidationError}
                    />
                    <Row justifyContent={'flex-end'}>
                        <Button
                            dataTestId={'rating-scale-table-add-row-button'}
                            icon={<IconPlus aria-hidden={true} />}
                            variant={ButtonVariant.Tertiary}
                            disabled={!isDefaultLocale(locale)}
                            aria-disabled={
                                itemDTO.scales.length >= MAX_RATING_SCALE || editDisabled
                            } //Using aria-disabled make it to be still focusable after disable it for the accessibility.
                            onClick={() => RatingHandler.addScale(itemDTO.id)}
                        >
                            Add new row
                        </Button>
                    </Row>
                </Col>
            </Col>
        </>
    )
}
