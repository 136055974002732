import React, { useCallback, useEffect, useReducer, useState } from 'react'

import { Button, ButtonSize } from '@amzn/stencil-react-components/button'
import { Col, View } from '@amzn/stencil-react-components/layout'
import { ProgressBar, ProgressBarStatus } from '@amzn/stencil-react-components/progress-bar'
import { Status, StatusIndicator } from '@amzn/stencil-react-components/status-indicator'

import {
    ModuleDeploymentStatus,
    ModuleDeploymentTargetStage,
} from 'src/models/dto/ModuleDeployment'
import { ModuleGroupService } from 'src/services/module-groups/ModuleGroupService'

export interface ModuleGroupDeploymentProgressProps {
    moduleGroupVersionId: string
    isInProgress: boolean
    targetStage: ModuleDeploymentTargetStage
    author: string
    comments?: string
    setDeployedStatus?: (value: boolean) => void
}

export enum ModuleGroupDeploymentProgressStatus {
    READY = 'READY',
    IN_PROGRESS = 'IN_PROGRESS',
    SUCCEED = 'SUCCEED',
    FAILED = 'FAILED',
}

interface ModuleGroupDeploymentProgressStatusProps {
    desc: string
    status: Status
    progressBarStatus: ProgressBarStatus
    /** between 0 and 1 */
    progressStep: number
}

export const MODULE_GROUP_STATUS_STRING_MAP: Record<
    ModuleGroupDeploymentProgressStatus,
    ModuleGroupDeploymentProgressStatusProps
> = {
    [ModuleGroupDeploymentProgressStatus.READY]: {
        desc: 'Checking the deployment status of the module',
        status: Status.Waiting,
        progressBarStatus: ProgressBarStatus.Loading,
        progressStep: 0,
    },
    [ModuleGroupDeploymentProgressStatus.IN_PROGRESS]: {
        desc: 'Module deployment is in progress',
        status: Status.Waiting,
        progressBarStatus: ProgressBarStatus.Loading,
        progressStep: 0.5,
    },
    [ModuleGroupDeploymentProgressStatus.SUCCEED]: {
        desc: 'Succeed to deploy',
        status: Status.Positive,
        progressBarStatus: ProgressBarStatus.Positive,
        progressStep: 1,
    },
    [ModuleGroupDeploymentProgressStatus.FAILED]: {
        desc: 'Failed to deploy',
        status: Status.Negative,
        progressBarStatus: ProgressBarStatus.Negative,
        progressStep: 1,
    },
}

export function ModuleGroupDeploymentProgress(props: ModuleGroupDeploymentProgressProps) {
    const [isRetry, setIsRetry] = useState<boolean>(false)
    const [reloadDeployModuleCount, reloadDeployModule] = useReducer((x: number) => x + 1, 0)
    const [deploymentProgressStatus, setDeploymentProgressStatus] =
        useState<ModuleGroupDeploymentProgressStatus>(ModuleGroupDeploymentProgressStatus.READY)
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)

    const handleError = useCallback((error: Error) => {
        setDeploymentProgressStatus(ModuleGroupDeploymentProgressStatus.FAILED)
        setErrorMessage(error?.message)
    }, [])

    useEffect(() => {
        if (!props.isInProgress) {
            return
        }

        setDeploymentProgressStatus(ModuleGroupDeploymentProgressStatus.IN_PROGRESS)

        ModuleGroupService.deployModuleGroupVersion(props.moduleGroupVersionId, {
            versionId: props.moduleGroupVersionId,
            stage: props.targetStage,
            comment: props.comments,
        })
            .then((response) => {
                if (response.deploymentStatus === ModuleDeploymentStatus.SUCCESSFULLY_PUBLISHED) {
                    setDeploymentProgressStatus(ModuleGroupDeploymentProgressStatus.SUCCEED)
                    if (props.setDeployedStatus) {
                        props.setDeployedStatus(true)
                    }
                } else {
                    setDeploymentProgressStatus(ModuleGroupDeploymentProgressStatus.FAILED)
                }
                setIsRetry(false)
            })
            .catch((error: Error) => {
                handleError(error)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        handleError,
        isRetry,
        props.setDeployedStatus,
        props.comments,
        props.isInProgress,
        props.moduleGroupVersionId,
        props.targetStage,
        reloadDeployModuleCount,
    ])

    const retry = () => {
        setIsRetry(true)
        setErrorMessage(undefined)
        setDeploymentProgressStatus(ModuleGroupDeploymentProgressStatus.READY)
        reloadDeployModule()
    }

    if (!props.isInProgress) {
        return null
    }

    return (
        <View backgroundColor='neutral0'>
            <Col gridGap='S100' backgroundColor='neutral0'>
                <StatusIndicator
                    id='push-module-status-indicator'
                    messageText={
                        errorMessage ??
                        MODULE_GROUP_STATUS_STRING_MAP[deploymentProgressStatus].desc
                    }
                    status={MODULE_GROUP_STATUS_STRING_MAP[deploymentProgressStatus].status}
                />

                <ProgressBar
                    progress={MODULE_GROUP_STATUS_STRING_MAP[deploymentProgressStatus].progressStep}
                    status={
                        MODULE_GROUP_STATUS_STRING_MAP[deploymentProgressStatus].progressBarStatus
                    }
                    data-test-id='push-module-progress-bar'
                />

                {deploymentProgressStatus === ModuleGroupDeploymentProgressStatus.FAILED && (
                    /* @ts-ignore: Expression produces a union type that is too complex to represent error */
                    <Button size={ButtonSize.Small} onClick={retry}>
                        {' '}
                        Retry
                    </Button>
                )}
            </Col>
        </View>
    )
}
