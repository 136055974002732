import React from 'react'

import { Button, ButtonVariant } from '@amzn/stencil-react-components/button'
import { Modal, ModalContent } from '@amzn/stencil-react-components/modal'

export interface WarningModalProps {
    open: boolean
    title: string
    children: React.ReactNode
    buttons: {
        isDestructive?: boolean
        variant: ButtonVariant
        label: string
        dataTestId?: string
        value?: boolean | number | string
    }[]
    onClose: (value?: boolean | number | string) => void
    modalTestId?: string
}
export const WarningModal = (props: WarningModalProps) => {
    return (
        <Modal
            aria-labelledby='stencil-modal-title'
            dataTestId={props.modalTestId}
            isOpen={props.open}
            close={props.onClose}
            key={props.modalTestId}
        >
            <ModalContent
                titleText={props.title}
                buttons={props.buttons.map((b) => {
                    return (
                        <Button
                            key={b.label}
                            dataTestId={b.dataTestId}
                            onClick={() => props.onClose(b.value)}
                            isDestructive={b.isDestructive}
                            variant={b.variant}
                        >
                            {b.label}
                        </Button>
                    )
                })}
            >
                {props.children}
            </ModalContent>
        </Modal>
    )
}
