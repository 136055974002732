import React, { useState } from 'react'

import { Button, ButtonVariant } from '@amzn/stencil-react-components/button'
import { Select } from '@amzn/stencil-react-components/form'
import { IconArrowLeft } from '@amzn/stencil-react-components/icons'
import { Col, Container, Spacer, View } from '@amzn/stencil-react-components/layout'
import { Link, LinkProps } from '@amzn/stencil-react-components/link'
import { Pagination } from '@amzn/stencil-react-components/pagination'
import { Table, TableColumn } from '@amzn/stencil-react-components/table'
import { H2, Text } from '@amzn/stencil-react-components/text'
import { withTooltip } from '@amzn/stencil-react-components/tooltip'

import { LAYOUT_DIRECTION, useLayoutDirection } from 'src/components/ModuleDisplayTable'

export interface RunResults {
    numberOfTests: number
    failedTests: number
    skippedTests: number
    passingTests: number
    report: string
    videoRecording: string
    workflowName: string
    runTimeStamp: string
    numberOfModules: number
}

// ignoring this for now because there is no known fix for this issue
// @ts-ignore: Expression produces a union type that is too complex to represent error
const LinkWithTooltip = withTooltip<Omit<LinkProps, 'ref'>>({
    useTriggerWrapper: false,
})(Link)

const ReportCell = ({ data }: { data: RunResults }) => {
    return (
        <Col>
            <Text textAlign='left' color='neutral70' fontSize='T100'>
                <LinkWithTooltip target='_blank' href={data.report} tooltipText='Open run report'>
                    View Report
                </LinkWithTooltip>{' '}
            </Text>
        </Col>
    )
}

const VideoCell = ({ data }: { data: RunResults }) => {
    return (
        <Col>
            <Text textAlign='left' color='neutral70' fontSize='T100'>
                <LinkWithTooltip
                    target='_blank'
                    href={data.videoRecording}
                    tooltipText='Download video recording'
                >
                    Download video
                </LinkWithTooltip>{' '}
            </Text>
        </Col>
    )
}

const DateCell = ({ data }: { data: RunResults }) => {
    const epochTime = parseInt(data.runTimeStamp)
    const fullDate = new Date(epochTime)
    const calendarDate = fullDate.toDateString().substring(4)
    const timeStamp = fullDate.toLocaleTimeString()

    return (
        <Col>
            <Text textAlign='left'> {calendarDate} </Text>
            <Text textAlign='left' color='neutral70' fontSize='T100'>
                at {timeStamp}{' '}
            </Text>
        </Col>
    )
}

const BasicNumberCell = ({ data }: { data: string }) => <Text textAlign='center'> {data} </Text>

const reportColumn = {
    header: 'Report',
    cellComponent: ReportCell,
}

const dateColumn = {
    header: 'Run At',
    cellComponent: DateCell,
}

const videoColumn = {
    header: 'Video Recording',
    cellComponent: VideoCell,
}

const defaultViewColumns = [
    dateColumn,
    {
        header: '# of Tests Run',
        accessor: 'numberOfTests',
        cellComponent: BasicNumberCell,
    } as TableColumn,
    {
        header: 'Passed Tests',
        accessor: 'passingTests',
        cellComponent: BasicNumberCell,
    } as TableColumn,
    {
        header: 'Failed Tests',
        accessor: 'failedTests',
        cellComponent: BasicNumberCell,
    } as TableColumn,
    {
        header: 'Skipped Tests',
        accessor: 'skippedTests',
        cellComponent: BasicNumberCell,
    } as TableColumn,
    {
        header: '# of Modules',
        accessor: 'numberOfModules',
        cellComponent: BasicNumberCell,
    } as TableColumn,
    reportColumn,
    videoColumn,
]

const mobileViewColumns = [dateColumn, reportColumn, videoColumn]

export const ViewResultsPage = ({
    results,
    goBack,
    fileName,
}: {
    results: RunResults[]
    goBack: () => void
    fileName: string
}) => {
    const [pageSize, setPageSize] = useState<number>(5)
    const numberOfPages = Math.ceil(results.length / pageSize)
    const [selectedPage, setSelectedPage] = useState(1)
    const renderOption = (option: string) => <Text>{option} Results per page</Text>
    const PAGE_OPTIONS = ['5', '10', '25', '50']
    const layout = useLayoutDirection()

    const columns = layout === LAYOUT_DIRECTION.MOBILE ? mobileViewColumns : defaultViewColumns

    return (
        <Container paddingTop={'S400'} paddingHorizontal={'S400'}>
            <Button
                variant={ButtonVariant.Tertiary}
                onClick={() => {
                    goBack()
                }}
                id={'return-to-selection-page'}
                dataTestId={'go-back-button'}
            >
                <IconArrowLeft aria-hidden={true} />
            </Button>

            <Col gridGap='S300' width={'100%'}>
                <H2>Test Results of {fileName}</H2>
                {results.length === 0 ? (
                    <Text>
                        This workflow definition file has not run on the automation pipeline yet, so
                        no results to display. Please wait for the pipeline to pick up and run the
                        latest file before returning here to check the results.
                    </Text>
                ) : (
                    <div>
                        <Text>
                            The below table shows a summary of the “{results[0].workflowName}“{' '}
                            workflow runs on the automation pipeline for the past 2 months. You can
                            open the report for each run for a detailed breakdown of the passing and
                            failing tests, and download the video recording for that run.
                        </Text>
                        <Spacer height={'S400'} />
                        <Table
                            columns={columns}
                            data={results.slice(
                                (selectedPage - 1) * pageSize,
                                selectedPage * pageSize
                            )}
                        />
                        <Spacer height={'S400'} />
                        <View
                            display='flex'
                            flexDirection={layout === LAYOUT_DIRECTION.MOBILE ? 'column' : 'row'}
                            justifyContent='space-between'
                            width='100%'
                        >
                            <Select
                                id='results-per-page-select'
                                labelId='results-per-page-select'
                                data-test-id='results-per-page-select'
                                width={layout === LAYOUT_DIRECTION.MOBILE ? '100%' : '30%'}
                                onChange={(option: string) => {
                                    setPageSize(Number(option))
                                    setSelectedPage(1)
                                }}
                                options={PAGE_OPTIONS}
                                renderOption={renderOption}
                                value={pageSize}
                            />
                            {numberOfPages > 1 && (
                                <Pagination
                                    numberOfPages={numberOfPages}
                                    onPageSelect={(page) => {
                                        setSelectedPage(page)
                                    }}
                                    selectedPage={selectedPage}
                                    dataTestId='results-table-pagination'
                                />
                            )}
                        </View>
                    </div>
                )}
            </Col>
        </Container>
    )
}
