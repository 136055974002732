import React, { useMemo } from 'react'
import _ from 'lodash'

import { Button, ButtonVariant } from '@amzn/stencil-react-components/button'
import { InputFooter } from '@amzn/stencil-react-components/form'
import { IconBin } from '@amzn/stencil-react-components/icons'
import { Col, Hr, Spacer } from '@amzn/stencil-react-components/layout'
import { RowData, Table, TableColumn } from '@amzn/stencil-react-components/table'
import { Text } from '@amzn/stencil-react-components/text'

import { Locale } from 'src/models/dto/Locale'
import {
    ItemEditorCheckboxInput,
    ItemEditorNumberInput,
    ItemEditorTextInput,
} from 'src/pages/module-builder/item-editors/ItemEditorInputs'

export enum CellType {
    TextInput = 'TextInput',
    NumberInput = 'NumberInput',
    Checkbox = 'Checkbox',
    DeleteButton = 'DeleteButton',
}

export enum CellMode {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    Table = 'Table',
    Card = 'Card',
}

export interface StimulusTableProps<T> {
    id: string
    type: string
    value: T
    locale: Locale
    labelText?: string
    disabled?: boolean
    errorMessage?: string
    placeholder?: string
    showError?: boolean
    setValue?: (param: T) => void
    mode?: CellMode
    cellType?: CellType
    maxCharacterLimit?: number
}

export const TextCell = ({ data, index }: RowData<StimulusTableProps<string>>) => {
    return (
        <Col width={'100%'}>
            <ItemEditorTextInput
                inputId={`input-text-${data.type}-id-${index}`}
                dataTestId={`input-text-${data.type}-id-${index}`}
                disabled={data.disabled ?? false}
                value={data.value || ''}
                placeholder={data?.placeholder || ''}
                itemId={data.id}
                setValue={data?.setValue || _.noop}
                validationErrorMessage={data?.errorMessage ?? ''}
                showError={data.showError}
                cellMode={data.mode ?? CellMode.Table}
                labelText={data.labelText}
                maxCharacterLimit={data.maxCharacterLimit}
            />
        </Col>
    )
}
export const ButtonCell = ({ data, index }: RowData<StimulusTableProps<string>>) => {
    const hide = !data.mode || data.mode === CellMode.Table
    return (
        <Button
            id={`input-button-${data.type}-id-${index}`}
            dataTestId={`table-editor-delete-row-${index}-button`}
            icon={data.mode === CellMode.Card ? undefined : <IconBin aria-hidden={true} />}
            variant={data.mode === CellMode.Card ? ButtonVariant.Secondary : ButtonVariant.Tertiary}
            onClick={data?.setValue || _.noop}
            isDestructive={data.cellType === CellType.DeleteButton}
            aria-disabled={data.disabled}
            title={hide ? data.labelText : undefined}
        >
            <Text
                style={data.mode !== CellMode.Card ? { display: 'none' } : undefined}
                aria-hidden={data.mode !== CellMode.Card}
            >
                {data.mode === CellMode.Card ? data.labelText : ''}
            </Text>
        </Button>
    )
}
export const ItemEditorCheckbox = ({ data, index }: RowData<StimulusTableProps<boolean>>) => {
    return (
        <Col alignItems={'center'}>
            <ItemEditorCheckboxInput
                disabled={data.disabled ?? false}
                itemId={data.id}
                inputId={`input-checkbox-${data.type}-id-${index}`}
                value={data.value}
                setValue={data?.setValue || _.noop}
                cellMode={data.mode ?? CellMode.Table}
                labelText={data.labelText}
            />
        </Col>
    )
}
export const NumberCell = ({ data, index }: RowData<StimulusTableProps<number>>) => {
    return (
        <Col width={'100%'}>
            <ItemEditorNumberInput
                inputId={`input-text-${data.type}-id-${index}`}
                value={data.value}
                placeholder={data?.placeholder || ''}
                itemId={data.id}
                setValue={data?.setValue || _.noop}
                validationErrorMessage={data?.errorMessage ?? ''}
                cellMode={data.mode ?? CellMode.Table}
                disabled={data.disabled ?? false}
                labelText={data.labelText}
                showError={data.showError}
            />
        </Col>
    )
}

export function getRenderCardForRow<T>(
    tableColumns: TableColumn<T, StimulusTableProps<unknown>>[]
) {
    function CardForTableRow({ data, index }: { data: T; index: number }) {
        return (
            <Col>
                <Text>Entry {index + 1}</Text>
                <Hr />
                <Spacer height='S200' />
                {tableColumns.map((col, j) => {
                    if (!data) {
                        return null
                    }
                    if (typeof col.accessor === 'string' || !col.accessor) {
                        console.error('col.accessor is not a function', { data, index, col })
                        return null
                    }
                    if (!col.cellComponent) {
                        return null
                    }
                    const cellData: StimulusTableProps<unknown> = {
                        ...col.accessor({ data, index }),
                        mode: CellMode.Card,
                    }

                    const C = col.cellComponent
                    return (
                        <Col key={j}>
                            <C data={cellData} index={index} />
                            <Spacer height='S300' />
                        </Col>
                    )
                })}
            </Col>
        )
    }
    ;(CardForTableRow as never as { displayName?: string }).displayName = 'CardForTableRow'
    return CardForTableRow
}

type CellComponent = ({ data, index }: RowData<StimulusTableProps<unknown>>) => JSX.Element

const CellComponents: Record<CellType, CellComponent> = {
    TextInput: TextCell as never as CellComponent,
    NumberInput: NumberCell as never as CellComponent,
    Checkbox: ItemEditorCheckbox as never as CellComponent,
    DeleteButton: ButtonCell as never as CellComponent,
}

type ItemEditorTableColumnAccessor<D, T> = (data: D) => T

interface ItemEditorTableColumn<D, T> {
    header: string | JSX.Element
    headerLabel: string
    labelText?: string
    width: string
    cellType: CellType
    placeHolder?: string
    disabled?: boolean
    accessor?: ItemEditorTableColumnAccessor<D, T>
    setValue?: (nextValue: T, index: number) => void
}

export type ItemEditorTableColumns<D> = ItemEditorTableColumn<D, unknown>[]

export interface ItemEditorTableProp<D> {
    itemId: string
    data: D[]
    columns: ItemEditorTableColumns<D>
    dataTestId?: string
    validationErrorMessage?: string
    showError?: boolean
}

export function ItemEditorTable<D>({
    itemId,
    data,
    columns,
    dataTestId,
    validationErrorMessage,
    showError,
}: ItemEditorTableProp<D>) {
    const tableColumns: TableColumn<D, StimulusTableProps<unknown>>[] = useMemo(
        () =>
            columns.map((column) => ({
                header: column.header,
                width: column.width,
                cellComponent: CellComponents[column.cellType],
                accessor: ((columnData) => {
                    const type = columnData.headerLabel.replace(/\s/g, '').toLowerCase()
                    return (rowData: RowData<D>) =>
                        ({
                            id: itemId,
                            type,
                            cellType: column.cellType,
                            labelText: columnData.headerLabel,
                            errorMessage: validationErrorMessage,
                            placeholder: columnData.placeHolder,
                            disabled: column.disabled ?? false,
                            value: columnData.accessor
                                ? columnData.accessor(rowData.data)
                                : undefined,
                            setValue: (nextValue: unknown) =>
                                columnData.setValue?.(nextValue as never, rowData.index),
                            mode: CellMode.Table,
                        } as StimulusTableProps<unknown>)
                })(column),
            })),
        [columns, itemId, validationErrorMessage]
    )

    const renderCardForRow = useMemo(() => getRenderCardForRow(tableColumns), [tableColumns])

    return (
        <>
            <Table columns={tableColumns} {...{ renderCardForRow, data, dataTestId }} />
            {validationErrorMessage && showError && (
                <InputFooter error={true} id={`item-editor-table-error-footer-${itemId}`}>
                    {validationErrorMessage}
                </InputFooter>
            )}
        </>
    )
}
