import { SetStateAction, useCallback, useMemo } from 'react'

import { applyChange, SetStateCallback, StateAndCallback } from './index'

export type GetKey<T> = (value: T) => string | number

export interface ListManipulation<T> {
    addItem(value?: T): void
    deleteItem(index: number): void
    onItemChange(index: number): SetStateCallback<T>
    getKey: GetKey<T>
}

function useListManipulation<T>(
    onChangeForList: SetStateCallback<T[]>,
    getKey: GetKey<T>
): ListManipulation<T> {
    const onChange = onChangeForList
    const addItem = useCallback(
        (newValue: T) => {
            // Extra variable assignment is here to show the function name to the debugger
            const addItemToList = (value: T[]) => {
                return [...(value ?? []), newValue]
            }
            onChange(addItemToList)
        },
        [onChange]
    )

    const deleteItem = useCallback(
        (i: number) => {
            const deleteItemFromList = (value?: T[]) => {
                const newValue = [...(value ?? [])]
                newValue.splice(i, 1)
                return newValue
            }
            onChange(deleteItemFromList)
        },
        [onChange]
    )

    const onItemChange = useCallback(
        (i: number) => (change: SetStateAction<T>) => {
            const updateByKey = (value?: T[]) => {
                const newValue = [...(value ?? [])]
                newValue[i] = applyChange(newValue[i], change)
                return newValue
            }

            onChange(updateByKey)
        },
        [onChange]
    )

    return useMemo(
        () => ({ addItem, deleteItem, getKey, onItemChange }),
        [addItem, deleteItem, getKey, onItemChange]
    )
}

export interface UseListEditor<T> {
    listManipulation: ListManipulation<T>
    getPropsForIndex: (
        value: T,
        index: number
    ) => { key: string | number; props: StateAndCallback<T> }
}

export function useListEditor<T>(
    props: StateAndCallback<T[]>,
    getKey: GetKey<T>
): UseListEditor<T> {
    const { onChange } = props
    const listManipulation = useListManipulation(onChange, getKey)
    const { onItemChange } = listManipulation
    const len = props.value.length

    const itemChanges = useMemo(() => {
        const list: SetStateCallback<T>[] = []
        for (let i = 0; i < len; i++) {
            list.push(onItemChange(i))
        }
        return list
    }, [onItemChange, len])

    const getPropsForIndex = useCallback(
        (v: T, i: number) => {
            return {
                key: getKey(v) ?? i,
                props: { value: v, onChange: itemChanges[i] },
            }
        },
        [itemChanges, getKey]
    )

    return useMemo(
        () => ({ listManipulation, getPropsForIndex }),
        [listManipulation, getPropsForIndex]
    )
}
