import React, { useEffect, useState } from 'react'
import _ from 'lodash'

import { Button } from '@amzn/stencil-react-components/button'
import { Input, InputFooter, InputWrapper, Select } from '@amzn/stencil-react-components/form'
import { IconQuestionCircle, SVGComponentProps } from '@amzn/stencil-react-components/icons'
import { Col, Hr, Row, Spacer } from '@amzn/stencil-react-components/layout'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'
import { Text } from '@amzn/stencil-react-components/text'
import { withTooltip } from '@amzn/stencil-react-components/tooltip'

import { DropdownButton } from 'src/components/DropdownButton'
import { SmartExpanderContent } from 'src/components/SmartExpander'
import { isDefaultLocale } from 'src/contexts/ModuleLocaleContext'
import { useEntity } from 'src/hooks/useEntity'
import { MUPPExamEntity } from 'src/models/dto/activities/MUPPExamDTO'
import { Locale } from 'src/models/dto/Locale'
import { Dimensions, TableDimension } from 'src/models/dto/mupp/PairingTableDTO'
import { GENERIC_ERROR_KEY, ValidationErrorEntity } from 'src/models/dto/TemplateValidationError'
import { GeneratedPairingTablesModal } from 'src/pages/module-builder/mupp-exam-editor/GeneratedPairingTablesModal'
import { ItemBankDetailsModal } from 'src/pages/module-builder/mupp-exam-editor/ItemBankDetailsModal'
import { SelectedMUPPPairingTable } from 'src/pages/module-builder/mupp-exam-editor/SelectedPairingTable'
import { SelectItemBankModal } from 'src/pages/module-builder/mupp-exam-editor/SelectItemBankModal'
import { activityDisplayNameMap } from 'src/pages/module-builder/page-editor/ActivityDropDown'
import { PageHeader } from 'src/pages/module-builder/page-editor/PageHeader'
import { ACTIVITY_ENTITY_STORE_SELECTOR } from 'src/services/EntityServices/ActivityEntityService'
import { MUPPExamHandler } from 'src/services/EntityServices/ActivityUpdateHandlers/MUPPExamHandler'
import { ModuleEntityService } from 'src/services/EntityServices/ModuleEntityService'
import {
    VALIDATION_ERROR_ENTITY_STORE_SELECTOR,
    ValidationErrorEntityService,
} from 'src/services/EntityServices/ValidationErrorEntityService'

interface MUPPExamEditorProps {
    locale: Locale
    pageIndex: number
    moduleEntityId: string
    workflowEntityId: string
    showValidationError?: boolean
    isLastPage: boolean
    defaultExpanded?: boolean
    editDisabled?: boolean
}

export const MUPPExamEditor = (props: MUPPExamEditorProps) => {
    const [isExpanded, setIsExpanded] = useState(props.defaultExpanded ?? true)
    const [showItemBankDetailsModal, setShowItemBankDetailsModal] = useState<boolean>(false)
    const [showPairingTableModal, setShowPairingTableModal] = useState<boolean>(false)
    const [showSelectItemBank, setShowSelectItemBank] = useState<boolean>(false)

    const IconWithTooltip = withTooltip<SVGComponentProps>()(IconQuestionCircle)
    const [dimensionsValue, setDimensionsValue] = useState<string[]>([])

    const { entity: muppExamDTO } = useEntity<MUPPExamEntity>({
        entityId: props.workflowEntityId,
        selector: ACTIVITY_ENTITY_STORE_SELECTOR,
    })

    if (!ValidationErrorEntityService.has(props.workflowEntityId)) {
        ValidationErrorEntityService.create(props.workflowEntityId)
    }

    const { entity: validationErrorEntity } = useEntity<ValidationErrorEntity>({
        entityId: props.workflowEntityId,
        selector: VALIDATION_ERROR_ENTITY_STORE_SELECTOR,
    })

    const [showError, setShowError] = useState(props.showValidationError ?? false)

    useEffect(() => {
        setShowError(props.showValidationError ?? false)
    }, [props.showValidationError])

    const invalidItemBankId: boolean =
        showError &&
        validationErrorEntity !== undefined &&
        validationErrorEntity.validationErrors.itemBankId !== undefined

    const invalidPairingTable: boolean =
        showError &&
        validationErrorEntity !== undefined &&
        validationErrorEntity.validationErrors.pairingTableDTO !== undefined

    useEffect(() => {
        if (muppExamDTO.selection.pairingTableDTO?.dimensionPairs.length > 0) {
            const currentDimensions = _.flatten(
                muppExamDTO.selection.pairingTableDTO.dimensionPairs.map((dp) => [
                    Dimensions.find((dim) => dim.systemName === dp.first)?.friendlyName ?? dp.first,
                    Dimensions.find((dim) => dim.systemName === dp.second)?.friendlyName ??
                        dp.second,
                ])
            ).filter((d, i, self) => self.indexOf(d) === i)

            setDimensionsValue(currentDimensions)
        }
    }, [muppExamDTO.selection.pairingTableDTO?.dimensionPairs])

    const closePairingTableModal = () => {
        setShowPairingTableModal(false)
    }

    const renderOption = (dimension: string) => (
        <Row gridGap='S200' key={dimension} dataTestId={dimension} alignItems='center'>
            {dimension}
        </Row>
    )

    const setItemBank = (version: string, id: string, _locales: string[]) => {
        ModuleEntityService.updateMUPPItemBankId(props.moduleEntityId, id)

        MUPPExamHandler.update({
            ...muppExamDTO,
            selection: {
                ...muppExamDTO.selection,
                itemBankId: version,
            },
        })
        setShowSelectItemBank(false)
    }

    return (
        <>
            {validationErrorEntity.validationErrors[GENERIC_ERROR_KEY] && showError && (
                <MessageBanner type={MessageBannerType.Error}>
                    <ul>
                        {validationErrorEntity.validationErrors[GENERIC_ERROR_KEY].map(
                            (message, index) => (
                                <li key={`${index}-ws-generic-error`}>{message}</li>
                            )
                        )}
                    </ul>
                </MessageBanner>
            )}
            <Col gridGap={'S300'} dataTestId={`page-editor-${props.pageIndex}`}>
                <Spacer height='S100' />
                <PageHeader
                    isLastPage={props.isLastPage}
                    moduleEntityId={props.moduleEntityId}
                    workflowEntityId={props.workflowEntityId}
                    isExpanded={isExpanded}
                    pageIndex={props.pageIndex}
                    onclick={() => {
                        setIsExpanded(!isExpanded)
                    }}
                    activityName={activityDisplayNameMap.get(muppExamDTO.type)}
                    onErrorCheck={setShowError}
                    editDisabled={props.editDisabled}
                />
                <SmartExpanderContent isExpanded={isExpanded}>
                    {() => (
                        <Col
                            gridGap={20}
                            backgroundColor={'white'}
                            padding={'S300'}
                            dataTestId={'mupp-exam-information'}
                        >
                            <InputWrapper
                                id={'dimension-list'}
                                tooltipAltText={'Information'}
                                tooltipText={
                                    'The list of dimensions to be included in the pairing table. ' +
                                    'Every selected dimension will appear the same amount of times in the pairing table.'
                                }
                                labelText={'Dimensions'}
                            >
                                {(inputProps) => (
                                    <Row flex={1}>
                                        <Select
                                            dataTestId={'dimension-list'}
                                            key='dimensions'
                                            renderOption={renderOption}
                                            value={dimensionsValue}
                                            isMulti
                                            {...inputProps}
                                            isSearchable
                                            options={Dimensions.map(
                                                (option: TableDimension) => option.friendlyName
                                            )}
                                            onChange={setDimensionsValue}
                                            disabled={props.editDisabled}
                                        />
                                    </Row>
                                )}
                            </InputWrapper>
                            {dimensionsValue.length < 5 && (
                                <InputFooter
                                    error={true}
                                    id={'multiple-choice-item-table-error-footer'}
                                >
                                    Please add at least 5 dimensions in order to generate pairing
                                    tables
                                </InputFooter>
                            )}

                            <Col gridGap={10}>
                                <Row justifyContent='space-between'>
                                    <Col>
                                        <Row alignItems='center'>
                                            <Text fontSize='T200'>Selected Pairing Table</Text>
                                            <IconWithTooltip
                                                title='Show tooltip for selected pairing table'
                                                color='primary70'
                                                tooltipText='The pairing table that will be used for the Work Styles Exam.'
                                            />
                                        </Row>
                                        {invalidPairingTable && (
                                            <InputFooter
                                                style={{
                                                    visibility: invalidPairingTable
                                                        ? 'visible'
                                                        : 'hidden',
                                                }}
                                                error
                                                id={'pairing-table-error-footer'}
                                            >
                                                {validationErrorEntity.validationErrors.pairingTableDTO.join(
                                                    ', '
                                                )}
                                            </InputFooter>
                                        )}
                                    </Col>

                                    <Col>
                                        <Button
                                            dataTestId={'generate-pairing-tables-button'}
                                            aria-disabled={
                                                dimensionsValue.length < 5 ||
                                                !isDefaultLocale(props.locale) ||
                                                props.editDisabled
                                            }
                                            onClick={() => setShowPairingTableModal(true)}
                                        >
                                            Generate Pairing Tables
                                        </Button>
                                    </Col>
                                </Row>
                                <Hr />
                                <SelectedMUPPPairingTable muppExamId={muppExamDTO.id} />
                                <Hr />
                            </Col>

                            <Col gridGap={30} justifyContent='space-between'>
                                <InputWrapper
                                    id={'item-bank-id'}
                                    tooltipAltText={'Information'}
                                    tooltipText={
                                        'The version Id of the Item Bank the Work Styles Exam will use.'
                                    }
                                    labelText={'Item Bank Version ID'}
                                >
                                    {(inputProps) => (
                                        <Row justifyContent='space-between'>
                                            <Col>
                                                <Input
                                                    dataTestId={'item-bank-id'}
                                                    width={370}
                                                    {...inputProps}
                                                    disabled={true}
                                                    error={invalidItemBankId}
                                                    value={muppExamDTO.selection.itemBankId}
                                                />
                                                {invalidItemBankId && (
                                                    <InputFooter
                                                        style={{
                                                            visibility: invalidItemBankId
                                                                ? 'visible'
                                                                : 'hidden',
                                                        }}
                                                        error
                                                        id={'item-bank-id-error-footer'}
                                                    >
                                                        {validationErrorEntity.validationErrors.itemBankId.join(
                                                            ', '
                                                        )}
                                                    </InputFooter>
                                                )}
                                            </Col>
                                            <Col>
                                                <DropdownButton
                                                    title={'Item Bank Options'}
                                                    dataTestId={'item-bank-drop-down'}
                                                    values={[
                                                        {
                                                            name: 'View Item Bank Details',
                                                            dataTestId:
                                                                'view-item-bank-details-button',
                                                            onClick: () =>
                                                                setShowItemBankDetailsModal(true),
                                                            disabled:
                                                                muppExamDTO.selection.itemBankId ===
                                                                    '' ||
                                                                !isDefaultLocale(props.locale),
                                                        },
                                                        {
                                                            name: 'Select Item Bank',
                                                            dataTestId: 'select-item-bank-button',
                                                            disabled:
                                                                !isDefaultLocale(props.locale) ||
                                                                props.editDisabled,
                                                            onClick: () =>
                                                                setShowSelectItemBank(true),
                                                        },
                                                    ]}
                                                />
                                            </Col>
                                        </Row>
                                    )}
                                </InputWrapper>
                            </Col>

                            {showItemBankDetailsModal && (
                                <ItemBankDetailsModal
                                    itemBankId={muppExamDTO.selection.itemBankId}
                                    isOpen={showItemBankDetailsModal}
                                    close={setShowItemBankDetailsModal}
                                />
                            )}
                            <SelectItemBankModal
                                isOpen={showSelectItemBank}
                                close={setShowSelectItemBank}
                                setItemBank={setItemBank}
                            />

                            {showPairingTableModal && (
                                <GeneratedPairingTablesModal
                                    muppExamID={muppExamDTO.id}
                                    dimensionsValue={dimensionsValue}
                                    isOpen={showPairingTableModal}
                                    close={closePairingTableModal}
                                />
                            )}
                        </Col>
                    )}
                </SmartExpanderContent>
            </Col>
        </>
    )
}
