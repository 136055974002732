import React from 'react'

import { Button, ButtonVariant } from '@amzn/stencil-react-components/button'
import { Col, Row } from '@amzn/stencil-react-components/layout'
import { ModalContent, WithModal } from '@amzn/stencil-react-components/modal'
import { Text } from '@amzn/stencil-react-components/text'

import { IconWithTooltip } from 'src/components/IconWithTooltip'
import { isDefaultLocale } from 'src/contexts/ModuleLocaleContext'
import { Locale } from 'src/models/dto/Locale'
import { ItemEditorCheckboxInput } from 'src/pages/module-builder/item-editors/ItemEditorInputs'
import { ItemGroupHandler } from 'src/services/EntityServices/ActivityUpdateHandlers/ItemGroupHandler'

export interface RemoveBranchingConditionsModalProps {
    workflowEntityId: string
    pageIndex: number
    locale: Locale
    isConditional: boolean
    setIsConditional: (nextValue: boolean) => void
    editDisabled?: boolean
}

export function RemoveBranchingConditionsModal({
    workflowEntityId,
    pageIndex,
    locale,
    isConditional,
    setIsConditional,
    editDisabled,
}: RemoveBranchingConditionsModalProps) {
    const renderModal = ({ close }: { close: () => void }) => {
        return (
            <ModalContent
                titleText='Remove page conditional logic'
                buttons={[
                    <Button
                        key='remove-branching-conditions-submit'
                        dataTestId='remove-branching-conditions-submit'
                        onClick={() => {
                            ItemGroupHandler.removeBranchingConditions(workflowEntityId)
                            setIsConditional(false)
                            close()
                        }}
                        isDestructive
                    >
                        Yes, delete
                    </Button>,
                    <Button
                        key='close-modal'
                        dataTestId='close-modal'
                        onClick={close}
                        variant={ButtonVariant.Primary}
                    >
                        {"No, don't delete"}
                    </Button>,
                ]}
            >
                <Col gridGap='S400'>
                    <Text>
                        Do you want to remove the conditional flag from this page? Unselecting this
                        checkbox will remove the existing branching conditions, and will have to be
                        recreated manually if you want to undo.
                    </Text>
                </Col>
            </ModalContent>
        )
    }

    return (
        <WithModal renderModal={renderModal}>
            {({ open }) => {
                return (
                    <Row alignItems={'center'}>
                        <ItemEditorCheckboxInput
                            inputId={`page-${pageIndex}-conditional-input`}
                            dataTestId={`page-${pageIndex}-conditional-input`}
                            disabled={!isDefaultLocale(locale) || editDisabled}
                            itemId={`page-${pageIndex}-conditional-input`}
                            labelText={'Conditional page'}
                            value={isConditional}
                            setValue={(nextValue: boolean) => {
                                if (nextValue === true) {
                                    ItemGroupHandler.addBranchingConditions(workflowEntityId)
                                    setIsConditional(true)
                                } else {
                                    open()
                                }
                            }}
                        />
                        <IconWithTooltip
                            tooltipText='This page will be rendered to candidates only if conditions specified are met.
                        Conditional pages cannot be the first or last page of a module.'
                        />
                    </Row>
                )
            }}
        </WithModal>
    )
}
