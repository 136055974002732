import React, { useEffect, useRef } from 'react'

import { Button, ButtonVariant } from '@amzn/stencil-react-components/button'
import { useAsync } from '@amzn/stencil-react-components/hooks'
import { IconPlus } from '@amzn/stencil-react-components/icons'
import { Col, Flex, Spacer, View } from '@amzn/stencil-react-components/layout'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'
import { H2, Text } from '@amzn/stencil-react-components/text'

import { CustomHeaderComponentProps, ModuleDisplayTable } from 'src/components/ModuleDisplayTable'
import { ModuleMetaData } from 'src/models/SearchResultResponse'
import { LAYOUT_DIRECTION, useLayoutDirection } from 'src/pages/search/SearchResultsPage'
import { Authenticator } from 'src/services/Authenticator'
import { ModuleService } from 'src/services/backend/ModuleService'

export interface MyModulesListProps {
    onCreateModule?: () => void
}

const CreateModuleButton = (props: { onCreateModule?: () => void }) => {
    const layout = useLayoutDirection()

    const button = (
        // @ts-ignore: Expression produces a union type that is too complex to represent error
        <Button
            id='create-module-button'
            data-test-id='create-module-button'
            variant={ButtonVariant.Primary}
            icon={<IconPlus aria-hidden={true} />}
            onClick={() => props.onCreateModule && props.onCreateModule()}
        >
            Create Module
        </Button>
    )

    return (
        <Col margin={{ top: '20%' }}>
            <View width={layout === LAYOUT_DIRECTION.MOBILE ? '270px' : '352px'}>
                <H2 fontSize='T400' color='neutral70' textAlign='center'>
                    You have not created any module content yet.
                </H2>
            </View>
            <View justifyContent='center' alignItems={'center'} display={'flex'} padding={'20px'}>
                {button}
            </View>
        </Col>
    )
}

const MyModulesHeader = (props: CustomHeaderComponentProps) => {
    const layout = useLayoutDirection()

    return (
        <>
            {layout !== LAYOUT_DIRECTION.MOBILE && <Spacer height='S600' />}
            <Flex display='flex' flexDirection='row' justifyContent='space-between' width='100%'>
                {layout !== LAYOUT_DIRECTION.MOBILE && <H2>My modules</H2>}

                <Text>
                    Showing {props.currentRecordsNum} of {props.totalRecordsNum} results
                </Text>
            </Flex>
        </>
    )
}

const NumberOfRecordsPerPage = 10
const MyModulesTable = (props: { myModulesList: ModuleMetaData[] }): JSX.Element => {
    const searchResult = {
        data: props.myModulesList,
        totalRecords: props.myModulesList.length,
        offset: 0,
        numberOfRecordsPerPage: NumberOfRecordsPerPage,
    }

    return (
        <ModuleDisplayTable
            searchResults={searchResult}
            CustomHeaderComponent={MyModulesHeader}
            searchTerm=''
        />
    )
}

export const MyModules = (props: MyModulesListProps) => {
    const {
        data: response,
        isLoading,
        error,
    } = useAsync(() => ModuleService.getMyModules(Authenticator.getDefaultUser()), [])
    const apiCalled = useRef(0)

    useEffect(() => {
        if (isLoading) apiCalled.current = 1
    }, [isLoading])

    if (isLoading || apiCalled.current === 0) {
        return null
    }

    if (error) {
        return (
            <MessageBanner type={MessageBannerType.Error} data-test-id='my-modules-message-banner'>
                Failed to retrieve my modules list: {error.toString()}
            </MessageBanner>
        )
    }

    return (
        <View alignItems='center' justifyContent='center' width='100%' display='flex'>
            {response?.myModulesList && response?.myModulesList.length > 0 ? (
                <MyModulesTable myModulesList={response.myModulesList} />
            ) : (
                <CreateModuleButton onCreateModule={props?.onCreateModule} />
            )}
        </View>
    )
}
