export const styles = {
    highlightRow: {
        animation: 'selectionStepHighlightFade 3s ease-out',
    },
    keyframes: `
        @keyframes selectionStepHighlightFade {
            0%, 50% { 
                background-color: rgba(0, 123, 255, 0.2); 
            }
            100% { 
                background-color: transparent; 
            }
        }
    `,
}
export const injectKeyframes = () => {
    if (typeof document !== 'undefined') {
        const styleSheet = document.createElement('style')
        styleSheet.textContent = styles.keyframes
        document.head.appendChild(styleSheet)
    }
}
