import React, { useCallback, useEffect, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { Button, ButtonSize, ButtonVariant } from '@amzn/stencil-react-components/button'
import { Expander } from '@amzn/stencil-react-components/expander'
import { Select } from '@amzn/stencil-react-components/form'
import {
    IconCheckCircleFill,
    IconCloseCircleFill,
    IconMoreHorizontal,
} from '@amzn/stencil-react-components/icons'
import { Col, Row, View } from '@amzn/stencil-react-components/layout'
import { ModalContent, WithModal } from '@amzn/stencil-react-components/modal'
import { Pagination } from '@amzn/stencil-react-components/pagination'
import { Spinner, SpinnerSize } from '@amzn/stencil-react-components/spinner'
import { SortState, Table, useSort } from '@amzn/stencil-react-components/table'
import { Text } from '@amzn/stencil-react-components/text'

import { DropdownButton } from 'src/components/DropdownButton'
import { LinkWithTooltip, ModuleDateCell } from 'src/components/ModuleDisplayTable'
import { ResponsiveRow } from 'src/components/ResponsiveRow'
import {
    ModuleGroupMetadata,
    ModuleGroupSortCriteria,
    ModuleGroupStatus,
} from 'src/models/dto/module-groups/ModuleGroupTypeDTO'
import { RuleDTO, RuleSubType } from 'src/models/dto/module-groups/PECGroupDTO'
import { ModuleGroupUtility } from 'src/pages/module-groups/ModuleGroupUtility'
import { moduleViewerRoute } from 'src/pages/module-review'

const ModuleGroupNameCell = ({ data }: { data: ModuleGroupMetadata }) => {
    return (
        <Col>
            <Text textAlign='left' color='#0077D9'>
                {' '}
                <RouterLink to={`/module-groups/${data.moduleGroupVersionId}`}>
                    <strong>{data.moduleGroupName}</strong>
                </RouterLink>{' '}
            </Text>
            <Text textAlign='left' color='neutral70' fontSize='T100'>
                {' '}
                Owner:{' '}
                <LinkWithTooltip
                    target='_blank'
                    href={`https://phonetool.amazon.com/users/${data.owner}`}
                    tooltipText='Opens user in Phone Tool'
                >
                    {data.owner}
                </LinkWithTooltip>{' '}
            </Text>
        </Col>
    )
}

const options = ['5', '10', '25']

const renderOption = (option: string) => <Text>{option} items per page</Text>

const DEFAULT_PAGE_SIZE = 10

export interface DuplicateOptionsProps {
    setShowDuplicateModal: (value: boolean) => void
    setSelectedModuleGroupId: (value: string) => void
    setSelectedModuleGroupName: (value: string) => void
}

export interface ModuleGroupTableProps {
    data: ModuleGroupMetadata[] | ModuleTableTypeData[]
    tableType: 'module' | 'moduleGroup' | 'moduleGroupReview'
    pageSize?: number
    totalRecords: number
    loading: boolean
    setPage?: (page: number) => void
    selectedPage?: number
    updatePageSize?: (pageSize: number) => void
    onDelete?: (versionId: string, index: number) => void
    onEdit?: (index: number) => void
    onDuplicate?: (index: number) => void
    sortCriteria?: ModuleGroupSortCriteria
    onSortCriteriaChange?: (sortCriteria: ModuleGroupSortCriteria) => void
    editDisabled?: boolean
    duplicateOptions?: DuplicateOptionsProps
}

export interface ModuleTableTypeData {
    priority: number
    versionId: string
    moduleName: string
    metadata?: RuleDTO[]
    score?: string
    equivalencyReason: string
    index: number
}

enum ModuleOptionsModal {
    DELETE_MODULE = 'DELETE_MODULE',
}

interface RenderModalProps {
    data: ModuleTableTypeData
    close: () => void
}

export const ModuleGroupTable = ({
    selectedPage: parentSelectedPage,
    pageSize: parentPageSize,
    totalRecords,
    duplicateOptions,
    loading,
    setPage,
    updatePageSize: updateParentPageSize,
    data: parentData,
    tableType,
    onSortCriteriaChange,
    sortCriteria,
    onEdit,
    onDuplicate,
    editDisabled,
    onDelete,
}: ModuleGroupTableProps) => {
    const [selectedPage, setSelectedPage] = useState(parentSelectedPage ?? 1)
    const [pageSize, setPageSize] = useState(parentPageSize ?? DEFAULT_PAGE_SIZE)
    const [numberOfPages, setNumberOfPages] = useState<number>(
        parentPageSize ? Math.ceil(totalRecords / pageSize) : 1
    )
    const [openModal, setOpenModal] = useState<ModuleOptionsModal | null>(
        ModuleOptionsModal.DELETE_MODULE
    )

    const { activeSortId, sortDirection, onSort } = useSort({
        defaultSortDirection: sortCriteria ? sortCriteria.sortDirection : undefined,
        defaultActiveSortId: sortCriteria ? sortCriteria.sortField : undefined,
    })

    const DuplicateButton = useCallback(
        ({ data: passedData }: { data: ModuleGroupMetadata }) => {
            return (
                <Button
                    variant={ButtonVariant.Secondary}
                    size={ButtonSize.Small}
                    id={`duplicate-${passedData.moduleGroupName}`}
                    dataTestId={`duplicate-${passedData.moduleGroupName}`}
                    onClick={() => {
                        if (duplicateOptions) {
                            duplicateOptions.setSelectedModuleGroupId(
                                passedData.moduleGroupVersionId
                            )
                            duplicateOptions.setSelectedModuleGroupName(passedData.moduleGroupName)
                            duplicateOptions.setShowDuplicateModal(true)
                        }
                    }}
                >
                    Duplicate
                </Button>
            )
        },
        [duplicateOptions]
    )

    const ActionButtons = useCallback(
        ({ data }: { data: ModuleTableTypeData }) => {
            const renderModals: Record<ModuleOptionsModal, React.FC<RenderModalProps>> = {
                [ModuleOptionsModal.DELETE_MODULE]: (props: RenderModalProps) => (
                    <ModalContent titleText={`Delete Module: ${props.data.moduleName}`}>
                        <Col gridGap='S300'>
                            <Text>
                                Are you sure you want to delete this module from the module group?
                            </Text>
                            <Row gridGap='S400' justifyContent='right'>
                                <Button variant={ButtonVariant.Tertiary} onClick={props.close}>
                                    Cancel
                                </Button>
                                <Button
                                    dataTestId='confirm-delete-button'
                                    variant={ButtonVariant.Secondary}
                                    onClick={() => {
                                        if (onDelete) {
                                            onDelete(props.data.versionId, props.data.index)
                                        }
                                        props.close()
                                    }}
                                >
                                    Yes, Delete
                                </Button>
                            </Row>
                        </Col>
                    </ModalContent>
                ),
            }

            const renderModal = ({ close }: { close: () => void }) => {
                return openModal
                    ? renderModals[openModal]({
                          close,
                          data,
                      })
                    : null
            }

            const dropdownValues = (open: () => void) => {
                const editOption = {
                    name: 'Edit',
                    dataTestId: `edit-module-${data.index}-option`,
                    onClick: () => {
                        if (onEdit) {
                            onEdit(data.index)
                        }
                    },
                }

                const duplicateOption = {
                    name: 'Duplicate',
                    dataTestId: `duplicate-module-${data.index}-option`,
                    onClick: () => {
                        if (onDuplicate) {
                            onDuplicate(data.index)
                        }
                    },
                }

                const deleteOption = {
                    name: 'Delete',
                    dataTestId: `delete-module-${data.index}-option`,
                    onClick: () => {
                        setOpenModal(ModuleOptionsModal.DELETE_MODULE)
                        open()
                    },
                }

                const values: { name: string; onClick: () => void; dataTestId?: string }[] = []

                values.push(editOption)
                values.push(duplicateOption)
                values.push(deleteOption)

                return values
            }

            return (
                <WithModal renderModal={renderModal} isScrollable={false}>
                    {({ open }) => (
                        <DropdownButton
                            title=''
                            buttonProps={{
                                size: ButtonSize.Small,
                            }}
                            disabled={editDisabled}
                            renderIcon={() => <IconMoreHorizontal title={'module-action-icon'} />}
                            values={dropdownValues(open)}
                            dataTestId={`module-rule-${data.index}-options-button`}
                            smallText={true}
                        />
                    )}
                </WithModal>
            )
        },
        [onDelete, openModal, onEdit, onDuplicate, editDisabled]
    )

    const JobMetadataView = useCallback(({ data }: { data: RuleDTO[] }) => {
        return (
            <Expander
                titleText={'Details'}
                renderTitle={({ titleText }) => {
                    return (
                        <Text fontSize='T100' fontWeight='bold' color='#0077D9'>
                            {titleText}
                        </Text>
                    )
                }}
            >
                {data.map((rule) => (
                    <Row padding={{ bottom: 'S200' }} key={rule.type}>
                        <Text fontSize='T100'>
                            <Text
                                color={'neutral50'}
                                fontWeight='bold'
                                fontSize='T100'
                                display='inline'
                            >
                                {rule.subType === RuleSubType.INCLUDE ? 'IS ' : 'IS NOT '}
                            </Text>
                            <Text
                                fontWeight='bold'
                                fontSize='T100'
                                display='inline'
                            >{`${rule.type}: `}</Text>
                            <Text fontSize='T100' display='inline'>
                                {rule.values.join(', ')}
                            </Text>
                        </Text>
                    </Row>
                ))}
            </Expander>
        )
    }, [])

    useEffect(() => {
        if (parentSelectedPage) {
            setSelectedPage(parentSelectedPage)
        }
    }, [parentSelectedPage])

    useEffect(() => {
        if (parentPageSize) {
            setPageSize(parentPageSize)
            setNumberOfPages(Math.ceil(totalRecords / pageSize))
        }
    }, [pageSize, parentPageSize, totalRecords])

    const handleSort = (sortState: SortState) => {
        const sortField = sortState.sortId
        const direction = sortState.sortDirection
        if (
            onSortCriteriaChange &&
            (sortField !== sortCriteria?.sortField || direction !== sortCriteria.sortDirection)
        ) {
            onSortCriteriaChange({ sortField, sortDirection: direction })
        }
        onSort(sortState)
    }

    const tableColumns = () => {
        const priorityColumn = {
            header: 'Priority order',
            accessor: 'priority',
            textAlign: 'center',
            width: '5%',
        }

        const skipRuleColumn = {
            header: 'Skip rule',
            textAlign: 'center',
            width: '9%',
            cellComponent: ({ data }: { data: ModuleTableTypeData }) => {
                return ModuleGroupUtility.isSkippedModule(data.versionId) ? (
                    <Col alignItems={'center'}>
                        <IconCheckCircleFill
                            dataTestId={'skip-rule-fill-icon'}
                            title={'skip-rule-icon'}
                            color={'green80'}
                        />
                    </Col>
                ) : (
                    <Col alignItems={'center'}>
                        <IconCloseCircleFill
                            dataTestId={'non-skip-rule-circle-icon'}
                            title={'non-skip-rule-icon'}
                            color={'neutral30'}
                        />
                    </Col>
                )
            },
        }

        const RenderModuleNameAndVersion = ({ data }: { data: ModuleTableTypeData }) => {
            return (
                <Col>
                    <Text wordBreak='break-all' textAlign='left' color='#0077D9'>
                        {ModuleGroupUtility.isSkippedModule(data.versionId) ? (
                            'Skip Rule Enabled'
                        ) : (
                            <RouterLink
                                to={moduleViewerRoute({
                                    moduleVersionId: data.versionId,
                                })}
                            >
                                {data.moduleName}
                            </RouterLink>
                        )}
                    </Text>
                    {!ModuleGroupUtility.isSkippedModule(data.versionId) ? (
                        <Row>
                            <Text fontSize={'T100'}>{data.versionId}</Text>
                        </Row>
                    ) : null}
                </Col>
            )
        }

        const isPECMapDefined = (parentData[0] as ModuleTableTypeData).metadata

        if (tableType === 'moduleGroup') {
            return [
                {
                    header: 'Status',
                    accessor: ({ data }: { data: ModuleGroupMetadata }) =>
                        ModuleGroupStatus[data.status] as string,
                    width: '10%',
                    sortId: 'status',
                },
                {
                    header: 'Module Group',
                    width: '30%',
                    cellComponent: ModuleGroupNameCell,
                    sortId: 'module_group_name',
                },
                {
                    header: 'Type',
                    accessor: 'moduleGroupType',
                    width: '10%',
                    sortId: 'module_group_type',
                },
                { header: 'Purpose', accessor: 'purpose', sortId: 'purpose' },
                { header: 'Last Modified', sortId: 'modified_at', cellComponent: ModuleDateCell },
                {
                    header: 'Action',
                    width: '10%',
                    cellComponent: DuplicateButton,
                },
            ]
        } else if (tableType === 'moduleGroupReview') {
            const columnsList = [
                {
                    header: 'Module Name',
                    width: '20%',
                    textAlign: 'left',
                    cellComponent: ({ data }: { data: ModuleTableTypeData }) => {
                        return <RenderModuleNameAndVersion data={data} />
                    },
                },
                {
                    header: isPECMapDefined ? 'Metadata' : 'Score',
                    accessor: isPECMapDefined ? 'metadata' : 'score',
                    width: '30%',
                    cellComponent: isPECMapDefined ? JobMetadataView : undefined,
                },
                { header: 'Reason for equivalency', accessor: 'equivalencyReason' },
            ]

            // Add these two columns only if showing PEC, since priority and skip rule only relates to PEC
            if (isPECMapDefined) {
                // @ts-ignore: Bug with type check where it thinks textAlign is invalid as a string
                columnsList.splice(0, 0, priorityColumn)
                columnsList.splice(2, 0, skipRuleColumn)
            }

            return columnsList
        } else {
            const columnsList = [
                {
                    header: 'Module Name',
                    width: '30%',
                    textAlign: 'left',
                    cellComponent: ({ data }: { data: ModuleTableTypeData }) => {
                        return <RenderModuleNameAndVersion data={data} />
                    },
                },
                {
                    header: isPECMapDefined ? 'Metadata' : 'Score',
                    accessor: isPECMapDefined ? 'metadata' : 'score',
                    width: '40%',
                    cellComponent: isPECMapDefined ? JobMetadataView : undefined,
                },
                { header: 'Reason for equivalency', accessor: 'equivalencyReason' },
                {
                    header: 'Actions',
                    textAlign: 'center',
                    width: '8%',
                    cellComponent: ActionButtons,
                },
            ]

            // Add these two columns only if showing PEC, since priority and skip rule only relates to PEC
            if (isPECMapDefined) {
                // @ts-ignore: Bug with type check where it thinks textAlign is invalid as a string
                columnsList.splice(0, 0, priorityColumn)
                columnsList.splice(2, 0, skipRuleColumn)
            }

            return columnsList
        }
    }

    if (loading && parentData.length <= 0) {
        return (
            <View
                display='flex'
                justifyContent='center'
                width='100%'
                alignItems='center'
                height='100%'
            >
                <Spinner
                    dataTestId='module-groups-search-results-table-no-data-spinner'
                    size={SpinnerSize.Medium}
                />
            </View>
        )
    }

    return (
        <Col gridGap='S200'>
            <Col style={{ position: 'relative' }}>
                <Table
                    dataTestId={'current-module-groups-table'}
                    aria-labelledby='current-module-group-header'
                    isStriped={true}
                    // @ts-ignore: Bug with type check where it thinks textAlign is invalid as a string
                    columns={tableColumns()}
                    data={parentData}
                    activeSortId={activeSortId}
                    sortDirection={sortDirection}
                    onSort={handleSort}
                />
                {loading && (
                    // this overlays on top of the table
                    <View
                        display={'flex'}
                        justifyContent='center'
                        width='100%'
                        alignItems='center'
                        alignSelf={'center'}
                        height='100%'
                        backgroundColor={'rgba(255, 255, 255, 0.5)'}
                        style={{
                            position: 'absolute',
                            zIndex: 9, // so it overlays
                            top: 0,
                            left: 0,
                        }}
                    >
                        <Spinner
                            dataTestId='module-groups-search-results-table-spinner'
                            size={SpinnerSize.Large}
                        />
                    </View>
                )}
            </Col>
            {(numberOfPages > 1 || selectedPage > 1) && (
                <ResponsiveRow justifyContent={'space-between'}>
                    <Col>
                        <Select
                            id='items-per-page-select'
                            labelId='items-per-page-select'
                            data-test-id='items-per-page-select'
                            onChange={(option: string) => {
                                setPageSize(Number(option))
                                setSelectedPage(1)
                                if (setPage) {
                                    setPage(1)
                                }

                                setPageSize(Number(option))
                                if (updateParentPageSize) {
                                    updateParentPageSize(Number(option))
                                }
                            }}
                            options={options}
                            renderOption={renderOption}
                            value={pageSize}
                        />
                    </Col>
                    {numberOfPages > 1 && (
                        <Col>
                            <Pagination
                                numberOfPages={numberOfPages}
                                onPageSelect={(page) => {
                                    setSelectedPage(page)
                                    if (setPage) {
                                        setPage(page)
                                    }
                                }}
                                selectedPage={selectedPage}
                                dataTestId='results-table-pagination'
                            />
                        </Col>
                    )}
                </ResponsiveRow>
            )}
        </Col>
    )
}
