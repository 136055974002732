import React, { useEffect, useState } from 'react'
import AWS from 'aws-sdk'

import { Button, ButtonVariant } from '@amzn/stencil-react-components/button'
import { IconArrowLeft, IconPlus } from '@amzn/stencil-react-components/icons'
import { Col, Container, Row, Spacer } from '@amzn/stencil-react-components/layout'
import { Modal, ModalContent } from '@amzn/stencil-react-components/modal'
import { useBreakpoints } from '@amzn/stencil-react-components/responsive'
import { H2, Text } from '@amzn/stencil-react-components/text'

import { publishUserEventMetrics, UserEventMethodNames } from 'src/metrics'
import {
    DDB_RETRIEVE_ERROR,
    ddbFunctions,
    s3Functions,
    UnexpectedErrorsBanner,
    WorkflowAutomationTestingEvents,
} from 'src/pages/automation-tests-request/AutomationTestsPage'
import { ModuleInfoInput } from 'src/pages/automation-tests-request/ModuleInfoInput'
import { RunResults } from 'src/pages/automation-tests-request/ViewResultsPage'
import { ItemEditorTextInput } from 'src/pages/module-builder/item-editors/ItemEditorInputs'
import { setCrossAccountCredentials } from 'src/services/CrossAccountCredentials'
import { setUpAwsConfig } from 'src/services/media/S3FileHandler'

import '../module-builder/page-editor/PageEditor.scss'

export interface Workflow {
    name: string
    modules: ModuleInfo[]
}

export interface ModuleInfo {
    name: string
    versionId: string
    type?: ModuleType
    itemCount?: number
    pageTitles?: string[]
    pages?: number
}

export enum ModuleType {
    Landing = 'Landing',
    WorkflowInstruction = 'WorkflowInstruction',
    Transition = 'Transition',
    WorkStylesInstructions = 'WorkStylesInstructions',
    WorkStyles = 'WorkStyles',
    WorkSimulationInstruction = 'WorkSimulationInstruction',
    CRAInstruction = 'CRAInstruction',
    CRASoundCheck = 'CRASoundCheck',
    CRA = 'CRA',
    WritingInstructions = 'WritingInstructions',
    WritingExercise = 'WritingExercise',
    ThankYou = 'ThankYou',
    FeedbackSurvey = 'FeedbackSurvey',
}

export const defaultModuleInfo = () => {
    return {
        name: '',
        versionId: '',
        type: undefined,
    } as ModuleInfo
}

export const defaultWorkflow = () => {
    return {
        name: '',
        modules: [defaultModuleInfo()],
    } as Workflow
}

export const WorkflowDefinitionsUploaderPage = ({
    workflowDefinition,
    goBack,
    fileNames,
    loadDDBResults,
}: {
    workflowDefinition?: Workflow
    goBack: () => void
    fileNames: string[]
    loadDDBResults: () => Promise<RunResults[] | string>
}) => {
    const [modules, setModules] = useState(
        workflowDefinition ? workflowDefinition.modules : [defaultModuleInfo()]
    )
    const [workflowName, setWorkflowName] = useState(
        workflowDefinition ? workflowDefinition.name : ''
    )
    const [workflow, setWorkflow] = useState(
        workflowDefinition ? workflowDefinition : defaultWorkflow()
    )
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [deletePrompt, setDeletePrompt] = useState(false)

    const [validDefinition, setValidDefinition] = useState(workflowDefinition ? true : false)
    const [unexpectedError, setUnexpectedError] = useState<string | undefined>(undefined)

    useEffect(() => {
        setWorkflow({
            name: workflowName,
            modules,
        })

        if (workflowName.trim() === '') {
            setValidDefinition(false)
        } else {
            for (const moduleInfo of modules) {
                if (
                    Object.keys(moduleInfo).some(
                        (property) =>
                            moduleInfo[property] === '' ||
                            moduleInfo[property] === -1 ||
                            moduleInfo[property] === undefined
                    )
                ) {
                    setValidDefinition(false)
                    break
                } else {
                    setValidDefinition(true)
                }
            }
        }
    }, [workflowName, modules])

    const { matches } = useBreakpoints()

    const UNIQUE_ITEM_ID_FLEX_BASIS = matches.s ? '100%' : '350px'

    const removeModule = (index: number) => {
        const newModulesList = [...modules]
        newModulesList.splice(index, 1)
        setModules(newModulesList)
    }

    const updateModuleInfo = (index: number, value: ModuleInfo) => {
        setModules((prevModules) => {
            const newModulesList = [...prevModules]
            newModulesList[index] = value
            return newModulesList
        })
    }

    const getFileName = () => {
        return `${workflowName.trim().toLowerCase().replace(/ +/g, '_')}.json`
    }

    const isFileNameValid = () => {
        return !fileNames.includes(getFileName())
    }

    async function deleteRunResultOfFile() {
        const config = await setCrossAccountCredentials()

        const results = await loadDDBResults()
        let ddbItems: RunResults[] = []

        if (results !== DDB_RETRIEVE_ERROR) {
            ddbItems = results as RunResults[]
        }

        if (ddbItems.length > 0) {
            const requestParams = ddbItems.map((item) => {
                const request = {
                    DeleteRequest: {
                        Key: {
                            workflowDefinitionFile: { S: getFileName() },
                            runTimeStamp: { S: item.runTimeStamp },
                        },
                    },
                }
                return request
            })

            console.log(requestParams)

            ddbFunctions
                .batchWrite(requestParams, config)
                .then(() => {
                    publishUserEventMetrics(
                        UserEventMethodNames.WorkflowAutomationRequest,
                        WorkflowAutomationTestingEvents.DeleteWorkflowRunResults
                    )
                })
                .catch((error: Error) => {
                    // If there is an issue deleting the run results, publish error metric which will cause an alarm, but do not disturb the user as they are not impacted
                    publishUserEventMetrics(
                        UserEventMethodNames.WorkflowAutomationRequest,
                        WorkflowAutomationTestingEvents.DeleteRunResultsError
                    )

                    console.error(error, error.message)
                })
        }

        // Switch back to regular credentials
        await setUpAwsConfig()
        AWS.config.update({})
    }

    async function deleteJSONFile() {
        await setUpAwsConfig()

        s3Functions
            .deleteFileFromS3(getFileName())
            .then(async () => {
                publishUserEventMetrics(
                    UserEventMethodNames.WorkflowAutomationRequest,
                    WorkflowAutomationTestingEvents.DeleteJSONFile
                )

                // Clear the results first
                await deleteRunResultOfFile()

                goBack()
            })
            .catch((error: Error) => {
                publishUserEventMetrics(
                    UserEventMethodNames.WorkflowAutomationRequest,
                    WorkflowAutomationTestingEvents.DeleteFileError
                )

                setUnexpectedError(
                    'There was an error deleting the JSON file to the S3 bucket. The workflow definition file has not been removed from the list and will continue to be run on the pipeline.'
                )
                console.error(error, error.message)
            })
    }

    async function uploadJSONFile() {
        await setUpAwsConfig()

        // Use S3 ManagedUpload class as it supports multipart uploads
        s3Functions
            .uploadJSONFileToS3(getFileName(), JSON.stringify(workflow))
            // uploadJSONFileToS3(getFileName(), workflow)
            .then(() => {
                publishUserEventMetrics(
                    UserEventMethodNames.WorkflowAutomationRequest,
                    WorkflowAutomationTestingEvents.UploadJSONFile
                )

                setIsModalOpen(true)
            })
            .catch((error: Error) => {
                publishUserEventMetrics(
                    UserEventMethodNames.WorkflowAutomationRequest,
                    WorkflowAutomationTestingEvents.UploadFileError
                )

                setUnexpectedError(
                    'There was an error uploading the JSON file to the S3 bucket. It has not been uploaded, and cannot be run on the pipeline.'
                )
                console.error(error, error.message)
            })
    }

    const closeDeleteModal = () => {
        setDeletePrompt(false)
    }

    const closeUploadModal = () => {
        setIsModalOpen(false)
    }

    return (
        <Container paddingTop={'S400'} paddingHorizontal={'S400'}>
            {unexpectedError && (
                <UnexpectedErrorsBanner
                    errorMessage={unexpectedError}
                    onDismiss={() => {
                        setUnexpectedError(undefined)
                    }}
                />
            )}
            <Button
                variant={ButtonVariant.Tertiary}
                onClick={() => {
                    goBack()
                }}
                id={'return-to-selection-page'}
                dataTestId={'go-back-button'}
            >
                <IconArrowLeft aria-hidden={true} />
            </Button>
            <Col gridGap='S300' width={'100%'}>
                <H2>Build Workflow Definition</H2>
                <Text>
                    Add the modules you want to test as part of the workflow definition. You can
                    search for module using the search function, or manually enter them. All fields
                    must be populated for the file to run correctly.
                </Text>
                <Text>
                    {' '}
                    Only modules that are past the UAT stage can be tested via automated tests. The
                    file name this is generated in based on the Workflow Name you specify. Once
                    uploaded, you will receive further instructions on how to run the most recent
                    version of your file.
                </Text>
            </Col>
            <Spacer height={'S400'} />
            <Row gridGap='S300' alignItems={'flex-start'} flexWrap={'wrap'}>
                <Col flex={`3 0 ${UNIQUE_ITEM_ID_FLEX_BASIS}`} width={matches.s ? '100%' : '410px'}>
                    <ItemEditorTextInput
                        dataTestId={'workflow-name'}
                        inputId={'workflow-name'}
                        value={workflow.name}
                        placeholder={'Enter the workflow name'}
                        labelText={'Workflow Name'}
                        itemId={'workflow-name'}
                        disabled={workflowDefinition !== undefined}
                        setValue={(body: string) => {
                            setWorkflowName(body)
                        }}
                    />
                </Col>
            </Row>
            <Spacer height={'S400'} />
            {modules.map((module, index) => {
                return (
                    <dl key={index}>
                        <ModuleInfoInput
                            module={module}
                            index={index}
                            removeModule={removeModule}
                            updateModule={updateModuleInfo}
                            isLast={modules.length === 1}
                        />
                    </dl>
                )
            })}
            <Spacer height={'S400'} />
            <Row gridGap={'S200'}>
                <Button
                    dataTestId={'upload-json-file'}
                    onClick={async () => {
                        if (workflowDefinition !== undefined) {
                            await uploadJSONFile()
                        } else {
                            if (isFileNameValid()) {
                                await uploadJSONFile()
                            } else {
                                setUnexpectedError(
                                    `There is already a workflow definition file with the name ${getFileName()}. Cannot upload this file as it would override the previous workflow definition.`
                                )
                            }
                        }
                    }}
                    disabled={!validDefinition}
                >
                    Upload file
                </Button>
                {workflowDefinition && (
                    <Button
                        dataTestId={'delete-json-file'}
                        onClick={() => setDeletePrompt(true)}
                        isDestructive
                    >
                        Delete file
                    </Button>
                )}
                <Spacer flex={1} />
                <Spacer flex={1} />
                <Button
                    variant={ButtonVariant.Tertiary}
                    onClick={() => {
                        setModules([...modules, defaultModuleInfo()])
                    }}
                    id={'add-module-info-button'}
                    dataTestId={'add-module-info-button'}
                >
                    <IconPlus aria-hidden={true} />
                    Add module
                </Button>
            </Row>
            <Modal
                dataTestId='uploaded-json-file-modal'
                shouldCloseOnClickOutside={false}
                isOpen={isModalOpen}
                isScrollable
                close={closeUploadModal}
            >
                <ModalContent
                    titleText='Successfully Uploaded File'
                    maxWidth='50vw'
                    buttons={[
                        <Button
                            key='close-uploaded-modal'
                            dataTestId='close-uploaded-modal'
                            onClick={() => {
                                closeUploadModal()
                                goBack()
                            }}
                            variant={ButtonVariant.Primary}
                        >
                            Okay
                        </Button>,
                    ]}
                >
                    <Col gridGap='S300'>
                        <Text>
                            Congratulations, you have uploaded a workflow definition file for{' '}
                            {workflowName}, with the file name: {getFileName()}
                        </Text>
                        <Text>
                            The QA Automation pipeline will shortly pick up your changes latest
                            changes, and run the file against the automated tests. Once the file has
                            completed running, you can review the test cases via the “View Results“
                            section on Assessment Designer.
                        </Text>
                        <Text>
                            To update or delete this workflow, open the automation test page in
                            Assessment Designer and select the workflow file from the dropdown.
                        </Text>
                    </Col>
                </ModalContent>
            </Modal>
            <Modal
                dataTestId='delete-json-file-modal'
                shouldCloseOnClickOutside={false}
                isOpen={deletePrompt}
                isScrollable
                close={closeDeleteModal}
            >
                <ModalContent
                    titleText='Delete the file?'
                    maxWidth='50vw'
                    buttons={[
                        <Button
                            key='confirm-delete-modal'
                            dataTestId='confirm-delete-modal'
                            onClick={async () => {
                                closeDeleteModal()
                                await deleteJSONFile()
                            }}
                            isDestructive
                        >
                            Delete file
                        </Button>,
                        <Button
                            key='close-delete-modal'
                            dataTestId='close-delete-modal'
                            onClick={() => closeDeleteModal()}
                            variant={ButtonVariant.Primary}
                        >
                            Keep file
                        </Button>,
                    ]}
                >
                    <Col gridGap='S300'>
                        <Text>
                            Are you sure you want to delete the workflow definition file{' '}
                            {getFileName()}?
                        </Text>
                        <Text>
                            Once deleted, this workflow definition will no longer run on the
                            automation pipeline, and the results of previous runs will be also be
                            deleted. If you want to run this workflow again, you’ll need to create a
                            new file.
                        </Text>
                    </Col>
                </ModalContent>
            </Modal>
        </Container>
    )
}
