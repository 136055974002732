import { RandomSelectionGroupEntity } from 'src/models/dto/activities/RandomSelectionGroupDTO'
import { ActivityType } from 'src/models/dto/ActivityDTO'
import {
    activity,
    ActivityEntityService,
    factories,
} from 'src/services/EntityServices/ActivityEntityService'
import { STORE_ACTION } from 'src/services/Store'

const activityType = ActivityType.LaunchRandomSelection

export class RandomSelectionGroupHandler {
    static init() {
        factories.set(activityType, () => RandomSelectionGroupHandler.create())
    }

    static get(entityId: string): RandomSelectionGroupEntity {
        return ActivityEntityService.get(entityId) as RandomSelectionGroupEntity
    }

    /**
     *
     * @returns an RandomSelectionGroupEntity but does not add it to the Store
     */
    static create(): RandomSelectionGroupEntity {
        const newActivity = activity()

        const entity = {
            ...newActivity,
            name: 'RandomSelectionGroupName',
            ppt: ActivityType.LaunchRandomSelection,
            type: ActivityType.LaunchRandomSelection,
            groupSize: 5,
            numberOfItemsToPick: 20,
            poolVersionId: '',
        }
        return entity
    }

    static updateItemPool(entityId: string, poolVersionId: string) {
        const entity = this.get(entityId)

        const payload: RandomSelectionGroupEntity = {
            ...entity,
            poolVersionId,
        }
        this.update(payload)
    }

    static updateGroupSize(entityId: string, groupSize: number | null) {
        const entity = this.get(entityId)

        const payload: RandomSelectionGroupEntity = {
            ...entity,
            groupSize,
        }
        this.update(payload)
    }

    static updateNoOfItemsToPick(entityId: string, numberOfItemsToPick: number | null) {
        const entity = this.get(entityId)

        const payload: RandomSelectionGroupEntity = {
            ...entity,
            numberOfItemsToPick,
        }
        this.update(payload)
    }

    static update(payload: RandomSelectionGroupEntity) {
        ActivityEntityService.store.dispatch({
            action: STORE_ACTION.REQUEST_UPDATE,
            entityId: payload.id,
            payload: payload,
        })
    }
}
