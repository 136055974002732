import { applyChange, Change } from 'src/hooks/DTOEditor'
import {
    BucketAndCupSelectionStepDTO,
    BucketDTO,
    BucketsAndCupsGroupGroupEntity,
    BucketSelectionRule,
    CupSelectionRule,
    enforceBucketSelectionRuleValidation,
    PagePattern,
} from 'src/models/dto/activities/BucketsAndCupsGroupDTO'
import { ActivityType } from 'src/models/dto/ActivityDTO'
import {
    activity,
    ActivityEntityService,
    factories,
} from 'src/services/EntityServices/ActivityEntityService'
import { STORE_ACTION } from 'src/services/Store'

const activityType = ActivityType.LaunchBucketsAndCups

export class BucketsAndCupsGroupHandler {
    static init() {
        factories.set(activityType, () => BucketsAndCupsGroupHandler.create())
    }

    static get(entityId: string): BucketsAndCupsGroupGroupEntity {
        return ActivityEntityService.get(entityId) as BucketsAndCupsGroupGroupEntity
    }

    /**
     *
     * @returns an BucketsAndCupsGroupEntity but does not add it to the Store
     */
    static create(): BucketsAndCupsGroupGroupEntity {
        const newActivity = activity()

        const entity: BucketsAndCupsGroupGroupEntity = {
            ...newActivity,
            type: ActivityType.LaunchBucketsAndCups,
            ppt: ActivityType.LaunchBucketsAndCups,
            name: newActivity.id,
            buckets: [],
            bucketAndCupSelectionStep: [this.defaultSelectionStep()],
            poolVersionId: '',
            generateScoringTemplate: false,
            endOfModule: false,
            updatingCsvFile: true,
            timingEnabled: false,
        }
        return entity
    }

    private static update(payload: BucketsAndCupsGroupGroupEntity) {
        ActivityEntityService.store.dispatch({
            action: STORE_ACTION.REQUEST_UPDATE,
            entityId: payload.id,
            payload: payload,
        })
    }

    static toggleGenerateScoringTemplate(entityId: string) {
        const current = this.get(entityId)

        this.update({
            ...current,
            generateScoringTemplate: !current.generateScoringTemplate,
        })
    }

    static addSelectionStep(entityId: string) {
        const current = this.get(entityId)
        this.update({
            ...current,
            bucketAndCupSelectionStep: [
                ...current.bucketAndCupSelectionStep,
                this.defaultSelectionStep(),
            ],
        })
    }

    static addSelectionStepAtIndex(entityId: string, index: number) {
        const current = this.get(entityId)
        const steps = [...current.bucketAndCupSelectionStep]
        steps.splice(index + 1, 0, this.defaultSelectionStep())

        this.update({
            ...current,
            bucketAndCupSelectionStep: steps,
        })
    }

    static moveSelectionStepUp(entityId: string, index: number) {
        if (index === 0) return

        const current = this.get(entityId)
        const steps = [...current.bucketAndCupSelectionStep]

        // Swap current item with the one above it
        const temp = steps[index]
        steps[index] = steps[index - 1]
        steps[index - 1] = temp
        this.update({
            ...current,
            bucketAndCupSelectionStep: steps,
        })
    }

    static moveSelectionStepDown(entityId: string, index: number) {
        const current = this.get(entityId)
        if (index === current.bucketAndCupSelectionStep.length - 1) return
        const steps = [...current.bucketAndCupSelectionStep]

        // Swap current item with the one below it
        const temp = steps[index]
        steps[index] = steps[index + 1]
        steps[index + 1] = temp
        this.update({
            ...current,
            bucketAndCupSelectionStep: steps,
        })
    }

    static setItemPoolVersionId(entityId: string, poolVersionId: string) {
        const current = this.get(entityId)
        this.update({
            ...current,
            poolVersionId: poolVersionId,
        })
    }

    static setBuckets(entityId: string, buckets: BucketDTO[]) {
        const current = this.get(entityId)
        this.update({
            ...current,
            buckets: buckets,
            updatingCsvFile: false,
        })
    }

    static updateSelectionStep(
        entityId: string,
        index: number,
        bucketAndCupSelectionStepDTO: BucketAndCupSelectionStepDTO
    ) {
        const current = this.get(entityId)
        const timingEnabled = current.timingEnabled
        const rank0 = bucketAndCupSelectionStepDTO.bucketRank
        const cupSelectionRuleUnderSameRank =
            (rank0
                ? current.bucketAndCupSelectionStep.find(
                      (s, i) => i < index && s.bucketRank === rank0
                  )?.cupSelectionRule
                : undefined) ?? bucketAndCupSelectionStepDTO.cupSelectionRule
        this.update({
            ...current,
            bucketAndCupSelectionStep: current.bucketAndCupSelectionStep.map((b, i) => {
                if (i > index && b.bucketRank === rank0) {
                    return enforceBucketSelectionRuleValidation(
                        b,
                        timingEnabled,
                        cupSelectionRuleUnderSameRank
                    )
                } else if (i === index) {
                    return enforceBucketSelectionRuleValidation(
                        bucketAndCupSelectionStepDTO,
                        timingEnabled,
                        cupSelectionRuleUnderSameRank
                    )
                } else {
                    return b
                }
            }),
        })
    }

    static updatePagePatterns(
        entityId: string,
        index: number,
        pagePatterns: Change<PagePattern[] | undefined>
    ) {
        const current = this.get(entityId).bucketAndCupSelectionStep[index]
        this.updateSelectionStep(entityId, index, {
            ...current,
            pagePatterns: applyChange(current.pagePatterns, pagePatterns) ?? undefined,
        })
    }

    static deleteSelectionStep(entityId: string, index: number) {
        const current = this.get(entityId)
        const left = current.bucketAndCupSelectionStep.slice(0, index)
        const right = current.bucketAndCupSelectionStep.slice(index + 1)
        this.update({
            ...current,
            bucketAndCupSelectionStep: [...left, ...right],
        })
    }

    static setUpdatingCSV(entityId: string, updatingCsv: boolean) {
        const current = this.get(entityId)
        this.update({
            ...current,
            updatingCsvFile: updatingCsv,
        })
    }

    static setTimingEnabled(entityId: string, timingEnabled: boolean) {
        const current = this.get(entityId)
        this.update({
            ...current,
            timingEnabled,
            bucketAndCupSelectionStep: timingEnabled
                ? current.bucketAndCupSelectionStep.map((step) =>
                      enforceBucketSelectionRuleValidation(
                          {
                              ...step,
                              cupRank: 1,
                              numberOfItemsToPickFromCup: 1,
                          },
                          timingEnabled
                      )
                  )
                : current.bucketAndCupSelectionStep,
        })
    }

    private static defaultSelectionStep(): BucketAndCupSelectionStepDTO {
        return {
            bucketRank: 1,
            cupRank: 1,
            bucketSelectionRule: BucketSelectionRule.RANDOM,
            cupSelectionRule: CupSelectionRule.RANDOM,
            groupSize: 1,
            numberOfCupsToPickFromBucket: 1,
            numberOfItemsToPickFromCup: 1,
        }
    }
}
