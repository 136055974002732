import React, { useCallback, useEffect, useState } from 'react'

import { Button, ButtonVariant } from '@amzn/stencil-react-components/button'
import { ExpanderContent } from '@amzn/stencil-react-components/expander'
import { Input, InputFooter, Select } from '@amzn/stencil-react-components/form'
import { TriggerButton } from '@amzn/stencil-react-components/helpers'
import { IconAlertCircleFill, IconCheckCircleFill } from '@amzn/stencil-react-components/icons'
import { Col, Flex, Hr, Row, Spacer, View } from '@amzn/stencil-react-components/layout'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'
import { ModalContent, WithModal } from '@amzn/stencil-react-components/modal'
import { useBreakpoints } from '@amzn/stencil-react-components/responsive'
import { ScreenReaderOnly } from '@amzn/stencil-react-components/screen-reader-only'
import { Label, Text } from '@amzn/stencil-react-components/text'

import { ContextBoxOptions } from 'src/components/ContextBoxOptions'
import { SimpleToolbar } from 'src/components/MarkdownEditor'
import { MediaManager } from 'src/components/MediaContentsEditor/MediaManager'
import { isDefaultLocale } from 'src/contexts/ModuleLocaleContext'
import { Change } from 'src/hooks/DTOEditor'
import { useContextBoxEntity, useValidationErrorEntity } from 'src/hooks/useEntity'
import { ContextType, RequestType } from 'src/models/dto/ContextBoxDTO'
import { Medias } from 'src/models/dto/items/MediaDTO'
import { Locale } from 'src/models/dto/Locale'
import { GENERIC_ERROR_KEY } from 'src/models/dto/TemplateValidationError'
import { getListOfFileTypes, MediaFileGroups } from 'src/models/media/MediaTemplateDTO'
import { ItemEditorTextArea } from 'src/pages/module-builder/item-editors/ItemEditorInputs'
import { ErrorCheckService } from 'src/services/backend/ErrorCheckService'
import { ContextBoxEntityService } from 'src/services/EntityServices/ContextBoxEntityService'

const HALF = 0.5

const upperCaseFirstCharacterRestLowerCase = (option: string) => {
    return option.charAt(0) + option.substring(1, option.length).toLowerCase()
}

export function ChangeContextTypeModal({
    onClose,
    onDone,
    currentRequestType,
    nextRequestType,
}: {
    onClose: () => void
    onDone: () => void
    currentRequestType: RequestType
    nextRequestType: RequestType
}) {
    return (
        <ModalContent
            titleText='Change Context Type'
            buttons={[
                <Button
                    key='change-context-type-modal-submit'
                    dataTestId='delete-context-type-modal-submit'
                    onClick={() => {
                        onDone()
                        onClose()
                    }}
                    isDestructive
                >
                    Yes, change to {nextRequestType.toLowerCase()}
                </Button>,
                <Button
                    key='close-modal'
                    dataTestId='close-modal'
                    onClick={onClose}
                    variant={ButtonVariant.Primary}
                >
                    No, keep {currentRequestType.toLowerCase()}
                </Button>,
            ]}
        >
            <Col gridGap='S400'>
                <Text>
                    Changing the context type will remove the current content type data. This cannot
                    be undone.
                </Text>
            </Col>
        </ModalContent>
    )
}

export const ContextBoxEditor = ({
    contextBoxId,
    itemGroupId,
    workflowEntityId,
    pageIndex,
    locale,
    showValidationIssuesBanner,
    editDisabled,
}: {
    contextBoxId: string
    itemGroupId: string
    workflowEntityId: string
    pageIndex: number
    locale: Locale
    showValidationIssuesBanner: boolean
    editDisabled?: boolean
}) => {
    const { entity } = useContextBoxEntity(contextBoxId, itemGroupId)
    const [isExpanded, setIsExpanded] = useState(true)
    const triggerButtonName = `${isExpanded ? 'Collapse' : 'Expand'} request, page ${pageIndex + 1}`

    const [nextRequestType, setNextRequestType] = useState<RequestType>(
        ContextBoxEntityService.getRequestType(entity)
    )

    const { entity: validationErrorEntity } = useValidationErrorEntity(contextBoxId)

    const invalid = Object.entries(validationErrorEntity.validationErrors).length > 0

    const {
        matches: { s: small },
    } = useBreakpoints()

    const [isChanged, setIsChanged] = useState(false)
    useEffect(() => setIsChanged(true), [entity])

    const [showError, setShowError] = useState(showValidationIssuesBanner)

    useEffect(() => {
        setShowError(showValidationIssuesBanner ?? false)
    }, [showValidationIssuesBanner])

    const [previewUnexpectedError, setPreviewUnexpectedError] = useState<string | undefined>(
        undefined
    )

    const onErrorCheck = useCallback(() => {
        setIsChanged(false)
        setShowError(true)
    }, [])

    const renderModal = ({ close }: { close: () => void }) => (
        <ChangeContextTypeModal
            onClose={() => {
                close()
            }}
            onDone={() => {
                close()
                ContextBoxEntityService.setRequestType(contextBoxId, nextRequestType)
            }}
            nextRequestType={nextRequestType}
            currentRequestType={ContextBoxEntityService.getRequestType(entity)}
        />
    )

    return (
        <Col backgroundColor='white' padding='S300' dataTestId={'context-box'}>
            {previewUnexpectedError && (
                <MessageBanner
                    type={MessageBannerType.Error}
                    dismissButtonAltText={'Hide errors'}
                    dataTestId={'unexpected-error-banner'}
                    isDismissible={true}
                    onDismissed={() => setPreviewUnexpectedError(undefined)}
                >
                    {previewUnexpectedError}
                </MessageBanner>
            )}
            <Flex
                flexDirection={small ? 'column' : 'row'}
                gridGap={small ? 'S300' : 'S200'}
                justifyContent='space-between'
                width='100%'
            >
                {validationErrorEntity.validationErrors[GENERIC_ERROR_KEY] &&
                    showValidationIssuesBanner && (
                        <MessageBanner type={MessageBannerType.Error}>
                            <ul>
                                {validationErrorEntity.validationErrors[GENERIC_ERROR_KEY].map(
                                    (message, index) => (
                                        <li key={index}>{message}</li>
                                    )
                                )}
                            </ul>
                        </MessageBanner>
                    )}
                <Row alignItems='center' gridGap='S200'>
                    <TriggerButton
                        isExpanded={isExpanded}
                        onClick={() => setIsExpanded(!isExpanded)}
                        iconAltText={triggerButtonName}
                        aria-label={triggerButtonName}
                        id={`expand-context-box-${pageIndex}`}
                        dataTestId={'expand-context-box'}
                    />
                    <Text fontSize='T400' color={invalid && showError ? 'red70' : 'neutral90'}>
                        <h4>
                            <strong>Context Box Item</strong>
                        </h4>
                    </Text>
                </Row>
                {showError && (
                    <Row gridGap='S200' margin={small ? 'S200' : '0'}>
                        {invalid && showError && (
                            <Row
                                color={'red70'}
                                gridGap={'S100'}
                                alignItems={'center'}
                                dataTestId={'context-box-error-banner'}
                            >
                                <IconAlertCircleFill aria-hidden={true} />
                                {ErrorCheckService.getErrorCount(validationErrorEntity.id)} issues
                                <ScreenReaderOnly> for context box</ScreenReaderOnly>
                            </Row>
                        )}
                        {!invalid && showError && !isChanged && (
                            <Row color={'green70'} gridGap={'S100'} alignItems={'center'}>
                                <IconCheckCircleFill aria-hidden={true} />
                                No issues
                                <ScreenReaderOnly> for context box</ScreenReaderOnly>
                            </Row>
                        )}
                    </Row>
                )}
                <Row justifyContent='flex-end' gridGap='S200' width={small ? '100%' : undefined}>
                    <View flex='1 1'>
                        <ContextBoxOptions
                            contextBoxId={contextBoxId}
                            workflowEntityId={workflowEntityId}
                            onErrorCheck={onErrorCheck}
                            onPreviewUnexpectedError={() => {
                                setPreviewUnexpectedError(
                                    'An error has occurred while previewing the context box. Please try again later.'
                                )
                            }}
                            editDisabled={editDisabled}
                        />
                    </View>
                </Row>
            </Flex>
            <View>
                <ExpanderContent isExpanded={isExpanded}>
                    <Spacer height={'S400'} />
                    <Hr size='wide' color='neutral20' />
                    <Spacer height={'S400'} />
                    <Col gridGap='S100'>
                        <Row>
                            <Col gridGap='S100' flex={`${small ? 1 : HALF} 0 0`}>
                                <Label
                                    htmlFor={`context-type-select-${contextBoxId}`}
                                    id={`context-type-label-${contextBoxId}`}
                                >
                                    <Text fontSize='T100' color='neutral70'>
                                        Context type
                                    </Text>
                                </Label>
                                <WithModal renderModal={renderModal}>
                                    {({ open }) => (
                                        <>
                                            <Select
                                                id={`context-type-select-${contextBoxId}`}
                                                labelId={`context-type-label-${contextBoxId}`}
                                                dataTestId={'context-type-select'}
                                                options={Object.keys(RequestType)}
                                                renderOption={upperCaseFirstCharacterRestLowerCase}
                                                renderSelectedValue={
                                                    upperCaseFirstCharacterRestLowerCase
                                                }
                                                renderNativeOption={
                                                    upperCaseFirstCharacterRestLowerCase
                                                }
                                                value={ContextBoxEntityService.getRequestType(
                                                    entity
                                                )}
                                                onChange={(requestType: RequestType) => {
                                                    if (
                                                        ContextBoxEntityService.hasChangedFromDefault(
                                                            contextBoxId
                                                        )
                                                    ) {
                                                        open()
                                                        setNextRequestType(requestType)
                                                    } else {
                                                        ContextBoxEntityService.setRequestType(
                                                            contextBoxId,
                                                            requestType
                                                        )
                                                    }
                                                }}
                                                disabled={!isDefaultLocale(locale) || editDisabled}
                                                aria-describedby={'context-type-warning-footer'}
                                            />
                                            {entity.contextType === ContextType.AUDIO && (
                                                <InputFooter
                                                    id={'context-type-warning-footer'}
                                                    warning
                                                >
                                                    Preview for Context Box of Audio type is
                                                    currently not supported
                                                </InputFooter>
                                            )}
                                        </>
                                    )}
                                </WithModal>
                            </Col>
                        </Row>
                        {entity.contextType !== ContextType.AUDIO && (
                            <>
                                <Spacer height={'S300'} />
                                <ItemEditorTextArea
                                    key={ContextBoxEntityService.getRequestType(entity)}
                                    toolbarControls={SimpleToolbar}
                                    inputId={`context-box-text-${contextBoxId}`}
                                    dataTestId={'context-box-text-editor'}
                                    value={entity.contentI18N[locale] ?? ''}
                                    itemId={contextBoxId}
                                    locale={locale}
                                    setValue={(value) => {
                                        ContextBoxEntityService.setContent(
                                            contextBoxId,
                                            locale,
                                            value
                                        )
                                    }}
                                    validationErrorMessage={(
                                        validationErrorEntity.validationErrors.contentI18N ?? []
                                    ).join(', ')}
                                    showError={showError}
                                    disabled={editDisabled}
                                    labelText={'Text'}
                                />
                            </>
                        )}
                        {entity.contextType === ContextType.AUDIO && (
                            <>
                                <Spacer height={'S200'} />
                                <MediaManager
                                    itemId={contextBoxId}
                                    locale={Locale.en_US}
                                    setLocaleWiseMediaOpt={(change: Change<Medias | undefined>) => {
                                        ContextBoxEntityService.setAudio(contextBoxId, change)
                                    }}
                                    localeWiseMediaOpt={entity.localeWiseMedia}
                                    disableMediaExpander={true}
                                    maxNumberOfMedia={1}
                                    buttonText={'Add an audio clip'}
                                    buttonVariant={ButtonVariant.Secondary}
                                    validationErrorMessage={(
                                        validationErrorEntity.validationErrors.localeWiseMedia ?? []
                                    ).join(', ')}
                                    showError={showError}
                                    lockedFileTypes={getListOfFileTypes(MediaFileGroups.AUDIO)}
                                    disabled={editDisabled}
                                />
                                <Label
                                    htmlFor={`max-plays-${pageIndex}`}
                                    id={`max-plays-${pageIndex}-label`}
                                >
                                    <Text fontSize='T100' color='neutral70'>
                                        Max play count (optional)
                                    </Text>
                                </Label>
                                <Input
                                    placeholder={'E.g. 3'}
                                    dataTestId={'max-play-count-input'}
                                    id={`max-plays-${pageIndex}`}
                                    value={ContextBoxEntityService.get(contextBoxId).maxPlays}
                                    type='number'
                                    onChange={(maxPlays) => {
                                        let next =
                                            maxPlays.target.value.length === 0
                                                ? undefined
                                                : +maxPlays.target.value

                                        if (next) {
                                            next = next < 0 ? 0 : next
                                        }

                                        ContextBoxEntityService.setMaxPlays(contextBoxId, next)
                                    }}
                                    disabled={!isDefaultLocale(locale) || editDisabled}
                                />
                            </>
                        )}
                    </Col>
                </ExpanderContent>
            </View>
        </Col>
    )
}
