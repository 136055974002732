import React, { useEffect, useState } from 'react'

import { TimerStopwatch } from '@amzn/katal-metrics/lib/metricObject'
import { Input, InputWrapper, Select } from '@amzn/stencil-react-components/form'
import { Row, Spacer, View } from '@amzn/stencil-react-components/layout'
import { RichTextEditor } from '@amzn/stencil-react-components/rte'
import { TableColumn } from '@amzn/stencil-react-components/table'

import { MastheadContainer } from 'src/components/MastheadContainer'
import {
    CommonUserEventKeys,
    publishTimeSpentMetrics,
    publishUserEventMetrics,
    UserEventMethodNames,
} from 'src/metrics'
import { ItemPoolGenerator } from 'src/pages/module-builder/item-pool-editor/ItemPoolGenerator'

export enum AllowedItemPoolType {
    RankingItem = 'Ranking Item',
    SlotPickerItem = 'Item Placement Item',
    MCQ = 'Multi Choice Item',
}

export interface PoolResults {
    versionId: string
    size: number
    type: string
}

const MIN_ITEM_POOL_SIZE = 50

const MAX_ITEM_POOL_SIZE = 10000

export function poolSizeError(poolSize: number) {
    return poolSize < MIN_ITEM_POOL_SIZE || poolSize > MAX_ITEM_POOL_SIZE
}

export type GetAdditionalTableCells = () => TableColumn<PoolResults, { versionId: string }>[]
export const ItemPoolGeneratorContext = React.createContext<{
    getAdditionalTableCells?: GetAdditionalTableCells
}>({})

const pageTimer = new TimerStopwatch('ItemPoolEditorPageTimer')

export const ItemPoolEditor = () => {
    const [itemType, setItemType] = useState<AllowedItemPoolType>(
        AllowedItemPoolType.SlotPickerItem
    )

    const [itemGenerationConfig, setItemGenerationConfig] = useState<string>('default')

    const [size, setSize] = useState<number>(MIN_ITEM_POOL_SIZE)

    useEffect(() => {
        pageTimer.start()
        publishUserEventMetrics(UserEventMethodNames.ItemPoolEditor, CommonUserEventKeys.Open)

        return () => {
            pageTimer.stop()
            publishTimeSpentMetrics(UserEventMethodNames.ItemPoolEditor, pageTimer.value)
        }
    }, [])

    return (
        <MastheadContainer>
            <View>
                <Row gridGap='S300' alignItems={'flex-start'} flexWrap={'wrap'}>
                    <InputWrapper labelText='Select Item type' id='item-pool-item-type-select'>
                        {(props) => (
                            <Select
                                options={Object.values(AllowedItemPoolType)}
                                placeholder='Select one'
                                dataTestId={'item-pool-type'}
                                {...props}
                                value={itemType}
                                onChange={setItemType}
                            />
                        )}
                    </InputWrapper>
                    <InputWrapper
                        id='item-pool-size'
                        labelText='Item pool size'
                        tooltipText='Specify the item pool size. It can range between 50 and 10000'
                        required={true}
                        footer={undefined}
                    >
                        {(props) => (
                            <Input
                                type='number'
                                value={size}
                                dataTestId={'item-pool-generator-size'}
                                onChange={(e) => {
                                    setSize(parseInt(e.target.value))
                                }}
                                min={5}
                                max={MAX_ITEM_POOL_SIZE}
                                width='100%'
                                placeholder='Enter Item pool size'
                                {...props}
                            />
                        )}
                    </InputWrapper>
                </Row>
                <Spacer height='S400' />
                <Row gridGap='S300' alignItems={'flex-start'} flexWrap={'wrap'}>
                    <RichTextEditor
                        defaultContent='default'
                        defaultHeight={500}
                        hasWordCounter={true}
                        placeholder='Write something here'
                        recommendedWordCount={200}
                        onChange={(editor) => {
                            setItemGenerationConfig(editor.getText())
                        }}
                    />
                </Row>
                <Spacer height='S400' />
                <ItemPoolGenerator
                    itemType={itemType}
                    size={size}
                    itemGenerationInput={itemGenerationConfig}
                    hrefFunction={(r) => URL.createObjectURL(r)}
                />
            </View>
        </MastheadContainer>
    )
}
