import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { Button, ButtonVariant } from '@amzn/stencil-react-components/button'
import { Card } from '@amzn/stencil-react-components/card'
import { Input, InputWrapper, Select } from '@amzn/stencil-react-components/form'
import { Col, Row, Spacer } from '@amzn/stencil-react-components/layout'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'
import { ScreenReaderOnly } from '@amzn/stencil-react-components/screen-reader-only'
import { Spinner } from '@amzn/stencil-react-components/spinner'
import { H3, Text } from '@amzn/stencil-react-components/text'

import { RawMediaView } from 'src/components/media/RawMediaView'
import { TagsInput } from 'src/components/media/TagsInput'
import { UpdateFields, UseMediaButton } from 'src/components/media/UseMediaButton'
import { Locale } from 'src/models/dto/Locale'
import { S3FileState } from 'src/services/media/LocalMediaFilesService'
import { UserMediaInfo } from 'src/services/media/UserMediaService'

export interface MediaEntryProps {
    userMediaInfo: UserMediaInfo
    onAlternativeTextChange?: (id: number, newValue: string) => void
    onMediaLabelChange?: (id: number, newValue: string) => void
    onLocaleChange?: (id: number, newValue: string) => void
    onTagsChange: (id: number, newValue: string[]) => void
    onSelectedChange?: (id: number, newValue: boolean) => void
    onUploadClicked?: (id: number) => Promise<S3FileState>
    isModuleBuilder?: boolean
    updateFields?: UpdateFields
}

// CSV export has been commented out

export const MediaEntry = (props: MediaEntryProps) => {
    // const [isSelected, setIsSelected] = useState(props.userMediaInfo.selected)
    const isSelected = false
    const [s3State, setS3State] = useState(props.userMediaInfo.s3State)
    const [alternativeTextError, setAlternativeTextError] = useState('')
    const [mediaLabelError, setMediaLabelError] = useState('')
    const [localeError, setLocaleError] = useState('')
    const [uploadError, setUploadError] = useState('')
    // const [readyExport, setReadyExport] = useState(false)
    const [tags, setTags] = useState<string[]>(props.userMediaInfo.assessmentMedia.tags || [])

    const unselectedColor = '#eef5f6'
    // const selectedColor = '#d4e4e6'

    const {
        onAlternativeTextChange,
        onMediaLabelChange,
        onLocaleChange,
        onTagsChange,
        // onSelectedChange,
        onUploadClicked,
    } = props
    const userMediaInfoId = props.userMediaInfo.id

    const internalOnAlternativeTextChange = useCallback(
        (newValue: string) => {
            if (onAlternativeTextChange) {
                try {
                    onAlternativeTextChange(userMediaInfoId, newValue)
                } catch (e: unknown) {
                    setAlternativeTextError((e as Error).message)
                    //setReadyExport(false)
                    return
                }
            }
            setAlternativeTextError('')
        },
        [onAlternativeTextChange, userMediaInfoId]
    )

    const internalOnMediaLabelChange = useCallback(
        (newValue: string) => {
            if (onMediaLabelChange) {
                try {
                    onMediaLabelChange(userMediaInfoId, newValue)
                } catch (e: unknown) {
                    setMediaLabelError((e as Error).message)
                    //setReadyExport(false)
                }
            }
            setMediaLabelError('')
        },
        [onMediaLabelChange, userMediaInfoId]
    )

    const internalOnLocaleChange = useCallback(
        (newValue: string) => {
            if (onLocaleChange) {
                try {
                    onLocaleChange(userMediaInfoId, newValue)
                } catch (e: unknown) {
                    setLocaleError((e as Error).message)
                    //setReadyExport(false)
                    return
                }
            }
            setLocaleError('')
        },
        [onLocaleChange, userMediaInfoId]
    )

    const internalOnTagsChange = useCallback(
        (newValue: string[]) => {
            onTagsChange?.(userMediaInfoId, newValue)
        },
        [onTagsChange, userMediaInfoId]
    )

    // useEffect(() => {
    //     if (!mediaLabelError && !alternativeTextError && !localeError) {
    //         setReadyExport(true)
    //     }
    // }, [alternativeTextError, mediaLabelError, localeError])

    // const internalOnSelectedChange = useCallback(
    //     (newValue: boolean) => {
    //         if (s3State !== S3FileState.Uploaded) {
    //             return
    //         }
    //         onSelectedChange?.(userMediaInfoId, newValue)
    //         setIsSelected(newValue)
    //     },
    //     [onSelectedChange, s3State, userMediaInfoId]
    // )

    const internalOnUploadClicked = useCallback(async () => {
        if (!onUploadClicked) {
            return
        }
        try {
            setS3State(S3FileState.Uploading)
            setS3State(await onUploadClicked(userMediaInfoId))
            setUploadError('')
        } catch (e: unknown) {
            console.error('Upload error', e)
            setS3State(S3FileState.NotUploaded)
            setUploadError((e as Error).message)
        }
    }, [onUploadClicked, userMediaInfoId])

    function renderDescription() {
        let description = ''

        if (props.userMediaInfo.assessmentMedia.mediaFile) {
            description += props.userMediaInfo.assessmentMedia.mediaFile
        }
        if (props.userMediaInfo.assessmentMedia.relativePath) {
            description +=
                (description ? ' - ' : '') + props.userMediaInfo.assessmentMedia.relativePath
        }
        if (props.userMediaInfo.assessmentMedia.mimeType) {
            description += (description ? ' - ' : '') + props.userMediaInfo.assessmentMedia.mimeType
        }
        return description
    }

    const renderUploadButton = () => {
        if (s3State === S3FileState.NotUploaded) {
            return (
                <Button variant={ButtonVariant.Primary} onClick={internalOnUploadClicked}>
                    Upload to S3
                </Button>
            )
        } else if (s3State === S3FileState.Uploading) {
            return <Spinner loadingText='Uploading media to S3...' />
        } else {
            return (
                // When "Upload to S3" is finished, focus on this button
                <UseMediaButton
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus
                    isModuleBuilder={props.isModuleBuilder}
                    updateFields={props.updateFields}
                    media={props.userMediaInfo.assessmentMedia}
                />
            )
        }
    }

    // do validation after the first render
    const tagsAsString = tags.join('\t')
    const assessmentMedia = props.userMediaInfo.assessmentMedia
    useEffect(() => {
        internalOnAlternativeTextChange(assessmentMedia.alternativeText)
        internalOnMediaLabelChange(assessmentMedia.label)
        internalOnLocaleChange(assessmentMedia.locale)
        internalOnTagsChange(tagsAsString.split('\t'))
    }, [
        assessmentMedia,
        tagsAsString,
        internalOnAlternativeTextChange,
        internalOnMediaLabelChange,
        internalOnLocaleChange,
        internalOnTagsChange,
    ])

    const localeValues = useMemo(() => Object.values(Locale), [])

    const desc = renderDescription()
    return (
        <Col gridGap='10px' flex={1} role='group' aria-label={`Media entry: ${desc}`}>
            <Card
                backgroundColor={unselectedColor}
                // backgroundColor={isSelected ? selectedColor : unselectedColor}
            >
                <Col flex={1} gridGap='20px'>
                    <H3>
                        <ScreenReaderOnly>{desc}</ScreenReaderOnly>
                    </H3>
                    {uploadError && (
                        <Row justifyContent='center'>
                            <MessageBanner type={MessageBannerType.Error}>
                                {uploadError}
                            </MessageBanner>
                        </Row>
                    )}
                    <Row flex={1} gridGap='20px'>
                        <Col flex={1} justifyContent='center'>
                            <Text textAlign='right' fontWeight='bold'>
                                {desc}
                            </Text>
                            <Spacer />
                            <RawMediaView
                                url={props.userMediaInfo.url}
                                mimeType={props.userMediaInfo.assessmentMedia.mimeType}
                                controls
                            />
                        </Col>
                        <Col flex={1} gridGap='10px'>
                            <InputWrapper
                                id={`${props.userMediaInfo.id}-filename`}
                                labelText='Filename'
                            >
                                {() => (
                                    <Input
                                        id={`${props.userMediaInfo.id}-filename`}
                                        type='text'
                                        defaultValue={props.userMediaInfo.assessmentMedia.mediaFile}
                                        disabled={true}
                                    />
                                )}
                            </InputWrapper>
                            <InputWrapper
                                id={`${props.userMediaInfo.id}-author`}
                                labelText='Author'
                            >
                                {() => (
                                    <Input
                                        id={`${props.userMediaInfo.id}-author`}
                                        type='text'
                                        defaultValue={props.userMediaInfo.assessmentMedia.author}
                                        disabled={true}
                                    />
                                )}
                            </InputWrapper>
                            <InputWrapper
                                id={`${props.userMediaInfo.id}-fileType`}
                                labelText='File Type'
                            >
                                {() => (
                                    <Input
                                        id={`${props.userMediaInfo.id}-fileType`}
                                        type='text'
                                        defaultValue={props.userMediaInfo.assessmentMedia.mimeType}
                                        disabled={true}
                                    />
                                )}
                            </InputWrapper>
                            <InputWrapper id={`${props.userMediaInfo.id}-date`} labelText='Date'>
                                {() => (
                                    <Input
                                        id={`${props.userMediaInfo.id}-date`}
                                        type='text'
                                        defaultValue={props.userMediaInfo.assessmentMedia.date}
                                        disabled={true}
                                    />
                                )}
                            </InputWrapper>
                        </Col>
                        <Col flex={1} gridGap='10px'>
                            {!props.isModuleBuilder && (
                                <>
                                    <InputWrapper
                                        id={`${props.userMediaInfo.id}-alt-text`}
                                        labelText='Descriptive Alternative Text (Accessibility)'
                                        error={alternativeTextError.length !== 0}
                                        footer={alternativeTextError}
                                    >
                                        {() => (
                                            <Input
                                                id={`${props.userMediaInfo.id}-alt-text`}
                                                type='text'
                                                defaultValue={
                                                    props.userMediaInfo.assessmentMedia
                                                        .alternativeText
                                                }
                                                onChange={(e) =>
                                                    internalOnAlternativeTextChange(e.target.value)
                                                }
                                                disabled={isSelected}
                                                error={alternativeTextError.length !== 0}
                                            />
                                        )}
                                    </InputWrapper>
                                    <InputWrapper
                                        id={`${props.userMediaInfo.id}-media-label`}
                                        labelText='Media Label'
                                        error={mediaLabelError.length !== 0}
                                        footer={mediaLabelError}
                                    >
                                        {() => (
                                            <Input
                                                id={`${props.userMediaInfo.id}-media-label`}
                                                type='text'
                                                defaultValue={
                                                    props.userMediaInfo.assessmentMedia.label
                                                }
                                                onChange={(e) =>
                                                    internalOnMediaLabelChange(e.target.value)
                                                }
                                                disabled={isSelected}
                                                error={mediaLabelError.length !== 0}
                                            />
                                        )}
                                    </InputWrapper>
                                </>
                            )}
                            <InputWrapper
                                id={`${props.userMediaInfo.id}-locale`}
                                labelText='Locale'
                                error={localeError.length !== 0}
                                footer={localeError}
                            >
                                {() => (
                                    <Select
                                        id={`${props.userMediaInfo.id}-locale`}
                                        defaultValue={props.userMediaInfo.assessmentMedia.locale}
                                        onChange={(e: unknown) =>
                                            internalOnLocaleChange(e as string)
                                        }
                                        disabled={isSelected}
                                        error={localeError.length !== 0}
                                        options={localeValues}
                                    />
                                )}
                            </InputWrapper>
                            <TagsInput
                                id={`${props.userMediaInfo.id}-tags`}
                                tags={tags}
                                setTags={setTags}
                                disabled={s3State === S3FileState.Uploaded}
                            />
                        </Col>
                    </Row>
                    <Row gridGap='S200' alignItems='center'>
                        {/*<Checkbox
                            name={`${props.userMediaInfo.id}-media-selected`}
                            id={`${props.userMediaInfo.id}-media-selected`}
                            checked={isSelected}
                            disabled={s3State !== S3FileState.Uploaded || !readyExport}
                            onChange={(e) => internalOnSelectedChange(e.target.checked)}
                        />
                        <Label htmlFor={`${props.userMediaInfo.id}-media-selected`}>Select</Label>
                        <Spacer flex={1} />
                        */}
                        {renderUploadButton()}
                    </Row>
                </Col>
            </Card>
        </Col>
    )
}
