import React, { SetStateAction, useMemo, useState } from 'react'

import { Button, ButtonVariant } from '@amzn/stencil-react-components/button'
import { Card } from '@amzn/stencil-react-components/card'
import { Checkbox, Input, InputWrapper } from '@amzn/stencil-react-components/form'
import { Col, Row, Spacer, View } from '@amzn/stencil-react-components/layout'
import { Modal } from '@amzn/stencil-react-components/modal'
import { Label, Text } from '@amzn/stencil-react-components/text'

import { StateAndCallback } from 'src/hooks/DTOEditor'
import { useObjectEditor } from 'src/hooks/DTOEditor/objectEditor'
import { parseFromRegex, sampleChoices } from 'src/pages/GraphConstructionEditor/Choice'
import { ItemCodeGenerationData } from 'src/pages/GraphConstructionEditor/index'

function NodeWeightEditor({ value, onChange }: StateAndCallback<number | undefined>) {
    return (
        <InputWrapper
            id='node-editor-weight'
            labelText='Weight'
            footer={'Relative chance of this alternative being taken among other options'}
        >
            {(props) => (
                <Input
                    {...props}
                    dataTestId='node-editor-weight'
                    value={value ?? 1}
                    onChange={(e) => {
                        onChange(parseFloat(e.target?.value ?? '1') || 1)
                    }}
                    type='number'
                    min={0.1}
                    step={0.1}
                />
            )}
        </InputWrapper>
    )
}

function NodeChoicesEditor({
    value,
    onChange,
}: StateAndCallback<ItemCodeGenerationData['regex'] | undefined>) {
    return (
        <InputWrapper
            id='node-editor-choices'
            labelText='Choices'
            footer={
                'Comma-separated list of choices. Each choice can be a range of characters (A-Z, 0-9)' +
                ' or a set of characters (ABC, 123)'
            }
        >
            {(props) => (
                <Input
                    {...props}
                    dataTestId='node-editor-choices'
                    type='text'
                    defaultValue={(value ?? []).join(', ')}
                    onChange={(e) => {
                        const v = e?.target?.value ?? ''
                        onChange(v.split(/[,\s]+/))
                    }}
                />
            )}
        </InputWrapper>
    )
}

function NodePickEditor({
    value = -1,
    onChange,
}: StateAndCallback<ItemCodeGenerationData['pick']>) {
    return (
        <InputWrapper id='node-editor-pick' labelText='Pick (-1 for all choices)'>
            {(props) => (
                <Input
                    {...props}
                    type='number'
                    dataTestId='node-editor-pick'
                    value={value}
                    onChange={(e) => {
                        const v = e.target?.value ?? '1'
                        onChange(parseInt(v, 10) || 1)
                    }}
                    min={-1}
                    max={10}
                />
            )}
        </InputWrapper>
    )
}

function NodeShuffleEditor({
    value = false,
    onChange,
}: StateAndCallback<ItemCodeGenerationData['shuffle']>) {
    return (
        <Row gridGap={10} id='node-editor-shuffle' alignItems='center'>
            <Spacer flex={1} />
            <Checkbox
                id='node-editor-shuffle-checkbox'
                data-test-id='node-editor-shuffle-checkbox'
                checked={value}
                onChange={(e) => {
                    onChange(e.target.checked)
                }}
            />
            <Label htmlFor='node-editor-shuffle-checkbox'>Shuffle choices before picking</Label>
        </Row>
    )
}

interface NodeEditorProps {
    initialData?: ItemCodeGenerationData
    setData: (change: SetStateAction<ItemCodeGenerationData>) => void
    isOpen: boolean
    isNode: boolean
    hideWeight?: boolean
    close: () => void
}

const fields = ['weight', 'pick', 'shuffle', 'regex'] as const

export function NodeDataEditor({
    isOpen,
    isNode,
    hideWeight = false,
    initialData,
    close,
    setData,
}: NodeEditorProps) {
    const [storedData, setStoredData] = useState<ItemCodeGenerationData>(initialData ?? {})
    const getPropsForField = useObjectEditor<ItemCodeGenerationData>(
        { value: storedData, onChange: setStoredData },
        fields
    )
    const examples = useMemo(() => {
        const set = new Set<string>()
        // eslint-disable-next-line no-magic-numbers
        for (let i = 0; i < 10; i++) {
            set.add(
                sampleChoices({
                    choices: (storedData.regex || []).map(parseFromRegex),
                    pick: storedData.pick,
                    shuffle: storedData.shuffle,
                })
            )
        }

        return Array.from(set)
    }, [storedData])

    return (
        <Modal close={close} isOpen={isOpen} isScrollable shouldCloseOnClickOutside={false}>
            <Card dataTestId='node-editor'>
                <Col>
                    {!hideWeight && <NodeWeightEditor {...getPropsForField.weight} />}
                    {isNode && (
                        <>
                            <NodeChoicesEditor {...getPropsForField.regex} />
                            <NodePickEditor {...getPropsForField.pick} />
                            <Spacer height={10} />
                            <NodeShuffleEditor {...getPropsForField.shuffle} />
                            <InputWrapper id='node-editor-examples' labelText='Examples'>
                                {() => (
                                    <View padding='S200'>
                                        <Text style={{ fontFamily: 'monospace' }}>
                                            {examples.join('\t')}
                                        </Text>
                                    </View>
                                )}
                            </InputWrapper>
                        </>
                    )}
                    <Row gridGap={10}>
                        <Button
                            dataTestId='node-editor-update'
                            variant={ButtonVariant.Primary}
                            onClick={() => setData(storedData)}
                        >
                            Update
                        </Button>
                        <Button
                            dataTestId='node-editor-cancel'
                            variant={ButtonVariant.Secondary}
                            onClick={() => close()}
                        >
                            Cancel
                        </Button>
                    </Row>
                </Col>
            </Card>
        </Modal>
    )
}
