import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react'

import { TimerStopwatch } from '@amzn/katal-metrics/lib/metricObject'
import { Button, ButtonSize } from '@amzn/stencil-react-components/button'
import { Col, View } from '@amzn/stencil-react-components/layout'
import { ProgressBar, ProgressBarStatus } from '@amzn/stencil-react-components/progress-bar'
import { Status, StatusIndicator } from '@amzn/stencil-react-components/status-indicator'

import {
    DEPLOY_API_REFRESH_INTERVAL,
    MODULE_PUBLISHING_TIMEOUT,
} from 'src/components/moduleDeployment/ModuleDeploymentProgressConfig'
import { publishUserEventMetrics, UserEventMethodNames } from 'src/metrics'
import {
    ModuleDeploymentStatus,
    ModuleDeploymentTargetStage,
} from 'src/models/dto/ModuleDeployment'
import { ModuleService } from 'src/services/backend/ModuleService'

export interface ModuleDeploymentProgressProps {
    moduleVersionId: string
    isInProgress: boolean
    targetStage: ModuleDeploymentTargetStage
    author: string
    comments?: string
    setDeployedStatus?: (value: boolean) => void
    moduleGroup?: boolean
}

export enum DeploymentProgressStatus {
    READY = 'READY',
    IN_PROGRESS = 'IN_PROGRESS',
    SUCCEED = 'SUCCEED',
    FAILED = 'FAILED',
    ALREADY_DEPLOYED = 'ALREADY_DEPLOYED',
    FAILED_BEFORE = 'FAILED_BEFORE',
}

enum ErrorTypes {
    TIMEOUT = 'TIMEOUT',
    AUTHORING_SERVICE_ERROR = 'AUTHORING_SERVICE_ERROR',
    DELIVERY_ERROR = 'DELIVERY_ERROR',
}

const MapStageToKatalEventType: Record<ModuleDeploymentTargetStage, UserEventMethodNames> = {
    PREVIEW: UserEventMethodNames.DeployModuleVersionPreview,
    PRODUCTION: UserEventMethodNames.DeployModuleVersionUAT,
    UAT: UserEventMethodNames.DeployModuleVersionProduction,
}

interface DeploymentProgressStatusProps {
    desc: string
    status: Status
    progressBarStatus: ProgressBarStatus
    progressStep: number
}

const MAXIMUM_PROGRESS = 3 + MODULE_PUBLISHING_TIMEOUT
export const STATUS_STRING_MAP: Record<DeploymentProgressStatus, DeploymentProgressStatusProps> = {
    [DeploymentProgressStatus.READY]: {
        desc: 'Checking the deployment status of the module',
        status: Status.Waiting,
        progressBarStatus: ProgressBarStatus.Loading,
        progressStep: 1,
    },
    [DeploymentProgressStatus.IN_PROGRESS]: {
        desc: 'Module deployment is in progress',
        status: Status.Waiting,
        progressBarStatus: ProgressBarStatus.Loading,
        progressStep: 2,
    },
    [DeploymentProgressStatus.SUCCEED]: {
        desc: 'Succeed to deploy',
        status: Status.Positive,
        progressBarStatus: ProgressBarStatus.Positive,
        progressStep: MAXIMUM_PROGRESS,
    },
    [DeploymentProgressStatus.FAILED]: {
        desc: 'Failed to deploy',
        status: Status.Negative,
        progressBarStatus: ProgressBarStatus.Negative,
        progressStep: MAXIMUM_PROGRESS,
    },
    [DeploymentProgressStatus.ALREADY_DEPLOYED]: {
        desc: 'This module has already been deployed',
        status: Status.Positive,
        progressBarStatus: ProgressBarStatus.Positive,
        progressStep: MAXIMUM_PROGRESS,
    },
    [DeploymentProgressStatus.FAILED_BEFORE]: {
        desc: 'This module failed to deploy previously, do you want to retry?',
        status: Status.Negative,
        progressBarStatus: ProgressBarStatus.Negative,
        progressStep: MAXIMUM_PROGRESS,
    },
}

const moduleDeploymentProgressTimer = new TimerStopwatch('moduleDeploymentProgressTimer')
const sendPublishingResultMetric = (eventType: UserEventMethodNames, errorType?: ErrorTypes) => {
    moduleDeploymentProgressTimer.stop()
    publishUserEventMetrics(eventType, 'processingTime', moduleDeploymentProgressTimer.value)

    if (errorType) {
        publishUserEventMetrics(eventType, 'error')
        publishUserEventMetrics(eventType, errorType.toString())
    }
}

export interface DeploymentProgressProps {
    isInProgress: boolean
    targetStage: ModuleDeploymentTargetStage
    setDeployedStatus?: (value: boolean) => void
    errorMessage: string | undefined
    setErrorMessage: (value: string | undefined) => void
    setIsRetry: (value: boolean) => void
    handleError: (error: Error, errorCode: ErrorTypes) => void
    setDeploymentProgressStatus: (value: DeploymentProgressStatus) => void
    deploymentProgressStatus: DeploymentProgressStatus
    reloadDeployModule: () => void
    setModuleDeploymentContext: (value: ModuleDeploymentContext) => void
    moduleDeploymentContext: ModuleDeploymentContext
}

export function DeploymentProgress(props: DeploymentProgressProps) {
    const refreshStatusTimerId = useRef<number | undefined>(undefined)
    const {
        isInProgress,
        targetStage,
        setDeployedStatus,
        errorMessage,
        setErrorMessage,
        setIsRetry,
        handleError,
        reloadDeployModule,
        moduleDeploymentContext,
        setModuleDeploymentContext,
        deploymentProgressStatus,
        setDeploymentProgressStatus,
    } = props

    const setReloadTimer = useCallback(() => {
        if (!refreshStatusTimerId.current) {
            refreshStatusTimerId.current = window.setTimeout(() => {
                refreshStatusTimerId.current = undefined
                reloadDeployModule()
            }, DEPLOY_API_REFRESH_INTERVAL)
        }
    }, [reloadDeployModule])

    const clearReloadTimer = () => {
        if (refreshStatusTimerId.current) {
            clearTimeout(refreshStatusTimerId.current)
            refreshStatusTimerId.current = undefined
        }
    }

    useEffect(() => {
        return clearReloadTimer
    }, [])

    useEffect(() => {
        if (moduleDeploymentContext.attempts === 0) {
            moduleDeploymentProgressTimer.start()
        } else if (moduleDeploymentContext.attempts === 1) {
            if (
                moduleDeploymentContext.lastStatus === ModuleDeploymentStatus.SUCCESSFULLY_PUBLISHED
            ) {
                setDeploymentProgressStatus(DeploymentProgressStatus.ALREADY_DEPLOYED)
                sendPublishingResultMetric(MapStageToKatalEventType[targetStage])
                if (setDeployedStatus) {
                    setDeployedStatus(true)
                }
            } else if (
                moduleDeploymentContext.lastStatus === ModuleDeploymentStatus.FAILED_TO_PUBLISH
            ) {
                setDeploymentProgressStatus(DeploymentProgressStatus.FAILED_BEFORE)
                sendPublishingResultMetric(MapStageToKatalEventType[targetStage])
            } else {
                setDeploymentProgressStatus(DeploymentProgressStatus.IN_PROGRESS)
                setReloadTimer()
            }
        } else if (
            moduleDeploymentContext.attempts > MODULE_PUBLISHING_TIMEOUT ||
            moduleDeploymentContext.lastStatus === ModuleDeploymentStatus.TIMED_OUT
        ) {
            handleError(new Error('Timeout'), ErrorTypes.TIMEOUT)
        } else if (moduleDeploymentContext.attempts > 1) {
            if (
                moduleDeploymentContext.lastStatus === ModuleDeploymentStatus.SUCCESSFULLY_PUBLISHED
            ) {
                setDeploymentProgressStatus(DeploymentProgressStatus.SUCCEED)
                if (setDeployedStatus) {
                    setDeployedStatus(true)
                }
                sendPublishingResultMetric(MapStageToKatalEventType[targetStage])
            } else if (
                moduleDeploymentContext.lastStatus === ModuleDeploymentStatus.FAILED_TO_PUBLISH
            ) {
                handleError(new Error('Delivery processing failure'), ErrorTypes.DELIVERY_ERROR)
            } else {
                setReloadTimer()
            }
        }
    }, [
        moduleDeploymentContext,
        handleError,
        targetStage,
        setDeployedStatus,
        setDeploymentProgressStatus,
        setReloadTimer,
    ])

    if (!isInProgress) {
        return null
    }

    const retry = () => {
        clearReloadTimer()
        setModuleDeploymentContext({
            lastStatus: undefined,
            attempts: 0,
        })
        setIsRetry(true)
        setErrorMessage(undefined)
        setDeploymentProgressStatus(DeploymentProgressStatus.READY)
        reloadDeployModule()
    }

    return (
        <View backgroundColor='neutral0'>
            <Col gridGap='S100' backgroundColor='neutral0'>
                <StatusIndicator
                    id='push-module-status-indicator'
                    messageText={errorMessage ?? STATUS_STRING_MAP[deploymentProgressStatus].desc}
                    status={STATUS_STRING_MAP[deploymentProgressStatus].status}
                />

                <ProgressBar
                    progress={Math.min(
                        (STATUS_STRING_MAP[deploymentProgressStatus].progressStep +
                            moduleDeploymentContext.attempts) /
                            MAXIMUM_PROGRESS,

                        1.0
                    )}
                    status={STATUS_STRING_MAP[deploymentProgressStatus].progressBarStatus}
                    data-test-id='push-module-progress-bar'
                />

                {(deploymentProgressStatus === DeploymentProgressStatus.FAILED ||
                    deploymentProgressStatus === DeploymentProgressStatus.FAILED_BEFORE) && (
                    <Button size={ButtonSize.Small} onClick={retry}>
                        {' '}
                        Retry
                    </Button>
                )}
            </Col>
        </View>
    )
}

interface ModuleDeploymentContext {
    attempts: number
    lastStatus?: ModuleDeploymentStatus
}

/**
 * Implementation note:
 *
 * Please note that setting props.isInProgress to false does not preventing the react calling hook events.
 *
 * After getting response from the deployModuleVersions api, lastDeployModuleStatus will be set and attemptsDeployModule is increased,
 * both which trigger effect hook for handling status change.
 *
 * attemptsDeployModule is also to distinguish the status from the api call, which is to know whether the failure happened previously or at this time.
 *
 * reloadDeployModuleCount is to trigger the api call without making recursion loop between state and effect hooks using reducer.
 **/
export function ModuleDeploymentProgress(props: ModuleDeploymentProgressProps) {
    const [isRetry, setIsRetry] = useState<boolean>(false)
    const [reloadDeployModuleCount, reloadDeployModule] = useReducer((x: number) => x + 1, 0)
    const [moduleDeploymentContext, setModuleDeploymentContext] = useState<ModuleDeploymentContext>(
        {
            attempts: 0,
            lastStatus: undefined,
        }
    )
    const [deploymentProgressStatus, setDeploymentProgressStatus] =
        useState<DeploymentProgressStatus>(DeploymentProgressStatus.READY)
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)

    const handleError = useCallback(
        (error: Error, errorCode: ErrorTypes) => {
            setDeploymentProgressStatus(DeploymentProgressStatus.FAILED)
            setErrorMessage(error?.message)
            sendPublishingResultMetric(MapStageToKatalEventType[props.targetStage], errorCode)
        },
        [props.targetStage]
    )

    useEffect(() => {
        if (!props.isInProgress) {
            return
        }

        ModuleService.deployModuleVersion(props.moduleVersionId, {
            comment: props.comments,
            stage: props.targetStage,
            retry: isRetry,
        })
            .then((response) => {
                setModuleDeploymentContext({
                    lastStatus: response.deploymentStatus,
                    attempts: moduleDeploymentContext.attempts + 1,
                })
                setIsRetry(false)
            })
            .catch((error: Error) => {
                handleError(error, ErrorTypes.AUTHORING_SERVICE_ERROR)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        props.isInProgress,
        props.comments,
        props.targetStage,
        props.moduleVersionId,
        isRetry,
        reloadDeployModuleCount,
        handleError,
    ])
    return (
        <DeploymentProgress
            isInProgress={props.isInProgress}
            targetStage={props.targetStage}
            errorMessage={errorMessage}
            setDeployedStatus={props.setDeployedStatus}
            setErrorMessage={setErrorMessage}
            setIsRetry={setIsRetry}
            handleError={handleError}
            setDeploymentProgressStatus={setDeploymentProgressStatus}
            deploymentProgressStatus={deploymentProgressStatus}
            reloadDeployModule={reloadDeployModule}
            setModuleDeploymentContext={setModuleDeploymentContext}
            moduleDeploymentContext={moduleDeploymentContext}
        />
    )
}
