import React, { useEffect, useState } from 'react'
import _ from 'lodash'

import { Button, ButtonVariant } from '@amzn/stencil-react-components/button'
import {
    FormWrapper,
    Input,
    InputFooter,
    InputWrapper,
    Select,
} from '@amzn/stencil-react-components/form'
import { IconAlertCircleFill, IconBin, IconPlus } from '@amzn/stencil-react-components/icons'
import { Col, Container, Row, Spacer } from '@amzn/stencil-react-components/layout'
import { Spinner } from '@amzn/stencil-react-components/spinner'
import { Label } from '@amzn/stencil-react-components/text'

import { ResearchValidationErrorMessage, useResearchPreview } from 'src/hooks/useResearchPreview'
import { CommonUserEventKeys, publishUserEventMetrics, UserEventMethodNames } from 'src/metrics'
import {
    defaultResearchWorkflowDTO,
    ResearchWorkflowDTO,
    ResearchWorkflowEnvironment,
    ResearchWorkflowResource,
    ResearchWorkflowThemeName,
} from 'src/models/dto/ResearchWorkflowDTO'

interface ResearchIdInputProps {
    researchId: ResearchWorkflowResource
    index: number
    removeModule(index: number): void
    updateModule(index: number, value: ResearchWorkflowResource): void
    isLast: boolean
    moduleErrors: ResearchValidationErrorMessage[]
}

const newResearchWorkflowModule = (): ResearchWorkflowResource => {
    return {
        evaluation: true,
        moduleVersionId: ' ',
    } as ResearchWorkflowResource
}

const ResearchIdInput = ({
    researchId,
    index,
    removeModule,
    updateModule,
    isLast,
    moduleErrors,
}: ResearchIdInputProps) => {
    const [moduleVersionId, setModuleVersionId] = useState<string>(researchId.moduleVersionId)
    const [moduleError, setModuleError] = useState('')
    const [invalid, setInvalid] = useState(false)

    useEffect(() => {
        setModuleError('')
        setInvalid(false)
        moduleErrors.forEach((error, actualIndex) => {
            const regex = /\[(.*?)]/
            const regexValue = regex.exec(error.attributePath)
            const errorIndex: number | null = regexValue ? parseInt(regexValue[1]) : null
            if (_.isNumber(errorIndex) && errorIndex === index) {
                setModuleError(moduleErrors[actualIndex].message)
                setInvalid(true)
            }
        })
    }, [index, moduleErrors])

    useEffect(() => {
        setModuleVersionId(researchId.moduleVersionId)
    }, [researchId.moduleVersionId])

    useEffect(() => {
        if (moduleVersionId.length == 0) {
            updateModule(index, {
                moduleVersionId: ' ',
                evaluation: true,
            })
        }
    }, [index, moduleVersionId, updateModule])

    return (
        <Row gridGap={'S200'}>
            <Col flex={1}>
                <Input
                    id={`research-id-input-${index}`}
                    dataTestId={`research-id-input-${index}`}
                    value={moduleVersionId}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setModuleVersionId(event.target.value)
                        updateModule(index, {
                            moduleVersionId: event.target.value.trim(),
                            evaluation: true,
                        })
                    }}
                    error={invalid}
                />
                {invalid && (
                    <InputFooter
                        error
                        id={`research-id-input-${index}-footer`}
                        dataTestId={`research-id-input-${index}-footer`}
                    >
                        {moduleError}
                    </InputFooter>
                )}
            </Col>
            <Col>
                <Button
                    id={`delete-research-${index}-button`}
                    dataTestId={`delete-research-${index}-button`}
                    variant={ButtonVariant.Tertiary}
                    isDestructive={true}
                    onClick={() => {
                        removeModule(index)
                    }}
                    disabled={isLast}
                    aria-label={`Delete research id at index ${index}.`}
                    icon={<IconBin aria-hidden={true} />}
                />
            </Col>
        </Row>
    )
}

const themeOptions = Object.values(ResearchWorkflowThemeName)

enum EnvName {
    'DELTA' = 'Preview (Delta)',
    'GAMMA' = 'UAT (Gamma)',
}

const environmentOptions = Object.keys(EnvName)

export const ResearchWorkflowPage = () => {
    const [researchModules, setResearchModules] = useState(() => [newResearchWorkflowModule()])
    const [theme, setTheme] = useState<string>(ResearchWorkflowThemeName.Branded)
    const [environment, setEnvironment] = useState<ResearchWorkflowEnvironment>(
        ResearchWorkflowEnvironment.DELTA
    )
    const [moduleErrors, setModuleErrors] = useState<ResearchValidationErrorMessage[]>([])
    const [themeError, setThemeError] = useState<string>('')
    const [researchWorkflowDTO, setResearchWorkflowDTO] = useState<ResearchWorkflowDTO>(
        defaultResearchWorkflowDTO
    )

    useEffect(() => {
        publishUserEventMetrics(UserEventMethodNames.ResearchWorkflow, CommonUserEventKeys.Open)
    }, [])

    const {
        generatingPreview,
        previewValidationError,
        unexpectedPreviewError,
        previewUrl,
        executePreview,
    } = useResearchPreview({
        researchWorkflow: researchWorkflowDTO,
    })

    const setResearchModuleForIndex = (index: number, value: ResearchWorkflowResource) => {
        const newResearchWorkflow = [...researchModules]
        newResearchWorkflow.splice(index, 1, value)
        setResearchModules(newResearchWorkflow)
    }

    const removeResearchModule = (index: number) => {
        const newResearchWorkflow = [...researchModules]
        newResearchWorkflow.splice(index, 1)
        setResearchModules(newResearchWorkflow)
    }

    useEffect(() => {
        if (previewUrl) {
            window.open(
                previewUrl,
                'preview',
                'scrollbars=no,menubar=no,toolbar=no,location=no,status=no'
            )
        }
    }, [previewUrl])

    useEffect(() => {
        setResearchWorkflowDTO({
            theme: ResearchWorkflowThemeName[theme as keyof ResearchWorkflowThemeName],
            modules: researchModules,
            environment,
        })
    }, [theme, researchModules, environment])

    useEffect(() => {
        setThemeError('')
        setModuleErrors([])
        previewValidationError?.forEach((dto) => {
            if (dto.attributePath === 'theme') {
                setThemeError(dto.message)
            } else if (dto.attributePath.includes('modules')) {
                setModuleErrors((old) => {
                    return [...old, dto]
                })
            }
        })
    }, [previewValidationError])

    return (
        <Container paddingTop={'S400'} paddingHorizontal={'S400'}>
            <Row>
                {previewValidationError && previewValidationError.length > 0 && (
                    <>
                        <Spacer flex={1} />
                        <Col padding={{ right: 'S200' }}>
                            <Row color={'red70'} gridGap={'S100'} alignItems={'center'}>
                                <IconAlertCircleFill aria-hidden />
                                {previewValidationError.length} issues
                            </Row>
                        </Col>
                    </>
                )}
            </Row>
            {unexpectedPreviewError && (
                <Row>
                    <Spacer flex={1} />
                    <Col padding={{ right: 'S200' }}>
                        <Row color={'red70'} gridGap={'S100'} alignItems={'center'}>
                            <IconAlertCircleFill aria-hidden />
                            An unexpected error occurred while trying to preview. Please try again.
                        </Row>
                    </Col>
                </Row>
            )}
            <FormWrapper>
                <InputWrapper
                    id={'research-workflow-theme'}
                    dataTestId={'research-workflow-theme'}
                    labelText={'Research Workflow Theme'}
                    error={themeError.length > 0}
                    footer={(footerProps) =>
                        themeError.length > 0 ? (
                            <InputFooter
                                dataTestId={'research-workflow-theme-footer'}
                                {...footerProps}
                            >
                                {themeError}
                            </InputFooter>
                        ) : undefined
                    }
                >
                    {(inputProps) => (
                        <Select
                            {...inputProps}
                            options={themeOptions}
                            defaultValue={theme}
                            onChange={(e: string) => {
                                setTheme(e)
                            }}
                        />
                    )}
                </InputWrapper>
                <InputWrapper
                    id='research-workflow-environment'
                    dataTestId='research-workflow-environment'
                    labelText='Research Workflow Environment'
                >
                    {(inputProps) => (
                        <Select
                            {...inputProps}
                            options={environmentOptions}
                            defaultValue={environment}
                            renderOption={(opt: string) => EnvName[opt as never]}
                            renderSelectedValue={(opt: string) => EnvName[opt as never]}
                            onChange={(e: string) => {
                                setEnvironment(e as never)
                            }}
                        />
                    )}
                </InputWrapper>

                <Col gridGap={'S200'}>
                    <Label color={moduleErrors.length > 0 ? 'red70' : 'neutral90'}>
                        Enter IDs to be included in the workflow
                    </Label>
                    {researchModules.map((researchId, index) => {
                        return (
                            <ResearchIdInput
                                key={`researchId-${index}`}
                                researchId={researchId}
                                index={index}
                                removeModule={removeResearchModule}
                                updateModule={setResearchModuleForIndex}
                                isLast={researchModules.length === 1}
                                moduleErrors={moduleErrors}
                            />
                        )
                    })}
                    <Row gridGap={'S200'}>
                        <Button disabled>Save</Button>
                        {generatingPreview ? (
                            <Spinner dataTestId='preview-spinner' />
                        ) : (
                            <Button
                                onClick={() => {
                                    executePreview()
                                }}
                                dataTestId={'preview-research-workflow-button'}
                            >
                                Preview
                            </Button>
                        )}
                        <Spacer flex={1} />
                        <Button
                            variant={ButtonVariant.Tertiary}
                            onClick={() => {
                                setResearchModules([
                                    ...researchModules,
                                    newResearchWorkflowModule(),
                                ])
                            }}
                            id={'new-research-id-button'}
                            dataTestId={'new-research-id-button'}
                        >
                            <IconPlus aria-hidden={true} />
                            Add ID
                        </Button>
                    </Row>
                </Col>
            </FormWrapper>
        </Container>
    )
}
