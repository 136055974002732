import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { Button } from '@amzn/stencil-react-components/button'
import { TextArea } from '@amzn/stencil-react-components/form'
import { Col, Hr, Row, Spacer } from '@amzn/stencil-react-components/layout'
import { ScreenReaderOnly } from '@amzn/stencil-react-components/screen-reader-only'
import { Spinner } from '@amzn/stencil-react-components/spinner'
import { H5 } from '@amzn/stencil-react-components/text'

import { ModuleReviewContext } from 'src/contexts/ModuleReviewContext'
import { useAsyncAction } from 'src/hooks/useAsyncAction'
import { CommentDTO, LocationId } from 'src/models/dto/approval/CommentDTO'
import { ApprovalService } from 'src/services/approval/ApprovalService'
import { Authenticator } from 'src/services/Authenticator'

export function locationId({
    pageNumber: p,
    itemNumber: i,
}: {
    pageNumber: number
    itemNumber?: number
}): LocationId {
    return JSON.stringify({ pageNumber: p, itemNumber: i })
}

export function parseLocationId(
    s: LocationId
): Partial<{ pageNumber: number; itemNumber: number }> {
    function parse(part: unknown) {
        return typeof part === 'string'
            ? parseInt(part, 10)
            : typeof part === 'number'
            ? part
            : undefined
    }

    try {
        const j = JSON.parse(s || '{}') as { pageNumber: unknown; itemNumber: unknown }
        return { pageNumber: parse(j.pageNumber), itemNumber: parse(j.itemNumber) }
    } catch (e) {
        return {}
    }
}

export function formatLocationId(l: LocationId) {
    const { pageNumber, itemNumber } = parseLocationId(l)
    return (
        (typeof pageNumber === 'number' ? `Page ${pageNumber} ` : '') +
        (typeof itemNumber === 'number' ? `Item ${itemNumber}` : '')
    ).trim()
}

export function formatTimestamp(timestamp: number) {
    const SECOND = 1000
    const date = new Date(timestamp * SECOND)
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
}

export function Comment(props: { comment: CommentDTO; label?: string }) {
    const { comment, label } = props
    const loc = useMemo(() => formatLocationId(comment.locationId), [comment.locationId])
    return (
        <Col backgroundColor='white' padding='S200' width='100%' dataTestId='module-review-comment'>
            {label && <ScreenReaderOnly>{label + ': '}</ScreenReaderOnly>}
            <H5 style={{ color: 'neutral10', width: '100%' }}>
                <div>
                    <span data-test-id='comment-author'>{comment.author}</span>
                    <span> on </span>
                    <span data-test-id='comment-date'>{formatTimestamp(comment.createdAt)}</span>
                    {loc?.trim() ? (
                        <span>
                            {' @ '}
                            <span data-test-id='comment-location'>{loc}</span>
                        </span>
                    ) : (
                        <span data-test-id='comment-location'>{''}</span>
                    )}
                    <span>
                        {' | Rev '}
                        <span data-test-id='comment-rev'>{comment.revisionNumber}</span>
                    </span>
                </div>
            </H5>
            <Spacer height='S100' />
            <Hr />
            <Spacer height='S100' />
            <Row dataTestId='comment-message' padding={{ top: 'S100' }}>
                {comment.message}
            </Row>
        </Col>
    )
}

export interface AddCommentFormProps {
    reviewId: string
    revisionNumber: string
    locationId?: LocationId
    commentTextAreaId?: string
    commentTextAreaLabelledBy?: string
    onCommentPosted?: () => void
}

function savedCommentKey(reviewId: string, l: LocationId) {
    return `AddCommentForm_SavedComment_${JSON.stringify({ reviewId, locationId: l })}`
}

export function AddCommentForm({
    reviewId,
    revisionNumber,
    locationId: l = '',
    commentTextAreaId: commentTextAreaId = 'comment-textarea',
    commentTextAreaLabelledBy,
    onCommentPosted,
}: AddCommentFormProps) {
    const { reloadComments } = useContext(ModuleReviewContext)
    const storageKey = savedCommentKey(reviewId, l)
    const author = Authenticator.getDefaultUser()
    const [comment, setComment] = useState<string>(() => sessionStorage?.getItem(storageKey) || '')
    useEffect(() => {
        if (comment) {
            sessionStorage.setItem(storageKey, comment)
        } else {
            sessionStorage.removeItem(storageKey)
        }
    }, [comment, storageKey])

    const input = useMemo(
        () => ({ reviewId, revisionNumber, author, message: comment, locationId: l }),
        [reviewId, revisionNumber, author, comment, l]
    )
    const { invoke: postComment, isLoading } = useAsyncAction(async () => {
        await ApprovalService.createComment(input)
        setComment('')
        sessionStorage.removeItem(storageKey)
        if (reloadComments) {
            await reloadComments()
        }
        onCommentPosted?.()
    }, [input])

    const onCommentChange = useCallback(
        (e: React.ChangeEvent<HTMLTextAreaElement>) => setComment(e.target.value),
        []
    )
    return (
        <Col gridGap={10} dataTestId='add-comment-form'>
            <ScreenReaderOnly id='comment-textarea-desc'>Enter your comment here.</ScreenReaderOnly>
            <TextArea
                value={comment}
                onChange={onCommentChange}
                id={commentTextAreaId}
                dataTestId='comment-textarea'
                aria-describedby='comment-textarea-desc'
                {...{ 'aria-labelledby': commentTextAreaLabelledBy }}
            />
            <Row>
                <Spacer flex={1} />
                <Button
                    dataTestId='add-comment'
                    aria-disabled={!comment.trim().length}
                    onClick={postComment}
                >
                    Add comment
                </Button>
            </Row>
            {isLoading && <Spinner />}
        </Col>
    )
}
