import { v4 } from 'uuid'

import { ItemDTO, ItemType } from 'src/models/dto/items/ItemDTO'
import { Locale, LocalizedAttribute, LocalizeDefault } from 'src/models/dto/Locale'

export enum ScoringMLModelType {
    ACE_SALES_SCORING_MODEL = 'ACE_SALES_SCORING_MODEL',
    AA_ACE_Sales_NLP_Scoring = 'AA_ACE_Sales_NLP_Scoring',
    AA_Sales_RoW = 'AA_Sales_RoW',
    AA_Sales_NATAM = 'AA_Sales_NATAM',
    Customer_Facing = 'Customer_Facing',
    LLM_Mixtral_CRA_Scoring = 'LLM_Mixtral_CRA_Scoring',
}

export enum TranscriptionMLModelType {
    ACE_SALES_TRANSCRIPTION_MODEL = 'ACE_SALES_TRANSCRIPTION_MODEL',
    AA_Sales_Transcribe_en_US = 'AA_Sales_Transcribe_en_US',
}

export interface AudioEvaluationItemDTO extends ItemDTO {
    itemType: ItemType.AudioEvaluation
    situationI18N: LocalizedAttribute<string>
    taskI18N: LocalizedAttribute<string>
    maxNumberOfAttempts: number
    maxRecordingTimeInSeconds: number
    experimental: boolean
    scoringMLModel: ScoringMLModelType
    scoringMLModelVersion: string
    transcriptionMLModel: TranscriptionMLModelType
    transcriptionMLModelVersion: string
}

export interface ModelVersion {
    displayName: string
    versions: string[]
}

export const defaultAudioEvaluationDTO = (): AudioEvaluationItemDTO => {
    const newId = v4()
    return {
        id: newId,
        itemType: ItemType.AudioEvaluation,
        label: '',
        locale: Locale.en_US,
        name: newId,
        optional: false,
        preserveOrder: false,
        ppt: ItemType.AudioEvaluation,
        experimental: false,
        maxNumberOfAttempts: 3,
        maxRecordingTimeInSeconds: 180,
        scoringMLModel: ScoringMLModelType.AA_ACE_Sales_NLP_Scoring,
        scoringMLModelVersion: '1.0',
        transcriptionMLModel: TranscriptionMLModelType.AA_Sales_Transcribe_en_US,
        transcriptionMLModelVersion: '1.0',
        taskI18N: LocalizeDefault<string>(''),
        situationI18N: LocalizeDefault<string>(''),
    }
}
