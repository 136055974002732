import React from 'react'

import { Button, ButtonVariant } from '@amzn/stencil-react-components/button'
import { IconCopy } from '@amzn/stencil-react-components/icons'

export type UpdateFields = (newValue: string, mimeType?: string) => void

interface UseMediaButtonProps {
    autoFocus?: boolean
    isModuleBuilder?: boolean
    updateFields?: UpdateFields
    media?: {
        filename?: string
        relativePath?: string
        mimeType: string
    }
}

export function UseMediaButton({
    autoFocus = false,
    isModuleBuilder = false,
    updateFields,
    media,
}: UseMediaButtonProps) {
    if (isModuleBuilder) {
        return (
            <Button
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus={autoFocus}
                disabled={!media?.relativePath}
                variant={ButtonVariant.Primary}
                onClick={() => {
                    if (media?.relativePath && updateFields) {
                        updateFields(media.relativePath, media.mimeType)
                    } else {
                        console.log('UseMediaButton (isModuleBuilder=true): something went wrong', {
                            media,
                        })
                    }
                }}
            >
                Use Media
            </Button>
        )
    }
    return (
        <Button
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={autoFocus}
            disabled={!media?.relativePath}
            variant={ButtonVariant.Primary}
            icon={<IconCopy aria-hidden={true} />}
            onClick={() => {
                if (media?.relativePath) {
                    void navigator.clipboard.writeText(media.relativePath)
                }
            }}
        >
            Copy Path
        </Button>
    )
}
