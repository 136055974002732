import React, { useMemo } from 'react'
import _ from 'lodash'

import { Button, ButtonVariant } from '@amzn/stencil-react-components/button'

import { ModuleGroupOptions, ModuleGroupOptionValues } from 'src/components/ModuleGroupOptions'
import { ReviewDTO } from 'src/models/dto/approval/ReviewDTO'
import { ModuleGroupDTO, ModuleGroupStatus } from 'src/models/dto/module-groups/ModuleGroupTypeDTO'
import { ModuleReviewButtonsWrapper } from 'src/pages/module-review/ModuleReviewButtonsWrapper'
import { RevisionSelector } from 'src/pages/module-review/RevisionSelector'

interface ModuleGroupReviewPageButtonsProps {
    moduleGroup: ModuleGroupDTO
    review?: ReviewDTO | null
    approve: () => Promise<void>
    revoke: () => Promise<void>
    changeRevision: (rev: string, reviewUrl?: string) => void
    revisionNumber: string
    isApproved: boolean
}

export function ModuleGroupReviewPageButtons({
    moduleGroup,
    changeRevision,
    approve,
    revoke,
    review,
    revisionNumber,
    isApproved,
}: ModuleGroupReviewPageButtonsProps) {
    const hasBeenUATDeployed =
        ModuleGroupStatus[moduleGroup.status as keyof typeof ModuleGroupStatus] ===
        ModuleGroupStatus.UAT
    const readyToBeUATDeployed =
        ModuleGroupStatus[moduleGroup.status as keyof typeof ModuleGroupStatus] ===
        ModuleGroupStatus.REVIEW_APPROVED

    const dropDownOptions = new Set([ModuleGroupOptionValues.BACK_TO_VIEWER])

    if (readyToBeUATDeployed) {
        dropDownOptions.add(ModuleGroupOptionValues.DEPLOY_UAT)
    }

    const sortedRevisions = useMemo(
        () => (review ? _.sortBy(review.revisionList, (r) => -r.createdAt) : []),
        [review]
    )

    return (
        <ModuleReviewButtonsWrapper>
            {review &&
                !hasBeenUATDeployed &&
                (!isApproved ? (
                    <Button disabled={isApproved} dataTestId='approve-review' onClick={approve}>
                        Approve
                    </Button>
                ) : (
                    <Button
                        variant={ButtonVariant.Secondary}
                        disabled={!isApproved}
                        dataTestId='revoke-approved-review'
                        onClick={revoke}
                    >
                        Revoke Approval
                    </Button>
                ))}
            <RevisionSelector
                revisions={sortedRevisions}
                selectedRevision={revisionNumber}
                onRevisionSelect={changeRevision}
            />

            <ModuleGroupOptions
                moduleGroupEntity={moduleGroup}
                moduleGroupStatus={moduleGroup.status as ModuleGroupStatus}
                reviewStatus={undefined}
                optionsToInclude={dropDownOptions}
            />
        </ModuleReviewButtonsWrapper>
    )
}
