import React, { useCallback, useEffect, useState } from 'react'
import AWS from 'aws-sdk'

import { TimerStopwatch } from '@amzn/katal-metrics/lib/metricObject'
import { Button, ButtonVariant } from '@amzn/stencil-react-components/button'
import { InputWrapper, Select } from '@amzn/stencil-react-components/form'
import { Col, Container, Row, Spacer, View } from '@amzn/stencil-react-components/layout'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'
import { Spinner } from '@amzn/stencil-react-components/spinner'
import { H2, Text } from '@amzn/stencil-react-components/text'

import { LAYOUT_DIRECTION, useLayoutDirection } from 'src/components/ModuleDisplayTable'
import {
    CommonUserEventKeys,
    publishTimeSpentMetrics,
    publishUserEventMetrics,
    UserEventMethodNames,
} from 'src/metrics'
import { RunResults, ViewResultsPage } from 'src/pages/automation-tests-request/ViewResultsPage'
import {
    Workflow,
    WorkflowDefinitionsUploaderPage,
} from 'src/pages/automation-tests-request/WorkflowDefinitionsUploaderPage'
import { setCrossAccountCredentials } from 'src/services/CrossAccountCredentials'
import { DDBHandler } from 'src/services/DDBHandler'
import { setUpAwsConfig } from 'src/services/media/S3FileHandler'
import { S3Handler } from 'src/services/S3Handler'

export const s3Functions = new S3Handler('workflow-definitions')
export const ddbFunctions = new DDBHandler('WorkflowRunResults')
export const DDB_RETRIEVE_ERROR = 'errorRetrievingFromDDB'

const pageTimer = new TimerStopwatch('WorkflowAutomationRequestTimer')

export enum WorkflowAutomationTestingEvents {
    UploadJSONFile = 'UploadJSONFile',
    DeleteJSONFile = 'DeleteJSONFile',
    GetJSONFile = 'GetJSONFile',
    GetWorkflowRunsResult = 'GetWorkflowRunsResult',
    DeleteWorkflowRunResults = 'DeleteWorkflowRunResults',
    ListFileNames = 'ListFileNames',
    UploadFileError = 'UploadFileError',
    DeleteFileError = 'DeleteFileError',
    GetFileError = 'GetFileError',
    ListFileNamesError = 'ListFileNamesError',
    GetResultsError = 'GetResultsError',
    DeleteRunResultsError = 'DeleteRunResultsError',
}

export function UnexpectedErrorsBanner({
    errorMessage,
    onDismiss,
}: {
    errorMessage: string
    onDismiss: () => void
}) {
    return (
        <>
            <MessageBanner
                type={MessageBannerType.Error}
                dismissButtonAltText={'Hide errors'}
                isDismissible={true}
                dataTestId={'unexpected-error-banner'}
                onDismissed={() => {
                    onDismiss()
                }}
            >
                <Text>{errorMessage}</Text>
            </MessageBanner>
        </>
    )
}

export const AutomationTestsPage = () => {
    const [pullingFileNames, setPullingFileNames] = useState(true)
    const [fileNames, setFileNames] = useState<string[]>([])
    const [selectedFile, setSelectedFile] = useState('')
    const [loadingFile, setLoadingFile] = useState(false)
    const [workflowDefinition, setWorkflowDefinition] = useState<Workflow | undefined>()
    const [launchBuilderPage, setLaunchBuilderPage] = useState(false)
    const [selectedResults, setSelectedResults] = useState<RunResults[]>([])
    const [launchResultsPage, setLaunchResultsPage] = useState(false)
    const [unexpectedError, setUnexpectedError] = useState<string | undefined>(undefined)

    const layout = useLayoutDirection()

    const returnToSelectionPage = () => {
        setLaunchResultsPage(false)
        setLaunchBuilderPage(false)
        setSelectedFile('')
        setWorkflowDefinition(undefined)
        setSelectedResults([])
        setPullingFileNames(true)
    }

    const loadFile = useCallback(async () => {
        console.log(`Downloading ${selectedFile})} JSON file from S3`)

        await s3Functions
            .loadFileFromS3(selectedFile)
            .then((data) => {
                publishUserEventMetrics(
                    UserEventMethodNames.WorkflowAutomationRequest,
                    WorkflowAutomationTestingEvents.GetJSONFile
                )

                if (data.Body) {
                    setWorkflowDefinition(JSON.parse(data.Body.toString('utf-8')) as Workflow)
                    setLaunchBuilderPage(true)
                }
            })
            .catch((error: Error) => {
                publishUserEventMetrics(
                    UserEventMethodNames.WorkflowAutomationRequest,
                    WorkflowAutomationTestingEvents.GetFileError
                )
                console.error(error, error.stack)

                setUnexpectedError(
                    `There was an error getting the JSON file ${selectedFile} from the workflow-definitions S3 bucket. That file seems to have been removed from the workflow definitions list. Pulling the existing files again so you have the most up to date list.`
                )
                setSelectedFile('')
                setPullingFileNames(true)
            })
    }, [selectedFile])

    const loadFromDDB = useCallback(async () => {
        const config = await setCrossAccountCredentials()

        const results = await ddbFunctions
            .loadResults('workflowDefinitionFile', selectedFile, config)
            .then((data) => {
                publishUserEventMetrics(
                    UserEventMethodNames.WorkflowAutomationRequest,
                    WorkflowAutomationTestingEvents.GetWorkflowRunsResult
                )

                return (
                    data.Items?.reverse().map((item) => {
                        return AWS.DynamoDB.Converter.unmarshall(item) as RunResults
                    }) || []
                )
            })
            .catch((error: Error) => {
                publishUserEventMetrics(
                    UserEventMethodNames.WorkflowAutomationRequest,
                    WorkflowAutomationTestingEvents.GetResultsError
                )

                console.log(error)
                return DDB_RETRIEVE_ERROR
            })
        return results
    }, [selectedFile])

    const loadResults = useCallback(async () => {
        console.log(`Loading the historical run results of ${selectedFile})} from DDB`)

        const results = await loadFromDDB()

        if (results === DDB_RETRIEVE_ERROR) {
            setUnexpectedError(
                `There was an unexpected error getting the results of the ${selectedFile} file runs.`
            )
        } else {
            const ddbItems = results as RunResults[]
            setSelectedResults(ddbItems)
            setLaunchResultsPage(true)
        }

        // Switch back to regular credentials
        await setUpAwsConfig()
        AWS.config.update({})
    }, [selectedFile, loadFromDDB])

    useEffect(() => {
        pageTimer.start()
        publishUserEventMetrics(
            UserEventMethodNames.WorkflowAutomationRequest,
            CommonUserEventKeys.Open
        )

        return () => {
            pageTimer.stop()
            publishTimeSpentMetrics(UserEventMethodNames.WorkflowAutomationRequest, pageTimer.value)
        }
    }, [])

    useEffect(() => {
        if (pullingFileNames) {
            setUpAwsConfig()
                .then(() => {
                    AWS.config.update({})
                    s3Functions
                        .getFileNames()
                        .then((data) => {
                            publishUserEventMetrics(
                                UserEventMethodNames.WorkflowAutomationRequest,
                                WorkflowAutomationTestingEvents.ListFileNames
                            )

                            if (data.Contents) {
                                const fileList = data.Contents.map((file) =>
                                    file.Key ? file.Key.toString() : ''
                                )
                                setFileNames(fileList)
                            }
                            setPullingFileNames(false)
                        })
                        .catch((error: Error) => {
                            publishUserEventMetrics(
                                UserEventMethodNames.WorkflowAutomationRequest,
                                WorkflowAutomationTestingEvents.ListFileNamesError
                            )
                            console.error(error, error.stack)

                            setUnexpectedError(
                                'There was an error getting the list of JSON files from the workflow-definitions S3 bucket.'
                            )
                            setPullingFileNames(false)
                        })
                })
                .catch(() => {
                    console.log('There was an error updating the AWS config.')
                    setPullingFileNames(false)
                })
        }
    }, [pullingFileNames])

    if (launchBuilderPage) {
        return (
            <WorkflowDefinitionsUploaderPage
                workflowDefinition={workflowDefinition}
                goBack={returnToSelectionPage}
                fileNames={fileNames}
                loadDDBResults={loadFromDDB}
            />
        )
    }

    if (launchResultsPage) {
        return (
            <ViewResultsPage
                results={selectedResults}
                goBack={returnToSelectionPage}
                fileName={selectedFile}
            />
        )
    }

    return (
        <Container paddingTop={'S400'} paddingHorizontal={'S400'}>
            {unexpectedError && (
                <UnexpectedErrorsBanner
                    errorMessage={unexpectedError}
                    onDismiss={() => {
                        setUnexpectedError(undefined)
                    }}
                />
            )}
            {!pullingFileNames ? (
                <Col flex={'3 0 250px'} width={'100%'}>
                    <Col gridGap='S300' width={'100%'}>
                        <H2>Workflow Automation Test Requests</H2>
                        <Text>
                            Select which workflow definition file you would like to update/delete
                            below, or create a new one from scratch.
                        </Text>
                        <Text>
                            To view the run results of the the currently defined workflows, select
                            the file and hit “View results.“ You can visit the{' '}
                            <a
                                href='https://pipelines.amazon.com/pipelines/AssessmentsUIAutomationWebsite'
                                target='_blank'
                                rel='noreferrer'
                            >
                                UI Automation Pipeline
                            </a>{' '}
                            to see when it was last run. The pipeline is scheduled to run every few
                            hours, so check back to review the latest runs and continue the UAT sign
                            off process.
                        </Text>
                        <Text>
                            Workflows will continue to run on the pipeline indefinitely, so once a
                            workflow has completed UAT testing be sure to come back and delete
                            corresponding file fom the automation tests list.
                        </Text>
                    </Col>
                    <Spacer height={'S400'} />
                    <InputWrapper
                        labelText='Select a workflow definition file to update'
                        id='basic-details-layout'
                    >
                        {(props) => (
                            <Select
                                dataTestId={'select-workflow'}
                                options={fileNames.filter((name) => name !== '')}
                                placeholder='Select a workflow definition file'
                                {...props}
                                value={selectedFile}
                                onChange={(file: string) => {
                                    setSelectedFile(file)
                                }}
                            />
                        )}
                    </InputWrapper>
                    <Spacer height={'S400'} />
                    <View
                        display='flex'
                        flexDirection={layout === LAYOUT_DIRECTION.MOBILE ? 'column' : 'row'}
                        justifyContent='space-between'
                        width='100%'
                    >
                        {!loadingFile ? (
                            <>
                                <Row gridGap={'S300'}>
                                    <Button
                                        dataTestId={'update-workflow-button'}
                                        disabled={selectedFile === ''}
                                        onClick={async () => {
                                            setLoadingFile(true)
                                            await loadFile()
                                            setLoadingFile(false)
                                        }}
                                    >
                                        Update workflow
                                    </Button>
                                    <Button
                                        dataTestId={'view-results-button'}
                                        variant={ButtonVariant.Primary}
                                        disabled={selectedFile === ''}
                                        onClick={async () => {
                                            setLoadingFile(true)
                                            await loadResults()
                                            setLoadingFile(false)
                                        }}
                                    >
                                        View results
                                    </Button>
                                </Row>
                                {layout === LAYOUT_DIRECTION.MOBILE && <Spacer height={'S400'} />}
                                <Button
                                    dataTestId={'create-new-file-button'}
                                    variant={ButtonVariant.Primary}
                                    onClick={() => {
                                        setLaunchBuilderPage(true)
                                    }}
                                >
                                    Create new workflow
                                </Button>
                            </>
                        ) : (
                            <Spinner />
                        )}
                    </View>
                </Col>
            ) : (
                <Spinner dataTestId='loading-spinner' />
            )}
        </Container>
    )
}
