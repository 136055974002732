import React, { useCallback, useContext, useState } from 'react'

import { Button, ButtonVariant } from '@amzn/stencil-react-components/button'
import { IconCopy } from '@amzn/stencil-react-components/icons'
import { Col, Row, Spacer } from '@amzn/stencil-react-components/layout'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'
import { ScreenReaderOnly } from '@amzn/stencil-react-components/screen-reader-only'
import { Spinner } from '@amzn/stencil-react-components/spinner'
import { Status, StatusIndicator } from '@amzn/stencil-react-components/status-indicator'
import { CellType, Table, TableColumn } from '@amzn/stencil-react-components/table'

import { useInterval } from 'src/hooks/useInterval'
import { GeneratedItemPoolSubType } from 'src/models/dto/item-pools/ItemPoolMetadataDTO'
import { FlyoutReferenceDTO } from 'src/models/dto/items/FlyoutReferenceDTO'
import { Locale } from 'src/models/dto/Locale'
import { GeneratedPoolsStorage } from 'src/pages/module-builder/item-pool-editor/GeneratedItemPoolsStorage'
import {
    AllowedItemPoolType,
    ItemPoolGeneratorContext,
    PoolResults,
    poolSizeError,
} from 'src/pages/module-builder/item-pool-editor/ItemPoolEditor'
import { GetProgressResponseStatus, ItemPoolService } from 'src/services/item-pools/ItemPoolService'

export const CopyCell = ({ data }: { data: string; index: number }) => {
    const [copied, setCopied] = useState(false)
    return (
        <Button
            dataTestId='copy-version-id'
            variant={ButtonVariant.Secondary}
            icon={<IconCopy aria-hidden={true} />}
            onClick={async () => {
                await navigator.clipboard.writeText(data)
                setCopied(true)
            }}
        >
            {copied ? 'Copied' : 'Copy'}
        </Button>
    )
}

CopyCell.displayName = 'CopyCell'

export interface ItemPoolGenerationProps {
    itemType: AllowedItemPoolType
    size: number
    itemGenerationInput: string
    hrefFunction: (b: Blob) => string
}

const DELAY_IN_MS = 1000
export const ItemPoolGenerator = ({
    itemType,
    size,
    itemGenerationInput,
    hrefFunction,
}: ItemPoolGenerationProps) => {
    const { getAdditionalTableCells } = useContext(ItemPoolGeneratorContext)

    const [loadingMessage, setLoadingMessage] = useState<string | null>(null)

    const [errorMessage, setErrorMessage] = useState<string>('')

    const [loading, setLoading] = useState<boolean>(false)

    const [loadingVersionId, setLoadingVersionId] = useState<string | null>(null)

    const [itemPoolIds, setItemPoolIds] = useState<PoolResults[]>(
        GeneratedPoolsStorage.getResults()
    )

    const [downloading, setDownloading] = useState(-1)

    const generatePool = async () => {
        try {
            setLoading(true)
            setErrorMessage('')
            setLoadingVersionId(null)

            const defaultConfig = {
                constraints: itemGenerationInput,
                async: true,
                size,
            }
            if (itemType === AllowedItemPoolType.RankingItem) {
                const response = await ItemPoolService.generateItemPool({
                    ...defaultConfig,
                    subtype: GeneratedItemPoolSubType.RANKING,
                    useReferenceMaterial: true,
                    flyoutReference: JSON.stringify({
                        flyoutSectionType: 'Tier1RuleRankingResponseTable',
                        linkNameI18N: {
                            [Locale.en_US]: 'test',
                        },
                    } as FlyoutReferenceDTO),
                })
                setLoadingVersionId(response.versionId)
            } else {
                const response = await ItemPoolService.generateItemPool({
                    ...defaultConfig,
                    subtype:
                        itemType === AllowedItemPoolType.SlotPickerItem
                            ? GeneratedItemPoolSubType.SLOT_PICKER
                            : GeneratedItemPoolSubType.MCQ,
                    useReferenceMaterial: false,
                    flyoutReference: null,
                })
                setLoadingVersionId(response.versionId)
            }
        } catch (e) {
            setErrorMessage(
                'Trouble generating a new item pool. Please try again. If failures persists, contact Designer team for help.'
            )
            setItemPoolIds(GeneratedPoolsStorage.getResults())
            setLoading(false)
        }
    }

    const downloadPool = useCallback(
        async (versionId: string, index: number) => {
            try {
                window.localStorage.removeItem('itemPoolZipBase64')
                setDownloading(index)
                setErrorMessage('')

                const endpoint = itemPoolIds.find((itemPoolResult) => {
                    return itemPoolResult.versionId === versionId
                }) ?? { versionId: versionId, type: itemType, size: 0 }

                const res = await ItemPoolService.exportItemPool(versionId)

                const element = document.createElement('a')
                element.id = `download-item-pool-link-${versionId}`
                element.href = hrefFunction(res)
                element.ariaRoleDescription = 'link'
                element.download = `${endpoint.type} Item Pool - ${versionId}.zip`
                document.body.appendChild(element) // Required for this to work in FireFox
                element.click()
            } catch (e) {
                setErrorMessage(
                    'Trouble downloading item pool CSV. Please try again. If failures persists, contact Designer team for help.'
                )
                setDownloading(-1)
            } finally {
                setDownloading(-1)
            }
        },
        [itemPoolIds, itemType, hrefFunction]
    )

    useInterval(async () => {
        if (loadingVersionId) {
            const response = await ItemPoolService.getProgress(loadingVersionId)
            if (response.status === GetProgressResponseStatus.IN_PROGRESS) {
                setLoadingMessage(response.message)
                return
            }
            setLoadingVersionId(null)
            setLoadingMessage(null)
            setLoading(false)

            if (response.status === GetProgressResponseStatus.IN_ERROR) {
                setErrorMessage(response.message)
            } else {
                const newItem = { versionId: loadingVersionId, type: itemType, size }
                setItemPoolIds((previous) => [...previous, newItem])
                GeneratedPoolsStorage.setResults(newItem)
            }
        }
    }, DELAY_IN_MS)

    const ButtonCell = ({ data, index }: { data: string; index: number }) => {
        if (downloading !== index) {
            return (
                <Button
                    dataTestId='download-button'
                    disabled={downloading !== -1}
                    onClick={() => downloadPool(data, index)}
                >
                    Download
                </Button>
            )
        } else {
            return <Spinner />
        }
    }
    ButtonCell.displayName = 'ButtonCell'

    const columns: TableColumn<PoolResults, { versionId: string }>[] = [
        {
            header: 'Item Pool Version Id',
            accessor: 'versionId',
            headerCellType: CellType.Data,
            dataCellType: CellType.Header,
        },
        { header: 'Item Type', accessor: 'type' },
        { header: 'Pool Size', accessor: 'size' },
        {
            header: <ScreenReaderOnly>Delete</ScreenReaderOnly>,
            accessor: 'versionId',
            cellComponent: ButtonCell,
        },
        {
            header: <ScreenReaderOnly>Copy</ScreenReaderOnly>,
            accessor: 'versionId',
            cellComponent: CopyCell,
        },
        ...(getAdditionalTableCells?.() ?? []),
    ]

    return (
        <>
            <Button
                dataTestId='generate-item-pool-button'
                icon={null}
                onClick={generatePool}
                variant={ButtonVariant.Secondary}
                disabled={loading || poolSizeError(size)}
            >
                Generate Item Pool
            </Button>

            {errorMessage && (
                <MessageBanner
                    type={MessageBannerType.Error}
                    isDismissible={true}
                    dataTestId={'error-message-banner'}
                >
                    {errorMessage}
                </MessageBanner>
            )}
            <Spacer height='S400' />
            <Col flex={1} gridGap={30} aria-live='assertive'>
                {loading && <Spinner dataTestId={'item-pool-generation-loading-spinner'} />}
                <StatusIndicator
                    messageText={
                        loading
                            ? 'Generating item pool. ' + (loadingMessage ?? '')
                            : itemPoolIds.length > 0
                            ? 'Done'
                            : ''
                    }
                    status={
                        loading
                            ? Status.Loading
                            : itemPoolIds.length > 0
                            ? Status.Positive
                            : Status.Neutral
                    }
                />
                {!loading && itemPoolIds.length > 0 && (
                    <ScreenReaderOnly>
                        There are {itemPoolIds.length} item pools in the table below.
                    </ScreenReaderOnly>
                )}
            </Col>
            <Col aria-live='off'>
                {itemPoolIds.length > 0 && (
                    <Row>
                        <Table
                            dataTestId='item-pool-ids-table'
                            key={`itemPoolIds-${downloading}`}
                            data={itemPoolIds}
                            columns={columns}
                        />
                    </Row>
                )}
            </Col>
        </>
    )
}
