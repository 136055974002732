import React from 'react'

import { Button, ButtonColorScheme, ButtonVariant } from '@amzn/stencil-react-components/button'
import { styledWithTheme } from '@amzn/stencil-react-components/context'
import { IconCross } from '@amzn/stencil-react-components/icons'

// This is based on Stencil's ModalCloseButton, which is not exported by Stencil

const ModalCloseButton = styledWithTheme(Button)`
  color: ${({ theme }) => theme.selectors.color('red70')};

  :hover {
    color: ${({ theme }) => theme.selectors.color('red60')};
    border-color: ${({ theme }) => theme.selectors.color('red60')};
  }

  :focus {
    :before {
      border: none;
    }
    color: ${({ theme }) => theme.selectors.color('red80')};
    border-color: ${({ theme }) => theme.selectors.color('red80')};
  }
`

export const ModalCloseButtonComponent = ({ close }: { close: () => void }) => {
    return (
        <ModalCloseButton
            colorScheme={ButtonColorScheme.DarkBackground}
            icon={<IconCross title='Close modal' />}
            onClick={close}
            variant={ButtonVariant.Tertiary}
        />
    )
}
