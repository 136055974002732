import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react'

import { Button, ButtonVariant } from '@amzn/stencil-react-components/button'
import { Card } from '@amzn/stencil-react-components/card'
import { Input, InputWrapper, Select } from '@amzn/stencil-react-components/form'
import { Col, Row, Spacer, View } from '@amzn/stencil-react-components/layout'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'
import { ModalContent } from '@amzn/stencil-react-components/modal'
import { Pagination } from '@amzn/stencil-react-components/pagination'
import { ScreenReaderOnly } from '@amzn/stencil-react-components/screen-reader-only'
import { Spinner } from '@amzn/stencil-react-components/spinner'
import { H3 } from '@amzn/stencil-react-components/text'

import { SearchResultCard } from 'src/components/media/SearchResultCard'
import { TagsInput } from 'src/components/media/TagsInput'
import { UpdateFields } from 'src/components/media/UseMediaButton'
import { MimeTypes } from 'src/models/dto/items/MediaDTO'
import { MediaMetadataDTO } from 'src/models/media/MediaMetadataDTO'
import {
    MediaValErrorResponseThrowable,
    MediaValidationErrorMessage,
} from 'src/models/media/MediaValidationError'
import {
    MediaSearchRequest,
    MediaService,
    MediaServiceFactory,
    SearchResponse,
} from 'src/services/media/MediaService'

export interface MediaSearchModalProps {
    isModuleBuilder?: boolean
    updateFields?: UpdateFields
    close?: () => void
}

const fileTypes = [...Object.keys(MimeTypes), 'None']

export function describeResults({ totalMatches, page }: SearchResponse) {
    if (totalMatches === 0) {
        return '(No results)'
    }

    const pageSize = 10
    const iStart = (page - 1) * pageSize
    const iEnd = page * pageSize
    return `(${iStart + 1} to ${Math.min(iEnd, totalMatches)} out of ${totalMatches})`
}

export const MediaSearchModal = (props: MediaSearchModalProps) => {
    const { close } = props

    const fileTypeInput = useRef<HTMLButtonElement | HTMLSelectElement | null>(null)

    const searchResultsRef = React.useRef<HTMLHeadingElement | null>(null)
    const [searchError, setSearchError] = useState('')
    const [searchValErrors, setSearchValErrors] = useState<MediaValidationErrorMessage[]>([])
    const mediaService = useRef<MediaService>()
    const [clearCount, incrementClears] = useReducer((x: number) => x + 1, 0)
    const [mediaSearchResponse, setMediaSearchResponse] = useState<SearchResponse>()
    const [tags, setTags] = useState<string[]>([])
    const [page, setPage] = useState(1)
    const [stateFilename, setStateFilename] = useState('')
    const [stateAuthor, setStateAuthor] = useState('')
    const [stateDate, setStateDate] = useState('')
    const [stateFileType, setStateFileType] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        mediaService.current = MediaServiceFactory.loadExisting()
        setTags(mediaService.current?.getCurrentRequest().tags)
    }, [mediaService])

    const onSearchClicked = useCallback(
        async (newPage?: number) => {
            if (!mediaService.current) {
                return
            }

            const searchRequest: MediaSearchRequest = {
                filename: stateFilename,
                author: stateAuthor,
                date: stateDate,
                fileType: stateFileType,
                tags,
                page: `${newPage ?? page}`,
            }

            if (typeof newPage === 'number') {
                setPage(newPage)
            }

            mediaService.current?.setCurrentRequest(searchRequest)

            setSearchError('')
            setSearchValErrors([])
            let success = false
            try {
                setIsLoading(true)
                const response = await MediaService.searchMediaMetadata(searchRequest)
                if (response.metadataList.length < 1) {
                    setSearchError('No file matching this search was found')
                    setMediaSearchResponse(undefined)
                } else {
                    setMediaSearchResponse(response)
                }
                success = true
            } catch (e: unknown) {
                if (
                    (e as MediaValErrorResponseThrowable).errorResponse
                        ?.mediaValidationErrorMessages
                ) {
                    setSearchValErrors(
                        (e as MediaValErrorResponseThrowable).errorResponse
                            ?.mediaValidationErrorMessages ?? []
                    )
                } else {
                    setSearchError((e as Error).message)
                }
            } finally {
                setIsLoading(false)
                if (success) {
                    searchResultsRef.current?.focus()
                }
            }
        },
        [
            page,
            searchResultsRef,
            mediaService,
            stateFilename,
            stateAuthor,
            stateDate,
            stateFileType,
            tags,
        ]
    )

    const onClearClicked = useCallback(() => {
        setPage(1)
        setStateFileType('')
        setStateDate('')
        setStateAuthor('')
        setStateFilename('')
        setTags([])
        incrementClears()

        const searchRequest: MediaSearchRequest = {
            filename: '',
            author: '',
            date: '',
            fileType: '',
            tags: [],
            page: '1',
        }

        mediaService.current?.setCurrentRequest(searchRequest)
    }, [incrementClears])

    function renderResultCard(
        metadata: MediaMetadataDTO,
        index: number,
        { length }: { length: number }
    ) {
        const label = `Media ${index + 1} of ${length}. Filename ${metadata.filename}, type ${
            metadata.fileType
        }`
        return (
            <View role='listitem' key={`${metadata.s3Path}`} aria-label={label}>
                <SearchResultCard
                    mediaMetadata={metadata}
                    isModuleBuilder={props.isModuleBuilder}
                    updateFields={props.updateFields}
                />
            </View>
        )
    }

    const updateFileType = useCallback((value: string) => {
        setStateFileType(value)

        // must explicitly refocus on file type input
        const refocusTimeoutMs = 600
        setTimeout(() => {
            requestAnimationFrame(() => {
                ;(fileTypeInput.current?.firstChild as HTMLElement | undefined)?.focus()
            })
        }, refocusTimeoutMs)
    }, [])

    const buttons = useMemo(
        () => [
            <Button key={1} onClick={close}>
                Close
            </Button>,
        ],
        [close]
    )

    return (
        <ModalContent maxWidth='90vw' titleText='Media Search' buttons={buttons}>
            <Col flex={1} gridGap='32px'>
                <Card flex={1} flexDirection='column' minWidth='80vw'>
                    {searchError && (
                        <Row justifyContent='center'>
                            <MessageBanner type={MessageBannerType.Error}>
                                {searchError}
                            </MessageBanner>
                        </Row>
                    )}
                    {searchValErrors && searchValErrors.length !== 0 && (
                        <Row justifyContent='center'>
                            <MessageBanner type={MessageBannerType.Error}>
                                {searchValErrors.map((errMessage, index) => (
                                    <li
                                        key={index}
                                        data-cy='search-metadata-validation-error-message'
                                    >
                                        {errMessage.message}
                                    </li>
                                ))}
                            </MessageBanner>
                        </Row>
                    )}
                    <Col flex={1} gridGap='10px'>
                        <InputWrapper id={'userMediaRequest-filename'} labelText='Filename'>
                            {() => (
                                <Input
                                    id={'userMediaRequest-filename'}
                                    type='text'
                                    value={stateFilename}
                                    onChange={(e) => setStateFilename(e.target.value)}
                                />
                            )}
                        </InputWrapper>
                        <InputWrapper id={'userMediaRequest-author'} labelText='Author'>
                            {() => (
                                <Input
                                    id={'userMediaRequest-author'}
                                    type='text'
                                    value={stateAuthor}
                                    onChange={(e) => setStateAuthor(e.target.value)}
                                />
                            )}
                        </InputWrapper>
                        <InputWrapper id={'userMediaRequest-fileType'} labelText='File Type'>
                            {() => (
                                <Select
                                    ref={fileTypeInput}
                                    id={'userMediaRequest-fileType'}
                                    options={fileTypes}
                                    value={stateFileType}
                                    onChange={updateFileType}
                                    disabled={false}
                                />
                            )}
                        </InputWrapper>
                        <InputWrapper id={'userMediaRequest-date'} labelText='Date'>
                            {() => (
                                <Input
                                    id={'userMediaRequest-date'}
                                    type='text'
                                    value={stateDate}
                                    placeholder='yyyy-mm-dd'
                                    onChange={(e) => setStateDate(e.target.value)}
                                />
                            )}
                        </InputWrapper>
                        <TagsInput
                            id='userMediaRequest-tags'
                            tags={tags}
                            setTags={setTags}
                            key={clearCount}
                        />
                        <Spacer height={20} />
                        <Row gridGap={20} alignItems='center'>
                            <Button
                                onClick={() => {
                                    void onSearchClicked(1)
                                }}
                                variant={ButtonVariant.Primary}
                            >
                                Search
                            </Button>
                            <Button
                                onClick={() => {
                                    onClearClicked()
                                }}
                            >
                                Clear
                            </Button>
                        </Row>
                    </Col>
                </Card>
                <View
                    flex={1}
                    justifyContent='center'
                    width='100%'
                    style={{ overflow: 'auto' }}
                    aria-live='polite'
                >
                    {isLoading && <Spinner loadingText='Loading search results...' showText />}
                    {!isLoading && mediaSearchResponse?.metadataList && (
                        <H3 ref={searchResultsRef}>
                            Search results
                            <ScreenReaderOnly>
                                {' '}
                                {describeResults(mediaSearchResponse)}
                            </ScreenReaderOnly>
                        </H3>
                    )}
                    <Row gridGap='S200' role='list' flexWrap='wrap'>
                        {mediaSearchResponse?.metadataList.map(renderResultCard)}
                    </Row>
                </View>
                {mediaSearchResponse && mediaSearchResponse?.metadataList && (
                    <Pagination
                        numberOfPages={mediaSearchResponse?.totalPages}
                        selectedPage={page}
                        onPageSelect={(newPage: number) => {
                            void onSearchClicked(newPage)
                        }}
                    />
                )}
            </Col>
        </ModalContent>
    )
}
