import React from 'react'

import { Button, ButtonVariant } from '@amzn/stencil-react-components/button'
import { PopupDatePicker } from '@amzn/stencil-react-components/date-picker'
import { FlyoutContent, WithFlyout } from '@amzn/stencil-react-components/flyout'
import {
    Checkbox,
    Input,
    InputWrapper,
    LabelPosition,
    Select,
} from '@amzn/stencil-react-components/form'
import { IconSearch, IconSettings } from '@amzn/stencil-react-components/icons'
import { Col, Flex } from '@amzn/stencil-react-components/layout'
import { ScreenReaderOnly } from '@amzn/stencil-react-components/screen-reader-only'
import { H3, Label } from '@amzn/stencil-react-components/text'

import { Locale, LocaleDescription } from 'src/models/dto/Locale'
import { MediaFileType } from 'src/models/media/MediaTemplateDTO'
import { DisplayType, SearchQuery } from 'src/pages/asset-library/types'
import { LAYOUT_DIRECTION, useLayoutDirection } from 'src/pages/search/SearchResultsPage'

export function SearchBar({
    nextSearchQuery,
    setNextSearchQuery,
    executeSearch,
    displayType,
}: {
    nextSearchQuery: SearchQuery
    setNextSearchQuery: (value: (prev: SearchQuery) => SearchQuery) => void
    executeSearch: () => void
    displayType: DisplayType
}) {
    const layout = useLayoutDirection()

    return (
        <>
            <Flex
                gridGap='16px'
                flexDirection={layout === LAYOUT_DIRECTION.MOBILE ? 'column' : 'row'}
            >
                <ScreenReaderOnly>
                    <Label htmlFor='media-search-input'>Search Asset Library</Label>
                </ScreenReaderOnly>
                <Input
                    id='media-search-input'
                    key={'media-search-input'}
                    dataTestId='media-search-input'
                    name='Media Search Input'
                    width={layout === LAYOUT_DIRECTION.MOBILE ? '100%' : '50%'}
                    value={nextSearchQuery.search}
                    onChange={(e) => {
                        setNextSearchQuery((previous: SearchQuery) => ({
                            ...previous,
                            search: e.target.value,
                        }))
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            executeSearch()
                        }
                    }}
                    insetElementLeading={{
                        element: <IconSearch aria-hidden={true} color='neutral60' />,
                    }}
                />
                <Button
                    variant={ButtonVariant.Primary}
                    onClick={() => executeSearch()}
                    aria-label='Search'
                    data-test-id='search-button'
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') executeSearch()
                    }}
                >
                    Search
                </Button>
            </Flex>
            {displayType === DisplayType.ASSET_LIBRARY_PAGE && (
                <InputWrapper
                    id='published-input'
                    labelText={'Include Unpublished'}
                    labelPosition={LabelPosition.Trailing}
                    dataTestId='include-unpublished'
                >
                    {(inputProps) => (
                        <Checkbox
                            {...inputProps}
                            checked={!(nextSearchQuery.englishPublished ?? false)}
                            onChange={() => {
                                setNextSearchQuery((prev: SearchQuery) => {
                                    if (prev.englishPublished === true) {
                                        const { englishPublished: _ignored, ...rest } = prev
                                        return rest
                                    }

                                    return {
                                        ...prev,
                                        englishPublished: true,
                                    }
                                })
                            }}
                        />
                    )}
                </InputWrapper>
            )}
        </>
    )
}

export const SearchResultsFilterForm = ({
    searchQuery,
    setSearchQuery,
    executeSearch,
    lockedFileTypes,
}: {
    searchQuery: SearchQuery
    setSearchQuery: (value: (prevState: SearchQuery) => SearchQuery) => void
    executeSearch: () => void
    lockedFileTypes?: string[]
}) => {
    const layout = useLayoutDirection()

    return (
        <Col gridGap='S300'>
            <fieldset>
                <legend>
                    <H3>Filter</H3>
                </legend>

                <Col gridGap='S300' aria-label='Filters'>
                    <InputWrapper
                        id='created-on-or-after-input'
                        dataTestId='created-on-or-after-input'
                        labelText='Created On Or After'
                    >
                        {(inputProps) => (
                            <PopupDatePicker
                                {...inputProps}
                                value={searchQuery.createdAfterOrAt ?? ''}
                                onChange={(value) => {
                                    setSearchQuery((prevState: SearchQuery) => ({
                                        ...prevState,
                                        createdAfterOrAt: value,
                                    }))
                                }}
                                inputProps={{ width: '100%' }}
                            />
                        )}
                    </InputWrapper>
                    <InputWrapper
                        id='created-on-or-before-input'
                        dataTestId='created-on-or-before-input'
                        labelText='Created On Or Before'
                    >
                        {(inputProps) => (
                            <PopupDatePicker
                                {...inputProps}
                                value={searchQuery.createdBeforeOrAt ?? ''}
                                onChange={(value) => {
                                    setSearchQuery((prevState: SearchQuery) => ({
                                        ...prevState,
                                        createdBeforeOrAt: value,
                                    }))
                                }}
                                inputProps={{ width: '100%' }}
                            />
                        )}
                    </InputWrapper>

                    <InputWrapper
                        id='locale-input'
                        data-test-id='languages-select'
                        labelText='Languages'
                    >
                        {(inputProps) => (
                            <Select
                                {...inputProps}
                                options={Object.keys(Locale)}
                                isMulti={true}
                                onChange={(locales: Locale[]) => {
                                    setSearchQuery((prev: SearchQuery) => ({
                                        ...prev,
                                        locale: locales,
                                    }))
                                }}
                                value={searchQuery.locale}
                                placeholder='Desired languages'
                                getTriggerText={(numberOfSelectedItems) =>
                                    numberOfSelectedItems === 1
                                        ? '1 language selected'
                                        : `${numberOfSelectedItems} languages selected`
                                }
                                renderOption={(option: Locale) => LocaleDescription[option]}
                                renderSelectedValue={(selected: Locale) =>
                                    LocaleDescription[selected]
                                }
                                renderNativeOption={(option: Locale) => LocaleDescription[option]}
                            />
                        )}
                    </InputWrapper>
                    <InputWrapper id='file-name-input' labelText='File name'>
                        {(inputProps) => (
                            <Input
                                {...inputProps}
                                data-test-id='file-name-input'
                                value={searchQuery.defaultFileName ?? ''}
                                placeholder='Default file name'
                                onChange={(e) => {
                                    setSearchQuery((prev: SearchQuery) => ({
                                        ...prev,
                                        defaultFileName: e.target.value,
                                    }))
                                }}
                            />
                        )}
                    </InputWrapper>
                    <InputWrapper id='file-type-select' labelText='File type'>
                        {(inputProps) => (
                            <Select
                                {...inputProps}
                                data-test-id='file-type-select'
                                options={Object.keys(MediaFileType)}
                                isMulti={true}
                                onChange={(mediaFileTypes: MediaFileType[]) => {
                                    setSearchQuery((prev: SearchQuery) => ({
                                        ...prev,
                                        fileType: mediaFileTypes.map((m) => m),
                                    }))
                                }}
                                disabled={!!lockedFileTypes}
                                value={(lockedFileTypes ?? searchQuery.fileType ?? []).map(
                                    (f) => f
                                )}
                                placeholder='File types'
                                getTriggerText={(numberOfSelectedItems) =>
                                    numberOfSelectedItems === 1
                                        ? '1 file type selected'
                                        : `${numberOfSelectedItems} file types selected`
                                }
                            />
                        )}
                    </InputWrapper>
                    <InputWrapper id='created-by-input' labelText='Created by'>
                        {(inputProps) => (
                            <Input
                                {...inputProps}
                                data-test-id='created-by-input'
                                value={searchQuery.createdBy ?? ''}
                                placeholder='Created by'
                                onChange={(e) => {
                                    setSearchQuery((prev: SearchQuery) => ({
                                        ...prev,
                                        createdBy: e.target.value,
                                    }))
                                }}
                            />
                        )}
                    </InputWrapper>
                    {layout !== LAYOUT_DIRECTION.MOBILE && (
                        <>
                            <Button
                                variant={ButtonVariant.Primary}
                                onClick={() => {
                                    executeSearch()
                                }}
                                aria-label='Apply Filters'
                                data-test-id='apply-filters-button'
                            >
                                Apply filters
                            </Button>
                            <Button
                                variant={ButtonVariant.Secondary}
                                onClick={() => {
                                    setSearchQuery((q) => ({
                                        ...q,
                                        createdBy: '',
                                        fileType: [],
                                        defaultFileName: '',
                                        locale: [],
                                        createdBeforeOrAt: '',
                                        createdAfterOrAt: '',
                                    }))
                                }}
                                aria-label='Clear'
                            >
                                Clear
                            </Button>
                        </>
                    )}
                </Col>
            </fieldset>
        </Col>
    )
}

export interface SearchResultsFilterSidebarProps {
    executeSearch: () => void
    nextSearchQuery: SearchQuery
    setNextSearchQuery: (value: (prevState: SearchQuery) => SearchQuery) => void
    lockedFileTypes?: string[]
}

export const useRenderFlyout = ({
    executeSearch,
    nextSearchQuery,
    setNextSearchQuery,
    lockedFileTypes,
}: SearchResultsFilterSidebarProps) => {
    const RenderFlyout = ({ close }: { close: () => void }) => {
        return (
            <FlyoutContent
                titleText='Filters'
                onCloseButtonClick={close}
                buttons={[
                    <Button
                        key='done-button'
                        dataTestId='apply-filters-button'
                        onClick={() => {
                            executeSearch()
                            close()
                        }}
                        variant={ButtonVariant.Primary}
                    >
                        Apply filters
                    </Button>,
                    <Button
                        key='cancel-button'
                        dataTestId='clear-filters-button'
                        variant={ButtonVariant.Secondary}
                        onClick={() => {
                            setNextSearchQuery((q) => ({
                                ...q,
                                createdBy: '',
                                fileType: [],
                                defaultFileName: '',
                                locale: [],
                                createdBeforeOrAt: '',
                                createdAfterOrAt: '',
                            }))
                        }}
                        aria-label='Clear'
                    >
                        Clear
                    </Button>,
                ]}
            >
                <SearchResultsFilterForm
                    executeSearch={executeSearch}
                    searchQuery={nextSearchQuery}
                    setSearchQuery={setNextSearchQuery}
                    lockedFileTypes={lockedFileTypes}
                />
            </FlyoutContent>
        )
    }
    RenderFlyout.displayName = 'RenderFlyout'
    return RenderFlyout
}

export function SearchResultsFilterSidebar(props: SearchResultsFilterSidebarProps) {
    const { executeSearch, nextSearchQuery, setNextSearchQuery, lockedFileTypes } = props
    const layout = useLayoutDirection()
    const renderFlyout = useRenderFlyout(props)

    if (layout === LAYOUT_DIRECTION.MOBILE || layout === LAYOUT_DIRECTION.TABLET) {
        return (
            <WithFlyout renderFlyout={renderFlyout}>
                {({ open }) => (
                    <Button
                        dataTestId='open-search-filters'
                        variant={ButtonVariant.Secondary}
                        icon={<IconSettings aria-hidden={true} />}
                        onClick={open}
                    >
                        Filters
                    </Button>
                )}
            </WithFlyout>
        )
    }

    return (
        <Col width='20%' minWidth={'20%'} maxWidth={'20%'}>
            <SearchResultsFilterForm
                executeSearch={executeSearch}
                searchQuery={nextSearchQuery}
                setSearchQuery={setNextSearchQuery}
                lockedFileTypes={lockedFileTypes}
            />
        </Col>
    )
}
