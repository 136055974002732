import React from 'react'

import { Col, Row, Spacer } from '@amzn/stencil-react-components/layout'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'
import { useBreakpoints } from '@amzn/stencil-react-components/responsive'

import { IconWithTooltip } from 'src/components/IconWithTooltip'
import { isDefaultLocale } from 'src/contexts/ModuleLocaleContext'
import { useEntity } from 'src/hooks/useEntity'
import { AudioCheckItemDTO, SoundCheckMLModelType } from 'src/models/dto/items/AudioCheckItemDTO'
import { GENERIC_ERROR_KEY, ValidationErrorEntity } from 'src/models/dto/TemplateValidationError'
import {
    disallowedMlModelVersionsForSoundCheck,
    getOptions,
    maxNumAttemptsOptions,
    maxNumAttemptsToName,
    maxNumAttemptsToValues,
    maxRecordingTimeOptions,
    maxRecordingToName,
    maxRecordingToValues,
    mlModelVersionsToValues,
} from 'src/pages/module-builder/item-editors/AudioEvaluationItemEditor'
import { ItemEditorProps } from 'src/pages/module-builder/item-editors/ItemEditorContainer'
import {
    GenericItemEditorSelectInput,
    ItemEditorCheckboxInput,
    ItemEditorTextArea,
    ItemEditorTextInput,
} from 'src/pages/module-builder/item-editors/ItemEditorInputs'
import { ITEM_ENTITY_STORE_SELECTOR } from 'src/services/EntityServices/ItemEntityService'
import { AudioCheckHandler } from 'src/services/EntityServices/ItemUpdateHandlers/AudioCheckHandler'
import {
    VALIDATION_ERROR_ENTITY_STORE_SELECTOR,
    ValidationErrorEntityService,
} from 'src/services/EntityServices/ValidationErrorEntityService'

const soundCheckModelTypeDisplayNames = new Map<SoundCheckMLModelType, string>([
    [SoundCheckMLModelType.ACE_SALES_SOUND_CHECK_MODEL, 'Ace Sales Sound Check'],
    [SoundCheckMLModelType.AA_SOUND_CHECK, 'AA Sound Check'],
])

const soundCheckDisplayNamesToValues: Map<string, SoundCheckMLModelType> = new Map<
    string,
    SoundCheckMLModelType
>(
    Array.from(soundCheckModelTypeDisplayNames).map((rec) => {
        rec.reverse()
        return rec as [string, SoundCheckMLModelType]
    })
)

const soundCheckModelVersions = ['1.0', '2.0']
const soundCheckModelTypeOptions: string[] = getOptions(soundCheckModelTypeDisplayNames)

const mlModelDisallowedMessage =
    'AA_SOUND_CHECK version 1.0 is no longer available, Please update the module to use version 2.0'

export const AudioCheckItemEditor = ({
    itemId,
    locale,
    showValidationError,
    editDisabled,
}: ItemEditorProps) => {
    const { entity: itemDTO } = useEntity<AudioCheckItemDTO>({
        entityId: itemId,
        selector: ITEM_ENTITY_STORE_SELECTOR,
    })
    const { matches } = useBreakpoints()

    if (!ValidationErrorEntityService.has(itemId)) {
        ValidationErrorEntityService.create(itemId)
    }

    const { entity: validationErrorEntity } = useEntity<ValidationErrorEntity>({
        entityId: itemId,
        selector: VALIDATION_ERROR_ENTITY_STORE_SELECTOR,
    })

    const flexItemWidth = matches.s ? '100%' : 'default'
    const UNIQUE_ITEM_ID_FLEX_BASIS = matches.s ? '100%' : '200px'
    const OPTIONAL_CHECKBOX_FLEX_BASIS = matches.s ? '100%' : '80px'
    const SELECT_INPUT_FLEX_BASIS = `2 0 ${matches.s ? '100%' : '150px'}`

    return (
        <>
            {validationErrorEntity.validationErrors[GENERIC_ERROR_KEY] && showValidationError && (
                <MessageBanner type={MessageBannerType.Error}>
                    <ul>
                        {validationErrorEntity.validationErrors[GENERIC_ERROR_KEY].map(
                            (message, index) => (
                                <li key={index}>{message}</li>
                            )
                        )}
                    </ul>
                </MessageBanner>
            )}
            <Row gridGap='S300' alignItems={'flex-start'} flexWrap={'wrap'}>
                <Col flex={`3 0 ${UNIQUE_ITEM_ID_FLEX_BASIS}`}>
                    <ItemEditorTextInput
                        inputId={'unique-item-id'}
                        dataTestId={'unique-item-id'}
                        value={itemDTO.label}
                        placeholder={'Some human readable label'}
                        disabled={!isDefaultLocale(locale) || editDisabled}
                        itemId={itemDTO.id}
                        setValue={(nextValue: string) => {
                            AudioCheckHandler.updateLabel(itemDTO.id, nextValue)
                        }}
                        validationErrorMessage={(
                            validationErrorEntity.validationErrors.label ?? []
                        ).join(', ')}
                        showError={showValidationError}
                        labelText={'Unique Item ID'}
                    />
                </Col>
                <Col
                    gridGap={'S200'}
                    padding={{ top: `${matches.s ? '8px' : '35px'}` }}
                    flex={`0 0 ${OPTIONAL_CHECKBOX_FLEX_BASIS}`}
                >
                    <ItemEditorCheckboxInput
                        inputId={'optional'}
                        itemId={itemDTO.id}
                        labelText={'Optional'}
                        disabled={!isDefaultLocale(locale) || editDisabled}
                        dataTestId={'optional-checkbox'}
                        value={itemDTO.optional}
                        setValue={(nextValue: boolean) => {
                            AudioCheckHandler.updateOptional(itemDTO.id, nextValue)
                        }}
                    />
                </Col>
                <Col
                    gridGap={'S200'}
                    padding={{ top: `${matches.s ? '8px' : '29px'}` }}
                    flex={'0 0 170px'}
                >
                    <Row alignItems={'center'}>
                        <ItemEditorCheckboxInput
                            inputId={'preserve-order'}
                            itemId={itemDTO.id}
                            labelText={'Preserve order'}
                            disabled={!isDefaultLocale(locale) || editDisabled}
                            dataTestId={'preserve-order-checkbox'}
                            value={itemDTO.preserveOrder}
                            setValue={(nextValue: boolean) => {
                                AudioCheckHandler.updatePreserveOrder(itemDTO.id, nextValue)
                            }}
                        />
                        <IconWithTooltip tooltipText='When randomizing page, ensure this items position is never changed' />
                    </Row>
                </Col>
                <Col flex={SELECT_INPUT_FLEX_BASIS}>
                    <GenericItemEditorSelectInput
                        valueToDisplayNames={maxNumAttemptsToName}
                        inputId={'audio-check-max-attempts'}
                        dataTestId={'max-number-of-attempts'}
                        itemId={itemDTO.id}
                        labelText={'Maximum Attempts'}
                        disabled={!isDefaultLocale(locale) || editDisabled}
                        options={maxNumAttemptsOptions}
                        displayNamesToValues={maxNumAttemptsToValues}
                        value={itemDTO.maxNumberOfAttempts}
                        setValue={(nextValue) => {
                            AudioCheckHandler.updateMaxNumberOfAttempts(itemDTO.id, nextValue)
                        }}
                        validationErrorMessage={(
                            validationErrorEntity.validationErrors.maxNumberOfAttempts ?? []
                        ).join(', ')}
                        showError={showValidationError}
                    />
                </Col>
                <Col flex={SELECT_INPUT_FLEX_BASIS}>
                    <GenericItemEditorSelectInput
                        valueToDisplayNames={maxRecordingToName}
                        inputId={'audio-check-max-recording-time'}
                        dataTestId={'max-recording-time-in-seconds'}
                        itemId={itemDTO.id}
                        labelText={'Maximum Recording Time (sec)'}
                        disabled={!isDefaultLocale(locale) || editDisabled}
                        options={maxRecordingTimeOptions}
                        displayNamesToValues={maxRecordingToValues}
                        value={itemDTO.maxRecordingTimeInSeconds}
                        setValue={(nextValue) => {
                            AudioCheckHandler.updateMaxRecordingTimeInSeconds(itemDTO.id, nextValue)
                        }}
                        validationErrorMessage={(
                            validationErrorEntity.validationErrors.maxRecordingTimeInSeconds ?? []
                        ).join(', ')}
                        showError={showValidationError}
                    />
                </Col>
            </Row>
            <Spacer height={'S200'} />
            <Row gridGap='S300' alignItems={'flex-start'} flexWrap={'wrap'}>
                <Col flex={SELECT_INPUT_FLEX_BASIS}>
                    <GenericItemEditorSelectInput
                        valueToDisplayNames={soundCheckModelTypeDisplayNames}
                        inputId={'audio-check-sound-check-ml-model'}
                        itemId={itemDTO.id}
                        labelText={'Sound Check ML Model'}
                        dataTestId={'sound-check-ml-model'}
                        options={soundCheckModelTypeOptions}
                        disabled={!isDefaultLocale(locale) || editDisabled}
                        displayNamesToValues={soundCheckDisplayNamesToValues}
                        value={itemDTO.soundCheckMLModel}
                        setValue={(nextValue) => {
                            AudioCheckHandler.updateSoundCheckMlModel(itemDTO.id, nextValue)
                        }}
                        validationErrorMessage={(
                            validationErrorEntity.validationErrors.soundCheckMLModel ?? []
                        ).join(', ')}
                        showError={showValidationError}
                    />
                </Col>
                <Col flex={SELECT_INPUT_FLEX_BASIS}>
                    <GenericItemEditorSelectInput
                        disallowedOptions={disallowedMlModelVersionsForSoundCheck}
                        valueToDisplayNames={mlModelVersionsToValues}
                        disallowedErrorMessage={mlModelDisallowedMessage}
                        inputId={'audio-check-sound-check-ml-model-version'}
                        dataTestId={'sound-check-ml-model-version'}
                        itemId={itemDTO.id}
                        labelText={'Sound Check ML Model Version'}
                        options={soundCheckModelVersions}
                        disabled={!isDefaultLocale(locale) || editDisabled}
                        displayNamesToValues={mlModelVersionsToValues}
                        value={itemDTO.soundCheckMLModelVersion}
                        setValue={(nextValue) => {
                            AudioCheckHandler.updateSoundCheckMlModelVersion(itemDTO.id, nextValue)
                        }}
                        validationErrorMessage={(
                            validationErrorEntity.validationErrors.soundCheckMLModelVersion ?? []
                        ).join(', ')}
                        showError={showValidationError}
                    />
                </Col>
            </Row>
            <Spacer height={'S200'} />
            <Row alignItems={'center'}>
                <Col flexGrow={1} width={flexItemWidth}>
                    <ItemEditorTextArea
                        inputId={'prompt'}
                        locale={locale}
                        value={itemDTO.promptI18N[locale] ?? ''}
                        disabled={editDisabled}
                        itemId={itemDTO.id}
                        setValue={(nextValue: string) => {
                            AudioCheckHandler.updatePrompt(itemDTO.id, locale, nextValue)
                        }}
                        validationErrorMessage={(
                            validationErrorEntity.validationErrors.promptI18N ?? []
                        ).join(', ')}
                        showError={showValidationError}
                        labelText={'Prompt'}
                        dataTestId={'audio-check-prompt-editor'}
                    />
                </Col>
            </Row>
            <Spacer height={'S200'} />
            <Row alignItems={'center'}>
                <Col flexGrow={1} width={flexItemWidth}>
                    <ItemEditorTextArea
                        inputId={'sentence'}
                        locale={locale}
                        value={itemDTO.sentenceI18N[locale] ?? ''}
                        disabled={editDisabled}
                        itemId={itemDTO.id}
                        setValue={(nextValue: string) => {
                            AudioCheckHandler.updateSentence(itemDTO.id, locale, nextValue)
                        }}
                        validationErrorMessage={(
                            validationErrorEntity.validationErrors.sentenceI18N ?? []
                        ).join(', ')}
                        showError={showValidationError}
                        labelText={'Sentence'}
                        dataTestId={'audio-check-sentence-editor'}
                    />
                </Col>
            </Row>
        </>
    )
}
