import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { AxiosError } from 'axios'
import useAxios from 'axios-hooks'
import { Base64 } from 'js-base64'
import { noop } from 'lodash'
import { DateTime } from 'luxon'
import { parse } from 'node-webvtt'

import { Button, ButtonSize, ButtonVariant } from '@amzn/stencil-react-components/button'
import { FileUpload } from '@amzn/stencil-react-components/file-upload'
import {
    Checkbox,
    Input,
    InputHeader,
    InputWrapper,
    LabelPosition,
} from '@amzn/stencil-react-components/form'
import { IconCheck } from '@amzn/stencil-react-components/icons'
import { Col, Container, Hr, Row, Spacer } from '@amzn/stencil-react-components/layout'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'
import { ModalContent, WithModal } from '@amzn/stencil-react-components/modal'
import { useBreakpoints } from '@amzn/stencil-react-components/responsive'
import { ScreenReaderOnly } from '@amzn/stencil-react-components/screen-reader-only'
import { Spinner } from '@amzn/stencil-react-components/spinner'
import { H1, Span, Text } from '@amzn/stencil-react-components/text'

import { DropdownButton } from 'src/components/DropdownButton'
import { LocaleDropdown } from 'src/components/LocaleSelector'
import { ResponsiveRow } from 'src/components/ResponsiveRow'
import { APP_CONFIG } from 'src/config.app'
import { ErrorResponse } from 'src/models/dto/ErrorResponse'
import { Locale, LocaleDescription, LocalizedAttribute } from 'src/models/dto/Locale'
import { MediaFileType, MediaTemplateDTO } from 'src/models/media/MediaTemplateDTO'
import { MediaErrorTemplate } from 'src/models/media/MediaValidationError'
import { DuplicateMediaModal } from 'src/pages/asset-library/DuplicateMediaModal'
import { FileView } from 'src/pages/asset-library/FileView'
import {
    CreateMediaTemplateRequest,
    GetMediaTemplateResponse,
    UpdateMediaLanguageRequest,
    UpdateMediaTemplateRequest,
} from 'src/pages/asset-library/HttpClientModels'
import { WithFixedFooter } from 'src/pages/asset-library/WithFixedFooter'
import { ApiActionNames } from 'src/services/AxiosInterceptor'
import {
    LocalMediaFilesService,
    LocalMediaFilesServiceFactory,
} from 'src/services/media/LocalMediaFilesService'
import { MediaService } from 'src/services/media/MediaService'

const getAssetType = (fileType: string) => {
    switch (fileType) {
        case MediaFileType.PNG:
        case MediaFileType.JPEG:
        case MediaFileType.GIF:
            return 'Image'
        case MediaFileType.WAV:
        case MediaFileType.MP3:
            return 'Audio'
        case MediaFileType.MP4:
        case MediaFileType.MOV:
            return 'Video'
    }
}

export interface RenderMediaOptionsModalProps {
    close: () => void
    duplicateMedia: (newTitle: string) => void
}

enum AttachmentType {
    CAPTIONS = 'captions',
    TRANSCRIPTS = 'transcripts',
    ASSET_FILE = 'asset file',
}

export type MediaTemplateModel = MediaTemplateDTO & { newlyPublished?: Locale; versionId?: string }

const AttachmentUpload = ({
    filePath,
    fileName,
    error,
    disabled,
    attachmentType,
    setSaved,
    setFileMetadata,
    acceptedMimeTypes,
    setCaptionsErrorMap,
}: {
    filePath: string
    fileName: string
    setFileMetadata: (path: string, fileName: string, fileType: string) => void
    error: string
    disabled: boolean
    attachmentType: AttachmentType
    setSaved: React.Dispatch<React.SetStateAction<boolean>>
    acceptedMimeTypes: string
    setCaptionsErrorMap?: (errorMessage: string) => void
}) => {
    const fileUploadRef = useRef<HTMLDivElement | null>(null)
    const [loading, setLoading] = useState(false)
    const [unexpectedError, setUnexpectedError] = useState(false)
    const [file, setFile] = useState('')
    const localMediaFilesService = useRef<LocalMediaFilesService>()

    useEffect(() => {
        localMediaFilesService.current = LocalMediaFilesServiceFactory.fromScratch()
    }, [])

    // Work around the issue where it's not possible to assign an ID to Stencil FileUpload
    useEffect(() => {
        const fileInput = fileUploadRef.current?.querySelector('input[type=file]')
        if (fileInput) {
            fileInput.id = `${attachmentType}-file-input`
        }
    }, [fileUploadRef, attachmentType])

    const uploadToS3 = async (f: File) => {
        if (localMediaFilesService.current) {
            localMediaFilesService.current.set(f, true)
            const relativeS3Path: string = localMediaFilesService.current.generateRelativeS3Path(
                f.name
            )
            await localMediaFilesService.current.uploadToS3(f.name, relativeS3Path)
            return relativeS3Path
        }
    }

    useEffect(() => {
        if (filePath) {
            MediaService.getFromS3(filePath)
                .then((val) => {
                    setFile(val)
                })
                .catch(() => {
                    setUnexpectedError(true)
                })
        }
    }, [filePath, setFile, setUnexpectedError])

    const onFileAttached = async (files: File[]) => {
        /*
            Related to AA-19611 (https://sim.amazon.com/issues/AA-19611) issue where Closed Caption file cannot be
            uploaded on Chrome Browser on Windows platform.
            This is most likely a Chrome issue as file upload API cannot decipher the mimeType of text/vtt file and the
            type field is left as blank. A juggling-check is used intentionally to check for both undefined and null.
            Since file type for caption file is not used, it is okay to be a blank string.
        */
        if (files && files.length == 1 && files[0].type != null) {
            setLoading(true)
            if (files[0].type === 'text/vtt' && setCaptionsErrorMap) {
                try {
                    //eslint-disable-next-line @typescript-eslint/no-unsafe-call
                    parse(await files[0].text())
                    setCaptionsErrorMap('')
                } catch (e: any) {
                    console.error(e)
                    setCaptionsErrorMap((e as Error).message)
                }
            }
            const relativeS3Path = await uploadToS3(files[0])
            setLoading(false)
            if (relativeS3Path) {
                setSaved(false)
                setFileMetadata(relativeS3Path, files[0].name, files[0].type)
            }
        }
    }

    const labelText =
        attachmentType === AttachmentType.CAPTIONS
            ? 'Captions Information'
            : attachmentType === AttachmentType.TRANSCRIPTS
            ? 'Transcripts Information (Optional)'
            : 'Locale Specific File'

    if (filePath) {
        return (
            <Col flex={1}>
                <InputHeader htmlFor={`${attachmentType}-file`} labelText={`${labelText}`} />
                {error.length > 0 && (
                    <MessageBanner type={MessageBannerType.Error}>{error}</MessageBanner>
                )}
                <Row gridGap={'S200'} padding={{ top: 'S400' }}>
                    {unexpectedError ? (
                        <Text color={'red70'}>Error fetching {attachmentType} file</Text>
                    ) : (
                        <a
                            href={file}
                            target={'_blank'}
                            download={`${fileName}`}
                            rel='noreferrer'
                            id={`${attachmentType}-file`}
                        >
                            <Text>{fileName}</Text>
                        </a>
                    )}
                    <Spacer flex={1} />
                    <Button
                        id={`delete-${attachmentType}-button`}
                        size={ButtonSize.Small}
                        isDestructive
                        aria-disabled={disabled}
                        variant={ButtonVariant.Tertiary}
                        onClick={() => {
                            setSaved(false)
                            setFileMetadata('', '', '')
                            if (AttachmentType.CAPTIONS && setCaptionsErrorMap) {
                                setCaptionsErrorMap('')
                            }
                        }}
                    >
                        Delete {attachmentType}
                    </Button>
                </Row>
            </Col>
        )
    }

    return (
        <Col flex={1} dataTestId={`${attachmentType}-file-input`} gridGap='S200'>
            <InputHeader htmlFor={`${attachmentType}-file-input`} labelText={labelText} />
            {loading ? (
                <Col flex={1} alignItems={'center'} alignSelf={'stretch'}>
                    <Spinner />
                </Col>
            ) : (
                <>
                    {error.length > 0 && (
                        <MessageBanner type={MessageBannerType.Error}>{error}</MessageBanner>
                    )}
                    <FileUpload
                        ref={fileUploadRef}
                        disabled={disabled}
                        onFileAttached={onFileAttached}
                        isMulti={false}
                        accept={acceptedMimeTypes}
                        uploadButtonTitle={`Add ${attachmentType} to upload`}
                        dragAndDropText={'or drag and drop'}
                        id={`${attachmentType}-file-input`}
                        errorText={error}
                    />
                </>
            )}
        </Col>
    )
}

const MediaFooter = ({ children }: { children: React.ReactNode }) => {
    return (
        <footer>
            <Container
                height='75px'
                backgroundColor='neutral90'
                paddingHorizontal={'S400'}
                style={{ position: 'fixed', bottom: 0, zIndex: 9 }}
                dataTestId={'upload-page-footer'}
                alignItems='center'
                justifyContent='center'
            >
                <Row alignItems='center' justifyContent='center' height={'100%'}>
                    {children}
                </Row>
            </Container>
        </footer>
    )
}

const CreateMediaFooter = ({
    createMediaRequest,
    loading,
}: {
    createMediaRequest: () => void
    loading: boolean
}) => {
    const navigate = useNavigate()

    if (loading) {
        return (
            <MediaFooter>
                <Spinner dataTestId={'creating-spinner'} />
            </MediaFooter>
        )
    }
    return (
        <MediaFooter>
            <Row margin={{ left: 'auto' }} gridGap={'S200'}>
                <Button
                    dataTestId={'cancel-edit-media-button'}
                    variant={ButtonVariant.Tertiary}
                    onClick={() => {
                        navigate('/asset-library')
                    }}
                >
                    <Text color='neutral0'>Cancel</Text>
                </Button>
                <Button
                    dataTestId={'create-asset-button'}
                    variant={ButtonVariant.Primary}
                    onClick={() => {
                        createMediaRequest()
                    }}
                >
                    Create Asset
                </Button>
            </Row>
        </MediaFooter>
    )
}

export const LastUpdatedTime = ({
    mediaTemplateModel,
}: {
    mediaTemplateModel: MediaTemplateModel
}) => {
    const lastUpdatedAtInSystemZone = DateTime.fromISO(mediaTemplateModel.lastUpdatedAt, {
        zone: 'UTC',
    }).setZone('system')

    return (
        <Span dataTestId='last-modified-footer-date' fontSize='T200' color='neutral0'>
            Last Saved:{' '}
            {lastUpdatedAtInSystemZone.toLocaleString({
                month: 'numeric',
                day: 'numeric',
            })}{' '}
            at {lastUpdatedAtInSystemZone.toLocaleString(DateTime.TIME_SIMPLE)} by{' '}
            {mediaTemplateModel.lastUpdatedBy}
        </Span>
    )
}

const UpdateMediaFooter = ({
    mediaTemplateModel,
    setMediaTemplate,
    updateMediaRequest,
    updateLoading,
    locale,
    saved,
    hasCaptionValidationError,
}: {
    mediaTemplateModel: MediaTemplateModel
    setMediaTemplate: React.Dispatch<React.SetStateAction<MediaTemplateModel>>
    updateMediaRequest: () => Promise<void>
    updateLoading: boolean
    locale: Locale
    saved: boolean
    hasCaptionValidationError: boolean
}) => {
    const renderModal = ({ close }: { close: () => void }) => (
        <ModalContent
            titleText={`You are publishing the ${LocaleDescription[locale]} locale`}
            buttons={[
                <Button
                    onClick={() => {
                        setMediaTemplate((prev) => {
                            return {
                                ...prev,
                                newlyPublished: locale,
                            }
                        })
                    }}
                    variant={ButtonVariant.Primary}
                    key={'publish-button'}
                    id={'publish-modal-button'}
                    dataTestId={'publish-modal-button'}
                >
                    Publish
                </Button>,
                <Button
                    onClick={() => {
                        close()
                    }}
                    key={'close-button'}
                    id={'close-publish-modal'}
                    dataTestId={'close-publish-modal'}
                >
                    Go Back
                </Button>,
            ]}
            closeButtonAltText={'close modal'}
        >
            <Text>
                It will no longer be possible to edit the media once published, but it will become
                assignable to a module within the module editor page.
            </Text>
        </ModalContent>
    )

    return (
        <MediaFooter>
            <LastUpdatedTime mediaTemplateModel={mediaTemplateModel} />
            <Spacer flex={1} />
            <Spacer width={'S200'} />
            {updateLoading ? (
                <Spinner dataTestId={'loading-spinner'} />
            ) : (
                <>
                    <Button
                        dataTestId={'save-asset-button'}
                        onClick={() => {
                            if (hasCaptionValidationError) {
                                return
                            }
                            updateMediaRequest().catch(() => noop())
                        }}
                        disabled={saved}
                        icon={saved ? <IconCheck title='' aria-hidden /> : undefined}
                    >
                        {saved ? 'Asset Saved' : 'Save Asset'}
                    </Button>
                    <Spacer width={'S200'} />
                    <WithModal renderModal={renderModal} shouldCloseOnClickOutside={false}>
                        {({ open }) => (
                            <Col gridGap='S500' alignItems='flex-end'>
                                <>
                                    <Button
                                        variant={ButtonVariant.Primary}
                                        dataTestId={'publish-asset-button'}
                                        disabled={mediaTemplateModel.languages[locale]?.published}
                                        onClick={() => {
                                            if (hasCaptionValidationError) {
                                                return
                                            }
                                            open()
                                        }}
                                    >
                                        {mediaTemplateModel.languages[locale]?.published
                                            ? `${LocaleDescription[locale]} is published`
                                            : `Publish ${LocaleDescription[locale]}`}
                                    </Button>
                                </>
                            </Col>
                        )}
                    </WithModal>
                </>
            )}
        </MediaFooter>
    )
}

const ModalMediaFooter = ({
    useMedia: onUseMedia,
    cancel,
    mediaTemplateModel,
}: {
    useMedia?: () => void
    cancel?: () => void
    mediaTemplateModel: MediaTemplateModel
}) => {
    return (
        <MediaFooter>
            <LastUpdatedTime mediaTemplateModel={mediaTemplateModel} />
            <Spacer flex={1} />
            <Spacer width={'S200'} />
            {cancel && (
                <Button
                    dataTestId={'cancel-button'}
                    onClick={() => {
                        cancel()
                    }}
                    variant={ButtonVariant.Secondary}
                >
                    Go back
                </Button>
            )}
            <Spacer width={'S200'} />
            {onUseMedia && (
                <Button
                    dataTestId={'use-media-button'}
                    onClick={() => {
                        onUseMedia?.()
                    }}
                    variant={ButtonVariant.Primary}
                >
                    Use media
                </Button>
            )}
            <Spacer width={'S200'} />
        </MediaFooter>
    )
}

const CaptionsAndTranscriptions = ({
    mediaTemplateModel,
    locale,
    errorMap,
    setMediaTemplateModel,
    setSaved,
}: {
    mediaTemplateModel: MediaTemplateModel
    locale: Locale
    errorMap: Record<string, string>
    setMediaTemplateModel: React.Dispatch<React.SetStateAction<MediaTemplateModel>>
    setSaved: React.Dispatch<React.SetStateAction<boolean>>
}) => {
    const {
        matches: { s: small },
    } = useBreakpoints()

    if (getAssetType(mediaTemplateModel.fileType) === 'Image') {
        return null
    }

    const disabled = mediaTemplateModel.languages[locale]?.published ?? false

    const updateCaptionsErrorMap = (errorMessage: string) => {
        errorMap[`languages[${locale}].captionsPath`] = errorMessage
    }

    return (
        <>
            <Spacer height={'S400'} />
            <ResponsiveRow>
                <AttachmentUpload
                    acceptedMimeTypes={'.vtt'}
                    attachmentType={AttachmentType.CAPTIONS}
                    setSaved={setSaved}
                    disabled={disabled}
                    filePath={mediaTemplateModel.languages[locale]?.captionsPath ?? ''}
                    fileName={mediaTemplateModel.languages[locale]?.captionsFileName ?? ''}
                    error={errorMap[`languages[${locale}].captionsPath`] ?? ''}
                    setFileMetadata={(path: string, fileName: string) => {
                        setMediaTemplateModel((prev) => {
                            return {
                                ...prev,
                                languages: {
                                    ...prev.languages,
                                    [locale]: {
                                        ...prev.languages[locale],
                                        captionsPath: path,
                                        captionsFileName: fileName,
                                    },
                                },
                            }
                        })
                    }}
                    setCaptionsErrorMap={updateCaptionsErrorMap}
                />
                <Spacer width={'S400'} />
                {small && <Spacer height={'S400'} />}
                <Col flex={1}>
                    <AttachmentUpload
                        acceptedMimeTypes={'.txt, .html, .docx, .rtf'}
                        attachmentType={AttachmentType.TRANSCRIPTS}
                        setSaved={setSaved}
                        disabled={disabled}
                        filePath={mediaTemplateModel.languages[locale]?.transcriptsPath ?? ''}
                        fileName={mediaTemplateModel.languages[locale]?.transcriptsFileName ?? ''}
                        error={errorMap[`languages[${locale}].transcriptsPath`] ?? ''}
                        setFileMetadata={(path: string, fileName: string) => {
                            setMediaTemplateModel((prev) => {
                                return {
                                    ...prev,
                                    hideTranscriptInMedia: false,
                                    languages: {
                                        ...prev.languages,
                                        [locale]: {
                                            ...prev.languages[locale],
                                            transcriptsPath: path,
                                            transcriptsFileName: fileName,
                                        },
                                    },
                                }
                            })
                        }}
                    />
                    <Spacer height={'S200'} />
                    {mediaTemplateModel.languages[Locale.en_US]?.transcriptsPath && (
                        <Row>
                            <Col flex={1}>
                                <InputWrapper
                                    id='transcript-hide-input'
                                    labelText={'Hide transcript in media'}
                                    labelPosition={LabelPosition.Trailing}
                                    tooltipText={
                                        'Some media requires a transcription file, but should not show the transcript in the media.'
                                    }
                                >
                                    {(inputProps) => (
                                        <Checkbox
                                            {...inputProps}
                                            checked={
                                                mediaTemplateModel?.hideTranscriptInMedia ?? false
                                            }
                                            onChange={() => {
                                                setSaved(false)
                                                setMediaTemplateModel((prev) => {
                                                    return {
                                                        ...prev,
                                                        hideTranscriptInMedia:
                                                            !prev?.hideTranscriptInMedia,
                                                    }
                                                })
                                            }}
                                            disabled={
                                                mediaTemplateModel.languages[locale]?.published ||
                                                locale !== Locale.en_US
                                            }
                                        />
                                    )}
                                </InputWrapper>
                            </Col>
                        </Row>
                    )}
                </Col>
            </ResponsiveRow>
        </>
    )
}

export const ErrorPage = ({ errorMessage }: { errorMessage: string }) => {
    return (
        <Container paddingTop={'S400'}>
            <Col
                display='flex'
                justifyContent='center'
                minHeight='70vh'
                alignItems='center'
                gridGap='S200'
                padding={{ top: 'S200' }}
            >
                <Row alignItems={'center'} justifyContent={'center'} gridGap={'S200'}>
                    <Text fontSize={'T500'} textAlign={'center'} color='neutral90'>
                        {errorMessage}
                    </Text>
                </Row>
                <Text fontSize={'T400'} color='neutral70'>
                    Please press the back button or try refreshing the page.
                </Text>
            </Col>
        </Container>
    )
}

export const UploadFileTranslation = ({
    locale,
    validationErrors,
    setMedia,
    setSaved,
    mediaDTO,
}: {
    locale: Locale
    validationErrors: Record<string, string>
    setSaved: React.Dispatch<React.SetStateAction<boolean>>
    mediaDTO: MediaTemplateModel
    setMedia: React.Dispatch<React.SetStateAction<MediaTemplateModel>>
}) => {
    const accepted = `${getAssetType(mediaDTO.fileType)!.toLowerCase()}/*`

    return (
        <AttachmentUpload
            acceptedMimeTypes={accepted}
            filePath={''}
            fileName={''}
            setFileMetadata={(path: string, fileName: string, fileType: string) => {
                setMedia((prev) => {
                    return {
                        ...prev,
                        languages: {
                            ...prev.languages,
                            [locale]: {
                                ...prev.languages[locale],
                                filePath: path,
                                fileName: fileName,
                                fileType: fileType,
                            },
                        },
                    }
                })
            }}
            error={validationErrors[`languages[${locale}].filePath`] ?? ''}
            disabled={false}
            attachmentType={AttachmentType.ASSET_FILE}
            setSaved={setSaved}
        />
    )
}

export const EditAssetPage = ({
    mediaId,
    useMedia,
    cancel,
    isEmbedded = false,
}: {
    mediaId?: string
    useMedia?: () => void
    cancel?: () => void
    isEmbedded?: boolean
}) => {
    const { versionId } = useParams()
    const [searchParams, setSearchParams] = useSearchParams()
    const [mediaVersionId, setMediaVersionId] = useState<string | undefined>(versionId || mediaId)
    const navigate = useNavigate()
    const [locale, setLocale] = useState<Locale>(
        Locale[searchParams.get('locale') ?? 'en_US'] as Locale
    )
    const [assetLoading, setAssetLoading] = useState<boolean>(true)
    const [errorLoadingAsset, setErrorLoadingAsset] = useState<boolean>(true)
    const [fileUrl, setFileUrl] = useState<string>('')
    const [fileType, setFileType] = useState<string>('')
    const [currentFilePath, setCurrentFilePath] = useState<string>('')
    const [updatedCaptionsUrl, setUpdatedCaptionsUrl] = useState<string>('')
    const [captionsPath, setCaptionsPath] = useState<string>('')
    const [errorMap, setErrorMap] = useState<Record<string, string>>({})
    const [headerElement, setHeaderElement] = useState<HTMLElement | null>(null)
    const [displayAssetSavedBanner, setDisplayAssetSavedBanner] = useState<boolean>(false)
    const [displayErrorSavingAssetBanner, setDisplayErrorSavingAssetBanner] =
        useState<boolean>(false)
    const [displayAssetPublishedBanner, setDisplayAssetPublishedBanner] = useState<boolean>(false)
    const [displayErrorPublishingBanner, setDisplayErrorPublishingBanner] = useState<boolean>(false)

    const [mediaTemplateModel, setMediaTemplateModel] = useState<MediaTemplateModel | undefined>(
        searchParams.get('asset')
            ? (JSON.parse(Base64.decode(searchParams.get('asset')!)) as MediaTemplateModel)
            : undefined
    )
    const [saved, setSaved] = useState<boolean>(!!mediaVersionId)
    const [isDeleted, setIsDeleted] = useState(false)

    const [
        { data: retrievedMediaTemplate, loading: fetchingMedia, error: errorFetchingMedia },
        executeGetMedia,
    ] = useAxios<GetMediaTemplateResponse>(
        {
            method: 'GET',
            url: `${APP_CONFIG.backendAPIV1BaseUrl}/media-manager/media-templates/${
                mediaVersionId ?? ''
            }`,
            apiActionName: ApiActionNames.GetMediaTemplate,
        },
        {
            manual: true,
            useCache: false,
        }
    )

    useEffect(() => {
        if (versionId) {
            setMediaVersionId(versionId)
        }
    }, [versionId])

    useEffect(() => {
        if (mediaVersionId && !mediaTemplateModel) {
            executeGetMedia().catch(() => {})
        }
    }, [mediaVersionId, mediaTemplateModel, executeGetMedia])

    useEffect(() => {
        if (retrievedMediaTemplate) {
            setMediaTemplateModel({
                ...retrievedMediaTemplate,
            })
            setMediaVersionId(retrievedMediaTemplate.versionId)
        }
    }, [retrievedMediaTemplate])

    // Or create it with the data the user enters into the form

    const [{ loading: creatingMedia, error: createError }, executeCreateMedia] = useAxios<
        GetMediaTemplateResponse,
        CreateMediaTemplateRequest
    >(
        {
            method: 'POST',
            url: `${APP_CONFIG.backendAPIV1BaseUrl}/media-manager/media-templates`,
            apiActionName: ApiActionNames.CreateMediaTemplate,
        },
        {
            manual: true,
        }
    )

    const duplicateMediaRequest = (newTitle: string) => {
        if (mediaTemplateModel !== undefined) {
            executeCreateMedia({
                data: {
                    title: newTitle,
                    fileType: mediaTemplateModel.fileType,
                    defaultFileName: mediaTemplateModel.defaultFileName,
                    defaultFilePath: mediaTemplateModel.defaultFilePath,
                    fileRequiresTranslation: mediaTemplateModel.fileRequiresTranslation,
                    hideTranscriptInMedia: mediaTemplateModel.hideTranscriptInMedia,
                    languages: mediaTemplateModel.languages,
                },
            })
                .then(({ data: createdMediaTemplate }) => {
                    setMediaTemplateModel({
                        ...createdMediaTemplate,
                    })
                    setMediaVersionId(createdMediaTemplate.versionId)
                    setSaved(true)
                    setDisplayAssetSavedBanner(true)
                    navigate(
                        `/asset-library/assets/${createdMediaTemplate.versionId}?locale=${locale}`,
                        { replace: true }
                    )
                    setDisplayErrorSavingAssetBanner(false)
                    setDisplayErrorPublishingBanner(false)
                    setDisplayAssetSavedBanner(false)
                    setDisplayAssetPublishedBanner(false)
                })
                .catch(() => {})
        }
    }

    const dropdownValues = (open: () => void) => {
        return mediaVersionId
            ? [
                  {
                      name: 'Duplicate media',
                      dataTestId: 'duplicate-media-option',
                      onClick: () => {
                          open()
                      },
                  },
              ]
            : []
    }

    const renderModal = ({ close }: { close: () => void }) => {
        return <DuplicateMediaModal close={close} duplicateMedia={duplicateMediaRequest} />
    }

    const [{ error: updateError, loading: updatingMedia }, executeUpdateMedia] = useAxios<
        GetMediaTemplateResponse,
        UpdateMediaTemplateRequest
    >(
        {
            method: 'PUT',
            url: `${APP_CONFIG.backendAPIV1BaseUrl}/media-manager/media-templates/${
                mediaVersionId ?? ''
            }`,
            apiActionName: ApiActionNames.UpdateMediaTemplate,
        },
        {
            manual: true,
        }
    )

    useEffect(() => {
        // always reset the error map if the error changes (even if its null)
        setErrorMap({})
        if (createError || updateError) {
            const axiosError = (createError ?? updateError) as AxiosError | undefined
            if (axiosError?.response?.data) {
                const validationErrors: MediaErrorTemplate[] = (
                    axiosError.response.data as ErrorResponse & {
                        validationErrors: MediaErrorTemplate[]
                    }
                ).validationErrors
                const newErrorMap: Record<string, string> = {}
                validationErrors.forEach((newError) => {
                    newErrorMap[newError.attribute] = newError.message
                })
                setErrorMap(newErrorMap)
            }
        }
    }, [createError, updateError, mediaTemplateModel?.newlyPublished])

    useEffect(() => {
        const shouldUseDefaultMediaData =
            locale === Locale.en_US || !mediaTemplateModel?.fileRequiresTranslation

        const filePath = shouldUseDefaultMediaData
            ? mediaTemplateModel?.defaultFilePath ?? mediaTemplateModel?.languages[locale]?.filePath
            : mediaTemplateModel?.languages[locale]?.filePath

        setCurrentFilePath(filePath ?? '')
    }, [mediaTemplateModel, locale])

    useEffect(() => {
        setFileType(mediaTemplateModel?.fileType ?? '')
    }, [mediaTemplateModel])

    useEffect(() => {
        if (currentFilePath && currentFilePath.length > 0) {
            MediaService.getFromS3(currentFilePath, fileType)
                .then((val) => {
                    setFileUrl(val)
                    setAssetLoading(false)
                    setIsDeleted(false)
                    setErrorLoadingAsset(false)
                })
                .catch((e) => {
                    console.error(e)
                    setAssetLoading(false)
                    setErrorLoadingAsset(true)
                })
        }
    }, [isEmbedded, navigate, currentFilePath, fileType])

    useEffect(() => {
        setCaptionsPath(mediaTemplateModel?.languages?.[locale]?.captionsPath ?? '')
    }, [mediaTemplateModel?.languages, locale])

    useEffect(() => {
        if (captionsPath?.length > 0) {
            MediaService.getFromS3(captionsPath)
                .then((val) => {
                    setUpdatedCaptionsUrl(val)
                })
                .catch((e) => {
                    console.error(e)
                    setUpdatedCaptionsUrl('')
                })
        } else {
            setUpdatedCaptionsUrl('')
        }
    }, [captionsPath])

    useEffect(() => {
        if (!useMedia && !cancel && !searchParams.get('locale')) {
            setSearchParams(
                {
                    locale: Locale.en_US,
                },
                {
                    replace: true,
                }
            )
        }
    }, [searchParams, setSearchParams, useMedia, cancel])

    useEffect(() => {
        headerElement?.focus()
    }, [headerElement])

    useEffect(() => {
        if (searchParams.get('asset')) {
            setMediaVersionId(undefined)
        }
    }, [searchParams])

    const hasCaptionValidationError =
        !!errorMap[`languages[${locale}].captionsPath`] &&
        errorMap[`languages[${locale}].captionsPath`].length > 0

    const updateMediaRequest = useCallback(async () => {
        if (mediaTemplateModel) {
            const languagesToUpdate: Partial<LocalizedAttribute<UpdateMediaLanguageRequest>> = {}

            Object.keys(mediaTemplateModel.languages).forEach((language) => {
                const {
                    fileCreatedBy: _ignored1,
                    fileCreatedAt: _ignored2,
                    ...rest
                } = mediaTemplateModel.languages[language]

                const updateMediaLanguageRequest = rest as UpdateMediaLanguageRequest

                languagesToUpdate[language] = {
                    ...updateMediaLanguageRequest,
                    published:
                        mediaTemplateModel?.newlyPublished === language ||
                        updateMediaLanguageRequest.published,
                }
            })

            setDisplayAssetSavedBanner(false)
            setDisplayErrorSavingAssetBanner(false)
            setDisplayAssetPublishedBanner(false)
            setDisplayErrorPublishingBanner(false)

            await executeUpdateMedia({
                data: {
                    title: mediaTemplateModel.title,
                    fileType: mediaTemplateModel.fileType,
                    defaultFileName: mediaTemplateModel.defaultFileName,
                    defaultFilePath: mediaTemplateModel.defaultFilePath,
                    fileRequiresTranslation: mediaTemplateModel.fileRequiresTranslation,
                    hideTranscriptInMedia: mediaTemplateModel.hideTranscriptInMedia,
                    languages: languagesToUpdate,
                },
            })
                .then((response) => {
                    setMediaTemplateModel((prevState) => ({
                        ...prevState!,
                        languages: {
                            ...prevState!.languages,
                            [locale]: {
                                ...prevState!.languages[locale],
                                published: !!mediaTemplateModel?.newlyPublished,
                            },
                        },
                        lastUpdatedAt: response.data.lastUpdatedAt ?? prevState?.lastUpdatedAt,
                        lastUpdatedBy: response.data.lastUpdatedBy ?? prevState?.lastUpdatedBy,
                        newlyPublished: undefined,
                    }))
                    if (mediaTemplateModel?.newlyPublished) {
                        setDisplayAssetPublishedBanner(true)
                    } else {
                        setDisplayAssetSavedBanner(true)
                    }
                    setSaved(true)
                })
                .catch(() => {
                    if (mediaTemplateModel?.newlyPublished) {
                        setDisplayErrorPublishingBanner(true)
                        setMediaTemplateModel({
                            ...mediaTemplateModel,
                            newlyPublished: undefined,
                        })
                    } else {
                        setDisplayErrorSavingAssetBanner(true)
                    }
                })
        }
    }, [executeUpdateMedia, mediaTemplateModel, setSaved, locale])

    useEffect(() => {
        if (mediaTemplateModel?.newlyPublished) {
            void updateMediaRequest()
        }
    }, [mediaTemplateModel?.newlyPublished, updateMediaRequest])

    if (errorFetchingMedia) {
        return <ErrorPage errorMessage={'Could not find media'} />
    }

    if (mediaTemplateModel === null && !versionId) {
        return (
            <ErrorPage
                errorMessage={
                    'An unexpected error has occurred. Please try again or refresh the page and try again.'
                }
            />
        )
    }

    if (assetLoading || fetchingMedia || !mediaTemplateModel) {
        return (
            <>
                <Row
                    justifyContent='center'
                    gridGap='S200'
                    margin='S200'
                    height='100%'
                    alignItems='center'
                >
                    <Spinner dataTestId={'initial-loading-spinner'} />
                </Row>
            </>
        )
    }

    const setMedia = setMediaTemplateModel as React.Dispatch<
        React.SetStateAction<MediaTemplateModel>
    >
    const media = mediaTemplateModel

    const createMediaRequest = () => {
        if (hasCaptionValidationError) {
            return
        }
        const languagesToUpdate: Partial<LocalizedAttribute<UpdateMediaLanguageRequest>> = {}

        Object.keys(mediaTemplateModel.languages).forEach((language) => {
            if (mediaTemplateModel?.languages[language]) {
                const {
                    fileCreatedBy: _ignored1,
                    fileCreatedAt: _ignored2,
                    ...rest
                } = mediaTemplateModel.languages[language]

                languagesToUpdate[language] = {
                    ...rest,
                    published: mediaTemplateModel?.newlyPublished === language,
                }
            }
        })

        executeCreateMedia({
            data: {
                title: media.title,
                fileType: media.fileType,
                defaultFileName: media.defaultFileName,
                defaultFilePath: media.defaultFilePath,
                fileRequiresTranslation: media.fileRequiresTranslation,
                hideTranscriptInMedia: media.hideTranscriptInMedia,
                languages: languagesToUpdate,
            },
        })
            .then(({ data: createdMediaTemplate }) => {
                setMediaTemplateModel({
                    ...createdMediaTemplate,
                })
                setMediaVersionId(createdMediaTemplate.versionId)
                setSaved(true)
                setDisplayAssetSavedBanner(true)
                navigate(
                    `/asset-library/assets/${createdMediaTemplate.versionId}?locale=${locale}`,
                    { replace: true }
                )
                setDisplayErrorSavingAssetBanner(false)
                setDisplayErrorPublishingBanner(false)
                setDisplayAssetSavedBanner(false)
                setDisplayAssetPublishedBanner(false)
            })
            .catch(() => noop())
    }

    let footer: JSX.Element

    if (useMedia || cancel) {
        footer = (
            <ModalMediaFooter
                useMedia={useMedia}
                cancel={cancel}
                mediaTemplateModel={mediaTemplateModel as never}
            />
        )
    } else if (mediaVersionId) {
        footer = (
            <UpdateMediaFooter
                mediaTemplateModel={mediaTemplateModel}
                setMediaTemplate={setMedia}
                updateMediaRequest={updateMediaRequest}
                updateLoading={updatingMedia}
                locale={locale}
                saved={saved}
                hasCaptionValidationError={hasCaptionValidationError}
            />
        )
    } else {
        footer = (
            <CreateMediaFooter createMediaRequest={createMediaRequest} loading={creatingMedia} />
        )
    }

    const requiresMediaUpload =
        locale !== Locale.en_US &&
        media.fileRequiresTranslation &&
        (media.languages[locale]?.filePath ?? '').length === 0

    return (
        <WithFixedFooter footerHeight='75px' renderFooter={() => footer}>
            <Container paddingTop='S700' paddingBottom='S400' paddingHorizontal='S400'>
                <Row justifyContent='space-between'>
                    <H1 fontSize='T500' ref={setHeaderElement} tabIndex={-1}>
                        {getAssetType(media.fileType)} Asset (
                        {media.languages[locale]?.published ? 'Published' : 'Unpublished'})
                    </H1>
                    {!isEmbedded && (
                        <WithModal renderModal={renderModal}>
                            {({ open }) => (
                                <DropdownButton
                                    title='Options'
                                    values={dropdownValues(open)}
                                    dataTestId={'media-options'}
                                    ariaLabel={'Actions for the asset.'}
                                />
                            )}
                        </WithModal>
                    )}
                </Row>
            </Container>
            <Hr />
            <Container paddingTop='S400' paddingBottom='S400' paddingHorizontal='S400'>
                {locale != Locale.en_US &&
                    media.fileRequiresTranslation &&
                    !media.languages[locale]?.filePath && (
                        <>
                            <MessageBanner type={MessageBannerType.Warning}>
                                This locale has only been partially translated. Please upload a file
                                for this locale, and then publish the locale when ready.
                            </MessageBanner>
                            <Spacer height={'S400'} />
                        </>
                    )}

                {displayAssetSavedBanner && (
                    <>
                        <MessageBanner
                            type={MessageBannerType.Success}
                            isDismissible={true}
                            aria-live='polite'
                            dismissButtonAltText={'Dismiss save confirmation banner'}
                        >
                            Asset saved successfully
                        </MessageBanner>
                        <Spacer height={'S400'} />
                    </>
                )}

                {displayErrorSavingAssetBanner && (
                    <>
                        <MessageBanner
                            type={MessageBannerType.Error}
                            isDismissible={true}
                            aria-live='polite'
                            dismissButtonAltText={'Dismiss save error banner'}
                        >
                            Error saving asset
                        </MessageBanner>
                        <Spacer height={'S400'} />
                    </>
                )}

                {displayAssetPublishedBanner && (
                    <>
                        <MessageBanner
                            type={MessageBannerType.Success}
                            isDismissible={true}
                            aria-live='polite'
                            dismissButtonAltText={'Dismiss publish confirmation banner'}
                        >
                            Asset published successfully
                        </MessageBanner>
                        <Spacer height={'S400'} />
                    </>
                )}

                {displayErrorPublishingBanner && (
                    <>
                        <MessageBanner
                            type={MessageBannerType.Error}
                            isDismissible={true}
                            aria-live='polite'
                            dismissButtonAltText={'Dismiss publish error banner'}
                        >
                            Error publishing asset
                        </MessageBanner>
                        <Spacer height={'S400'} />
                    </>
                )}

                <LocaleDropdown
                    id='viewing-locale-select'
                    options={Object.keys(mediaTemplateModel.languages) as Locale[]}
                    locale={locale}
                    setLocale={(l) => {
                        if (!useMedia && !isEmbedded) {
                            setSearchParams({
                                locale: l.toString(),
                            })
                        }
                        setLocale(l)
                    }}
                    flexDirection={'column'}
                />

                <Spacer height={'S500'} />

                <fieldset>
                    <ScreenReaderOnly>
                        <legend>Active asset information</legend>
                    </ScreenReaderOnly>
                    <ResponsiveRow gridGap='S400'>
                        {requiresMediaUpload ? (
                            <UploadFileTranslation
                                locale={locale}
                                setMedia={setMedia}
                                setSaved={setSaved}
                                validationErrors={errorMap}
                                mediaDTO={mediaTemplateModel}
                            />
                        ) : (
                            <FileView
                                media={media}
                                captionsUrl={updatedCaptionsUrl}
                                locale={locale}
                                errorLoadingFile={errorLoadingAsset}
                                validationErrors={errorMap}
                                fileUrl={fileUrl}
                                setMedia={setMedia}
                                setSaved={setSaved}
                                isDeleted={isDeleted}
                                setIsDeleted={setIsDeleted}
                            />
                        )}
                    </ResponsiveRow>
                </fieldset>
                <Spacer height={'S500'} />
                <Row>
                    <Col flex={1}>
                        <InputWrapper
                            id='translate-file-input'
                            labelText={'Unique item needed for each locale'}
                            labelPosition={LabelPosition.Trailing}
                            tooltipText={
                                'Some media files may need to be translated, for example a picture containing text.'
                            }
                        >
                            {(inputProps) => (
                                <Checkbox
                                    {...inputProps}
                                    checked={mediaTemplateModel?.fileRequiresTranslation ?? false}
                                    onChange={() => {
                                        setSaved(false)
                                        setMedia((prev) => {
                                            return {
                                                ...prev,
                                                fileRequiresTranslation:
                                                    !prev?.fileRequiresTranslation,
                                            }
                                        })
                                    }}
                                    disabled={
                                        media.languages[locale]?.published ||
                                        locale !== Locale.en_US
                                    }
                                />
                            )}
                        </InputWrapper>
                    </Col>
                </Row>
                <Spacer height={'S400'} />
                <Row>
                    <Col flex={1}>
                        <InputWrapper
                            id='media-title'
                            dataTestId='media-title'
                            labelText='Media Title'
                            tooltipText={'This will be the name of the saved file'}
                            footer={errorMap.title}
                            error={errorMap.title !== undefined && errorMap.title.length > 0}
                        >
                            {(inputProps) => (
                                <Input
                                    {...inputProps}
                                    value={media.title}
                                    disabled={
                                        media.languages[locale]?.published ||
                                        locale !== Locale.en_US
                                    }
                                    onChange={(e) => {
                                        const title = e.target.value ?? ''
                                        setMedia((prev) => {
                                            return {
                                                ...prev,
                                                title: title,
                                            }
                                        })
                                        setSaved(false)
                                    }}
                                />
                            )}
                        </InputWrapper>
                    </Col>
                </Row>
                <Spacer height={'S400'} />
                <Row>
                    <Col flex={1}>
                        <InputWrapper
                            id='alt-text'
                            dataTestId='alt-text'
                            labelText='Alternative Text (alt text)'
                            tooltipText={'This will be shown if the asset fails to load'}
                            footer={errorMap[`languages[${locale}].alternateText`]}
                            error={
                                errorMap[`languages[${locale}].alternateText`] !== undefined &&
                                errorMap[`languages[${locale}].alternateText`].length > 0
                            }
                        >
                            {(inputProps) => (
                                <Input
                                    {...inputProps}
                                    value={media.languages[locale]?.alternateText ?? ''}
                                    disabled={media.languages[locale]?.published}
                                    onChange={(e) => {
                                        const nextValue = e.target.value ?? ''
                                        setMedia((prev) => {
                                            return {
                                                ...prev,
                                                languages: {
                                                    ...media.languages,
                                                    [locale]: {
                                                        ...media.languages[locale],
                                                        alternateText: nextValue,
                                                    },
                                                },
                                            }
                                        })
                                        setSaved(false)
                                    }}
                                />
                            )}
                        </InputWrapper>
                    </Col>
                </Row>
                <CaptionsAndTranscriptions
                    setMediaTemplateModel={setMedia}
                    mediaTemplateModel={media}
                    locale={locale}
                    errorMap={errorMap}
                    setSaved={setSaved}
                />
            </Container>
        </WithFixedFooter>
    )
}
