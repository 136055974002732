import React from 'react'
import _ from 'lodash'

import { Button, ButtonVariant } from '@amzn/stencil-react-components/button'
import { Spinner } from '@amzn/stencil-react-components/spinner'

import { ModuleOptions, OptionValues } from 'src/components/ModuleOptions'
import { ReviewDTO } from 'src/models/dto/approval/ReviewDTO'
import { ModuleDTO } from 'src/models/dto/ModuleDTO'
import { ModuleStatus } from 'src/models/dto/ModuleStatus'
import { ModuleReviewButtonsWrapper } from 'src/pages/module-review/ModuleReviewButtonsWrapper'
import { RevisionSelector } from 'src/pages/module-review/RevisionSelector'

interface ModuleReviewPageButtonsProps {
    revisionNumber: string
    moduleDTO: ModuleDTO
    review?: ReviewDTO | null
    changeRevision: (rev: string) => void
    setPreviewError: (error: string) => void
    approve: () => Promise<void>
    revoke: () => Promise<void>
    isApproved: boolean
    moduleStatus: ModuleStatus
    approvalAllowed?: boolean
    submittingApproval?: boolean
    fastTrackProcess?: boolean | null | undefined
}

export function ModuleReviewPageButtons({
    approve,
    revoke,
    isApproved,
    moduleDTO,
    revisionNumber,
    changeRevision,
    review,
    moduleStatus,
    approvalAllowed,
    submittingApproval,
    fastTrackProcess,
}: ModuleReviewPageButtonsProps) {
    const hasBeenUATDeployed = moduleStatus === ModuleStatus.UAT

    // const error = 'Unexpected error previewing module'
    const sortedRevisions = review ? _.sortBy(review.revisionList, (r) => -r.createdAt) : []

    const moduleInReview =
        moduleStatus === ModuleStatus.REVIEW ||
        moduleStatus === ModuleStatus.REVIEW_MAJOR ||
        moduleStatus === ModuleStatus.REVIEW_MINOR

    const dropDownOptions = new Set([
        OptionValues.OPEN_EDITOR,
        OptionValues.LAUNCH_PREVIEW,
        OptionValues.BACK_TO_VIEWER,
        OptionValues.SHARE_PREVIEW,
        OptionValues.COPY_MODULE_ID,
    ])

    if (moduleStatus === ModuleStatus.REVIEW_APPROVED && !fastTrackProcess) {
        dropDownOptions.add(OptionValues.DEPLOY_UAT)
    } else if (moduleStatus === ModuleStatus.UAT) {
        dropDownOptions.add(OptionValues.OPEN_UAT)
    }

    return (
        <ModuleReviewButtonsWrapper>
            {review &&
                moduleInReview &&
                !hasBeenUATDeployed &&
                approvalAllowed &&
                !submittingApproval &&
                (!isApproved ? (
                    <Button
                        variant={ButtonVariant.Secondary}
                        disabled={isApproved}
                        dataTestId='approve-review'
                        onClick={approve}
                    >
                        Approve
                    </Button>
                ) : (
                    <Button
                        variant={ButtonVariant.Secondary}
                        disabled={!isApproved}
                        dataTestId='revoke-approved-review'
                        onClick={revoke}
                    >
                        Revoke Approval
                    </Button>
                ))}
            {submittingApproval && <Spinner />}
            {/*<PreviewModuleButton
            moduleDTOToPreview={moduleVersionId}
            beginProcessingCallback={() => setPreviewError('')}
            validationErrorCallback={() => setPreviewError(error)}
            serverErrorCallback={() => setPreviewError(error)} />*/}
            {review && (
                <RevisionSelector
                    revisions={sortedRevisions}
                    selectedRevision={revisionNumber}
                    onRevisionSelect={changeRevision}
                />
            )}

            <ModuleOptions
                moduleEntity={{ ...moduleDTO, workflowIds: [] }}
                moduleStatus={moduleStatus}
                reviewStatus={undefined}
                optionsToInclude={dropDownOptions}
            />
        </ModuleReviewButtonsWrapper>
    )
}
